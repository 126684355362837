import React, { useEffect, useState } from "react";
import Modal from "react-bootstrap/Modal";
import Image from "react-bootstrap/Image";
import { OverlayTrigger, Tooltip } from "react-bootstrap";
import PreviewIcon from "../../assets/images/previewIcon.svg";
import CustomizeIcon from "../../assets/images/customizeIcon.svg";
//API
import {
  getRegistartionTemplate,
  getWebinarData,
} from "../../action/user.action";

function Owntemp(props) {
  //PROPS
  const { webinarId, templateId, meetingType } = props;
  const [webinarData, setWebinarData] = useState(null);
  const [error, setError] = useState(null);

  console.log(
    "webinarId, templateId, meetingType",
    webinarId,
    templateId,
    meetingType
  );
  // alert(meetingType)
  //SAVE / EDIT - CONFIG
  //SAVE = "none"
  const OPT = templateId === null ? 0 : 1;
  console.log("template id:=", templateId, OPT);

  const [templateData, setTemplateData] = useState([]);
  const templatePreviewData = new Array(
    {
      screen: require("../../assets/images/template_img/modal_img_2.jpg"),
    },
    {
      screen: require("../../assets/images/template_img/modal_img_3.jpg"),
    },
    {
      screen: require("../../assets/images/template_img/modal_img_4.jpg"),
    },
    {
      screen: require("../../assets/images/template_img/modal_img_5.jpg"),
    },
    {
      screen: require("../../assets/images/template_img/modal_img_6.jpg"),
    },
    {
      screen: require("../../assets/images/template_img/modal_img_7.jpg"),
    }
  );
  const [activeBody, setActiveBody] = useState(OPT);
  const handleBodyClick = (bodyId) => {
    setActiveBody(bodyId);
  };
  const [show1, setShow1] = useState(false);
  const [template, setTemplate] = useState("");
  const handleClose_1 = () => setShow1(false);
  const handleShow1 = (index) => {
    setShow1(true);
    setTemplatePreview(index);
  };
  const [show2, setShow2] = useState(false);
  const [templatePreview, setTemplatePreview] = useState(0);
  const handleClose_2 = () => setShow2(false);
  const handleShow2 = (template) => {
    setShow2(true);
    setTemplate(template);
  };

  useEffect(() => {
    getTemplate();
  }, []);
  //GET - ALL TEMPLATE
  const getTemplate = async () => {
    //PAYLOAD
    let payload = {
      templateId: "",
      webinarId: webinarId,
    };

    try {
      let { status, result } = await getRegistartionTemplate(payload);
      console.log(status, result, "status, result");
      if (status) {
        setTemplateData(result?.result);
      }
    } catch (err) {
      console.log(err, "err");
    } finally {
    }
  };

  console.log(templateData, "templateDatatemplateData");

  const redirectpage = () => {
    const url = `/canva-template/${webinarId}/${meetingType}`;
    const a = document.createElement("a");
    a.href = url;
    a.click(); // Trigger the click event
  };

  const viewPage = () => {
    const template = "66ebabea2f1eba2b3cb7b465";
    const url = `/template-viewer/${webinarId}/${template}/${meetingType}`;
    const a = document.createElement("a");
    a.href = url;
    a.click(); // Trigger the click event
  };

  // Call getWebinarDetails when webinarId changes
  useEffect(() => {
    if (webinarId) {
      console.log("Owntemplate111");
      getWebinarDetail();
    }
  }, [webinarId]);

  const getWebinarDetail = async () => {
    try {
      let { status, result } = await getWebinarData({ id: webinarId });
      if (status) {
        setWebinarData(result);
      }
    } catch {
      console.log("Error");
    }
  };

  console.log("Webinar Data:", webinarData);

  // const filteredTemplates = templateData?.filter(template =>
  //   template?.custamiz_id != "" && template?.template_type == 1
  // ) || [];

  // const filteredTemplates = templateData?.filter(template => {
  //   if (template?.hasOwnProperty('custamiz_id') && template?.hasOwnProperty('template_type')) {
  //     if (template?.custamiz_id != "" && template?.template_type == 1) {

  //       return template;
  //     }
  //     else {
  //       return ([]);
  //     }
  //   }
  //   else {
  //     return template;
  //   }
  // });

  const filteredTemplates = templateData.filter(function (template) {
    if (
      template?.hasOwnProperty("custamiz_id") &&
      template?.hasOwnProperty("template_type")
    ) {
      if (template?.custamiz_id != "" && template?.template_type == 1) {
        return template;
      } else {
        return false;
      }
    } else {
      return false;
    }
  });

  console.log("filteredTemplates", filteredTemplates);

  return (
    <>
      <div className="dashbordrightbody-list1 registration_radio">
        <div className="registration_sec ">
          {filteredTemplates.length != 0 ? (
            <>
              <a href="#" className="registration_body ">
                <div className="registration_sec_image">
                  <img
                    src={require("../../assets/images/canvaTemplate.png")}
                    className="img-fluid m-auto d-block"
                    style={{ width: "328px", height: "150px" }}
                  />
                </div>

                <div className="registration_info mt-3">
                  <a href="#" onClick={viewPage}>
                    <OverlayTrigger
                      overlay={
                        <Tooltip id="tooltip-disabled">View template</Tooltip>
                      }
                    >
                      <p>
                        Template name : <span>Canva New</span>
                        <img
                          src={require("../../assets/images/registration_imgs/eye_icon.png")}
                          className="img-fluid"
                          onClick={handleShow1}
                        />
                      </p>
                    </OverlayTrigger>
                  </a>
                  <div className="registration_info_1">
                    <img
                      src={require("../../assets/images/registration_imgs/pencil_square.png")}
                      className="img-fluid"
                    />
                    <a href="#" onClick={redirectpage}>
                      Customize
                    </a>
                  </div>
                </div>
              </a>

              <a
                href="#"
                className={`registration_body select active`}
                onClick={() => handleBodyClick(1)}
              >
                <div className="registration_sec_image">
                  <img
                    src={require("../../assets/images/canvaTemplate.png")}
                    className="img-fluid m-auto d-block"
                    style={{ width: "328px", height: "150px" }}
                  />
                </div>
                <div className="registration_info mt-3">
                  <a
                    href={`/template-viewer/${webinarId}/${templateId}/${meetingType}`}
                  >
                    <OverlayTrigger
                      overlay={
                        <Tooltip id="tooltip-disabled">View template</Tooltip>
                      }
                    >
                      <p>
                        Template name : <span>Canva 001</span>
                        <img
                          src={require("../../assets/images/registration_imgs/eye_icon.png")}
                          className="img-fluid"
                          onClick={handleShow1}
                        />
                      </p>
                    </OverlayTrigger>
                  </a>
                  <div className="registration_info_1">
                    <img
                      src={require("../../assets/images/registration_imgs/pencil_square.png")}
                      className="img-fluid"
                    />
                    <a
                      href={`/canva-template/${webinarId}/${templateId}/${meetingType}`}
                    >
                      Customize
                    </a>
                  </div>
                </div>
              </a>
            </>
          ) : (
            <a href="#" onClick={() => handleBodyClick(1)} className="registration_body ">
              <div className="registration_sec_image">
                <img
                  src={require("../../assets/images/canvaTemplate.png")}
                  className="img-fluid m-auto d-block"
                  style={{ width: "328px", height: "150px" }}
                />
              </div>
              <div className="registration_info mt-3">
                <div className="registration_info_1">
                  <a href="#" onClick={viewPage}>
                    <OverlayTrigger
                      overlay={
                        <Tooltip id="tooltip-disabled">View template</Tooltip>
                      }
                    >
                      <p>
                        Template name : <span>Canva New</span>
                      </p>
                    </OverlayTrigger>
                  </a>
                  <div>
                    <img src={CustomizeIcon} className="img-fluid ps-0 pe-2" />
                    <a href="#" onClick={redirectpage}>
                      Customize
                    </a>
                  </div>
                </div>
                <div className="registration_info_1">
                  <a href="#" onClick={viewPage}>
                    <OverlayTrigger
                      overlay={
                        <Tooltip id="tooltip-disabled">View template</Tooltip>
                      }
                    >
                      <p>
                        <img
                          src={PreviewIcon}
                          className="img-fluid"
                          onClick={handleShow1}
                        />
                      </p>
                    </OverlayTrigger>
                  </a>
                </div>
              </div>
            </a>
          )}
        </div>
      </div>
    </>
  );
}
export default Owntemp;
