

import { combineReducers } from '@reduxjs/toolkit'
import UserSlice from './features/userdetails'
import WebinarSlice from './features/webinar_details'
import MeetingSlice from './features/meeting_details'
import UserWebinarSlice from './features/user_webinar'
import  PlanSlice  from './features/plandetails'

const MainReducers = (asyncReducers) => (state, action) => {

    const CombineReducers = combineReducers({
        User: UserSlice,
        Webinar: WebinarSlice,
        Meeting: MeetingSlice,
        UserWebinar: UserWebinarSlice,
        PlanDetails: PlanSlice,
        ...asyncReducers
    })
    return CombineReducers(state, action)
}

export default MainReducers