import React, { useState, useEffect } from "react";
import navicon1 from "../assets/images/nav-icon1.svg";
import navicon2 from "../assets/images/nav-icon2.svg";
import navicon3 from "../assets/images/nav-icon3.svg";
import navicon4 from "../assets/images/nav-icon4.svg";
import navicon5 from "../assets/images/nav-icon5.svg";
import navicon6 from "../assets/images/nav-icon6.svg";
import navicon7 from "../assets/images/profile-icon.svg";
import navicon8 from "../assets/images/subscription-icon.svg";
import { NavLink } from "react-router-dom";
import { clearCookies } from "../utils/cookies";
import { useDispatch, useSelector } from "react-redux";
import { useParams, useNavigate } from "react-router-dom";

import io from "socket.io-client";
import config from "../lib/config";
import Cookies from "js-cookie";
import { setUserDetails } from "../redux/features/userdetails";

const socket = io(config.API_URL);

const Navinner = () => {
  let UserDetails = useSelector((state) => state.User);
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const handlePageChange = (page) => {
    navigate(page);
  };

  const clearUserCookies = () => {
    Cookies.remove("user_token");
    dispatch(setUserDetails(null));
    window.location.reload();
  };

  useEffect(() => {
    if (UserDetails) {
      socket.on("connect", () => {
        console.log("Connected to server");
      });
      socket.emit("CREATEROOM", UserDetails._id);
      socket.emit("message", "hii");
      socket.on("auth", (data) => {
        console.log("socket_______emit__auth");
        clearUserCookies();
      });
      // socket.on("StremingCompleted", (reason) => {
      //   console.log(`StremingCompletedstopStreaming : ${reason}`);

      // });
      // return () => {
      //   if (socket) {
      //     socket.disconnect();
      //   }
      // };
    }
  }, [UserDetails, socket]);

  useEffect(() => {
    const socket = io(config.API_URL);
    socket.on("new-message", (data) => {
      console.log("New message received:", data);
      // toast.info("New message from admin: " + data.message);
    });
  }, []);
  return (
    <>
      <div className="d-none d-lg-flex h-100">
        <div className="sidebar-left">
          <div className="sidebar-left-header">
            <div class="nav-icons">
              <ul>
                <li>
                  <NavLink to="/inner-home">
                    <img src={navicon1} alt="" class="img-fluid" /> Dashboard
                  </NavLink>
                </li>
                <li>
                  <NavLink to="/webinar">
                    <img src={navicon2} alt="" class="img-fluid" /> My webinars
                  </NavLink>
                </li>
                <li>
                  <NavLink to="/report">
                    <img src={navicon3} alt="" class="img-fluid" /> My Report
                  </NavLink>
                </li>
                <li>
                  <NavLink to="/allmeetings">
                    <img src={navicon4} alt="" class="img-fluid" /> My
                    registrants
                  </NavLink>
                </li>
              </ul>
            </div>
          </div>
          <div className="sidebar-left-footer">
            <div class="nav-icons">
              <ul>
                <li>
                  <NavLink to="/Subscription">
                    <img src={navicon8} alt="" class="img-fluid" />
                    Subscription
                  </NavLink>
                </li>
                <li>
                  <NavLink to="/inner-support-ticket">
                    <img src={navicon5} alt="" class="img-fluid" /> Support
                  </NavLink>
                </li>
                <li>
                  <NavLink to="/inner-settings">
                    <img src={navicon7} alt="" class="img-fluid" /> Profile
                  </NavLink>
                </li>
                <li>
                  <NavLink to="javascript:void(0)" onClick={clearCookies}>
                    <img src={navicon6} alt="" class="img-fluid" /> Logout
                  </NavLink>
                </li>
              </ul>
            </div>
          </div>
        </div>
      </div>
      <div className="d-block d-lg-none h-100">
        <div className="sidebar-left">
          <div className="sidebar-left-header">
            <div class="nav-icons">
              <ul>
                <li data-bs-dismiss="offcanvas">
                  <NavLink to="/inner-home">
                    <img src={navicon1} alt="" class="img-fluid" /> Dashboard
                  </NavLink>
                </li>
                <li data-bs-dismiss="offcanvas">
                  <NavLink to="/webinar">
                    <img src={navicon2} alt="" class="img-fluid" /> My webinars
                  </NavLink>
                </li>
                <li data-bs-dismiss="offcanvas">
                  <NavLink to="/report">
                    <img src={navicon3} alt="" class="img-fluid" /> My Report
                  </NavLink>
                </li>
                <li data-bs-dismiss="offcanvas">
                  <NavLink to="/allmeetings">
                    <img src={navicon4} alt="" class="img-fluid" /> My
                    registrants
                  </NavLink>
                </li>
              </ul>
            </div>
          </div>
          <div className="sidebar-left-footer">
            <div class="nav-icons">
              <ul>
                <li data-bs-dismiss="offcanvas">
                  <NavLink to="/Subscription">
                    <img src={navicon8} alt="" class="img-fluid" />
                    Subscription
                  </NavLink>
                </li>
                <li data-bs-dismiss="offcanvas">
                  <NavLink to="/inner-support-ticket">
                    <img src={navicon5} alt="" class="img-fluid" /> Support
                  </NavLink>
                </li>
                <li data-bs-dismiss="offcanvas">
                  <NavLink to="/inner-settings">
                    <img src={navicon7} alt="" class="img-fluid" /> Profile
                  </NavLink>
                </li>
                <li data-bs-dismiss="offcanvas">
                  <NavLink to="javascript:void(0)" onClick={clearCookies}>
                    <img src={navicon6} alt="" class="img-fluid" /> Logout
                  </NavLink>
                </li>
              </ul>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};
export default Navinner;
