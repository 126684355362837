import { width } from "@fortawesome/free-solid-svg-icons/fa0";
import React, { useEffect, useRef, useState } from "react";
import {
  Tabs,
  Tab,
  TabContainer,
  TabContent,
  Row,
  Col,
  Nav,
} from "react-bootstrap";
import Form from "react-bootstrap/Form";

import ReactLanguageSelect from "react-languages-select";
import {
  Create_Webinar,
  checkForEmail_verification,
  getWebinarData,
} from "../../action/user.action";
import { toastAlert } from "../../utils/toast";
import { useSelector } from "react-redux";
import config from "../../lib/config";
import {
  Basic_validation,
  new_Basic_validation,
  persenter_validation,
} from "../../lib/validation";
import { isEmpty } from "../../helper/helper";
import { OverlayTrigger, Tooltip } from "react-bootstrap";

//Controller Data 
import { controllerData } from '../../lib/controller';
import { toast } from "react-toastify";

function BasicFullconfig(props) {
  //Redux Data Get From Data
  const PlanDetails = useSelector((state) => state.PlanDetails);
  const [packListNames, setPackListNames] = useState([]);
  const [planDate, setPlanDate] = useState(null);

  useEffect(() => {
    console.log(PlanDetails[0], "PlanDetails-#");

    // Check if PlanDetails has data
    if (PlanDetails.length > 0) {
      const names = PlanDetails[0]?.PlanDetails?.packList;
      const date = PlanDetails[0].plan_expired;

      if (Array.isArray(names)) {
        setPackListNames(names);
        console.log(names, "Pack List Names");
      } else {
        console.log("packListNames is not an array or is undefined.");
      }

      setPlanDate(date);
    } else {
      console.log("Data Not Available");
    }
  }, [PlanDetails]);

  const { id, initialForm, setInitialForm, languageRef, error, webinarId } =
    props;

  const [isOTPSent, setIsOtpSent] = useState(false);

  console.log(initialForm, "initialForm1111111");

  console.log(error, "error");

  const userDetails = useSelector((state) => state.User);

  console.log(userDetails, "userDetails");

  const {
    language,
    title,
    discription,
    presentername,
    presenterImage,
    presenteremail,
    vaffixlive,
    youtubelive,
    facebooklive,
    fake_user,
    google_form,
  } = initialForm;

  const [selectedOption1, setselectedOption1] = useState(1);

  const handleOptionChange = (event) => {
    const value = (Array.isArray(packListNames) && packListNames.some(id => id === controllerData?.Youtube_and_Facebook_Live))
    if (value) {
      const { id, value } = event.target;
      console.log(id, value, "event.target.value", initialForm[id]);
      setInitialForm({ ...initialForm, [id]: initialForm[id] ? false : true });
    } else {
      toast.warning("Please subscribe to the pack.")
    }
  };

  console.log(initialForm, "sakthi_____bala");

  const fileRef = useRef();

  const [newPresenter, setNewPresenter] = useState({
    Presenters_name: "",
    presenterImage: "",
    Presenters_email: "",
  });

  const [preview, setpreview] = useState("");

  const [editpresenter, setEdit_presenter] = useState(true);

  const [Preerror, setPreError] = useState();

  const Changes_Lang_Handler = (value) => {
    setInitialForm({ ...initialForm, language: value });
  };

  const changesHandler = async (e) => {
    const { id, value } = e.target;
    if (id == "title") {
      if (value?.length <= 30) {
        setInitialForm({ ...initialForm, [id]: value });
      }
    } else if (id == "discription") {
      if (value?.length <= 150) {
        setInitialForm({ ...initialForm, [id]: value });
      }
    } else if (id === "google_form") {
      setInitialForm({ ...initialForm, [id]: value });
    } else if (id === "fake_user") {
      setInitialForm({ ...initialForm, [id]: value });
    }
  };

  console.log(initialForm, "initialForm");

  const editPresenters = async () => {
    setEdit_presenter(false);
    setNewPresenter({
      ...newPresenter,
      Presenters_name: presentername,
      presenterImage: presenterImage,
      Presenters_email: presenteremail,
      preview: preview,
    });
  };

  function handleListChange(event) {
    const { id } = event.target;
    if (id == "image") {
      const { files } = event.target;

      const file = event.target.files[0];
      const fileType = file.type;
      const fileSize = file.size;

      console.log(fileType, fileSize, "fileSizefileSizefileSize");

      if (
        (fileType === "image/png" ||
          fileType === "image/jpeg" ||
          fileType === "image/jpg" ||
          fileType === "image/webp") &&
        fileSize <= 1024 * 1024 // 1MB in bytes
      ) {
        // setpreview(URL.createObjectURL(files[0]));
        setNewPresenter({
          ...newPresenter,
          presenterImage: files[0],
          preview: URL.createObjectURL(files[0]),
        });
      } else {
        toastAlert("error", "The file must be less than 1 MB.");

        return false;
      }
    } else {
      setNewPresenter({ ...newPresenter, [id]: event.target.value });
    }
  }

  // console.log(isYoutubeAdded, "newPresenter");

  const addClick = async () => {
    newPresenter.isOTPSent = isOTPSent;
    const dat = await persenter_validation(newPresenter);
    console.log(dat, "newPresenter____webinarId");
    if (isEmpty(dat)) {
      const payload = {
        email: newPresenter.Presenters_email,
        webinarid: webinarId,
      };

      if (isOTPSent && newPresenter.otp) {
        payload.OTP = newPresenter.otp;
      }

      const { status, result } = await checkForEmail_verification(payload);

      console.log(status, result, "status,result");
      if (status) {
        if (result.isMailSent) {
          setIsOtpSent(true);
          toastAlert("success", result.message);
        } else {
          setInitialForm({
            ...initialForm,
            presenteremail: newPresenter.Presenters_email,
            presenterImage: newPresenter.presenterImage,
            presentername: newPresenter.Presenters_name,
          });
          setNewPresenter({
            preview: "",
            image: "",
            Presenters_name: "",
            Presenters_email: "",
          });
          setpreview(newPresenter.preview);
          setPreError();
          setEdit_presenter(true);
          setIsOtpSent(false);
          toastAlert("success", "Presenter verified");
        }
      } else {
        toastAlert("error", result.message);
      }
    } else {
      setPreError(dat);
    }
  };

  return (
    <>
      <h2 className="head-field mt-2"> Basic Setting</h2>
      <div className="webinar-setting-content basic-margin">
        <Row>
          <Col lg={6}>
            <Form.Group className="mb-3" controlId="exampleForm.ControlInput1">
              <Form.Label>Webinar language</Form.Label>
              <ReactLanguageSelect
                defaultLanguage="en"
                names={"international"}
                // languages={languages.map(lang => lang.code)}
                // searchable={true}
                ref={languageRef}
                searchPlaceholder="Search language"
                aria-label="Default select example"
                id="Webinar_language"
                onSelect={(val) => Changes_Lang_Handler(val)}
                className="language_select"
                disabled={true}
              />

              {/* <Form.Select aria-label="Default select example">
                <option>English</option>
                <option value="1">Tamil</option>
                <option value="2">Hindi</option>
                <option value="3">Spanish</option>
              </Form.Select> */}
              <p>* The interface will be translated for your users</p>
              {error && error.Webinar_language && (
                <span style={{ color: "red" }}>{error.Webinar_language}</span>
              )}
            </Form.Group>
          </Col>
          {/* <Col lg={3}>
            <Form.Group className="mb-3" controlId="exampleForm.ControlInput1">
              <Form.Label>Webinar name</Form.Label>
              <Form.Control
                type="text"
                placeholder="Example: Webinar for my free leads"
              />
              <p>Private title. for your reference only. Max 70 characters.</p>
            </Form.Group>
          </Col> */}

          <Col lg={6}>
            <Form.Group className="mb-3" controlId="exampleForm.ControlInput1">
              <Form.Label>Webinar title</Form.Label>
              <Form.Control
                type="text"
                placeholder="Example: How to lose weight in 6 weeks"
                value={title}
                id="title"
                onChange={changesHandler}
              />
              <p>* Public title. Max 30 characters.</p>
              {error && error.Webinar_title && (
                <span style={{ color: "red" }}>{error.Webinar_title}</span>
              )}
            </Form.Group>
          </Col>
          <Col lg={6}>
            <Form.Group className="mb-3" controlId="exampleForm.ControlInput1">
              <Form.Label>Webinar description</Form.Label>
              <Form.Control
                type="text"
                placeholder="Example: All the tips and tricks to efficient diets and workouts"
                value={discription}
                id="discription"
                onChange={changesHandler}
              />
              <p>*Public description. Max 150 characters.</p>
              {error && error.Webinar_description && (
                <span style={{ color: "red" }}>
                  {error.Webinar_description}
                </span>
              )}
            </Form.Group>
          </Col>
          {/* Fake user */}
          <Col lg="6">
            {" "}
            <Form.Group
              className="mb-3"
              controlId="fake_user"
            >
              <Form.Label>Fake User Count</Form.Label>
              <Form.Control
                type="text"
                placeholder="Enter the Number"
                //id="Webinar_description"
                onChange={changesHandler}
                value={fake_user}
              />
              <p>No of user </p>
              {error && error.fake_user && (
                <span style={{ color: "red" }}>
                  {error.fake_user}
                </span>
              )}
            </Form.Group>
          </Col>
          {/* Google Form */}
          <Col lg="6">
            {" "}
            <Form.Group
              className="mb-3"
              controlId="google_form"
            >
              <Form.Label>Google Form</Form.Label>
              <Form.Control
                type="text"
                placeholder="Enter the url"
                //id="Webinar_description"
                onChange={changesHandler}
                value={google_form}
              />
              <p>give ur Google Fomr link</p>
              {error && error.google_form && (
                <span style={{ color: "red" }}>
                  {error.google_form}
                </span>
              )}
            </Form.Group>
          </Col>
        </Row>
      </div>
      <h2 className="head-field ">Webinar presenter</h2>

      <Row className=" mb-5">
        <Col xxl="6" xl="12" lg="12" className="mb-3">
          <div className=" webinar-width1 webinar-width2">
            <div className="white-box1 " style={{ minHeight: "215px" }}>
              <div className=" NewPresenters">
                <Row className="align-items-center">
                  <Col lg="12" className="">
                    <div className="row align-items-center">
                      <div className="col-lg-2 col-md-2 col-sm-2">
                        <div className="img_position_basic">
                          <Form.Control
                            ref={fileRef}
                            id="image"
                            type="file"
                            className="img_upload"
                            accept=".png,.jpg,.jpeg,.webp"
                            // value={ }
                            onChange={handleListChange}
                            disabled={editpresenter}
                          />
                          {newPresenter.presenterImage ? (
                            <>
                              {newPresenter.preview ? (
                                <OverlayTrigger
                                  overlay={
                                    <Tooltip id="tooltip-disabled">
                                      Upload image
                                    </Tooltip>
                                  }
                                >
                                  <img
                                    src={`${newPresenter.preview}`}
                                    className="img-fluid"
                                    style={{
                                      height: "60px",
                                      width: "60px",
                                      borderRadius: "50%",
                                      cursor: "pointer",
                                    }}
                                    onClick={() => fileRef.current.click()}
                                  />
                                </OverlayTrigger>
                              ) : (
                                <OverlayTrigger
                                  overlay={
                                    <Tooltip id="tooltip-disabled">
                                      Upload image
                                    </Tooltip>
                                  }
                                >
                                  <img
                                    src={`${config.API_URL}/presenter/${newPresenter.presenterImage}`}
                                    className="img-fluid"
                                    style={{
                                      height: "60px",
                                      width: "60px",
                                      borderRadius: "50%",
                                      cursor: "pointer",
                                    }}
                                    onClick={() => fileRef.current.click()}
                                  />
                                </OverlayTrigger>
                              )}
                            </>
                          ) : (
                            <OverlayTrigger
                              overlay={
                                <Tooltip id="tooltip-disabled">
                                  Upload image
                                </Tooltip>
                              }
                            >
                              <img
                                src={require("../../assets/images/profile-img2.png")}
                                className="img-fluid"
                                style={{
                                  height: "60px",
                                  width: "60px",
                                  borderRadius: "50%",
                                  cursor: "pointer",
                                }}
                                onClick={() => fileRef.current.click()}
                              />
                            </OverlayTrigger>
                          )}{" "}
                          <OverlayTrigger
                            overlay={
                              <Tooltip id="tooltip-disabled">
                                Upload image
                              </Tooltip>
                            }
                          >
                            <img
                              src={require("../../assets/images/edit.png")}
                              class=" img-fluid image_edit"
                              onClick={() => fileRef.current.click()}
                            ></img>
                          </OverlayTrigger>
                        </div>
                      </div>
                      <div className="col-lg-6 col-md-6 col-sm-6">
                        <p style={{ fontSize: "12px", color: "#878682" }}>
                          * Image is optional, Max 1MB. Valid formats: png , jpg
                          , jpeg and webp
                        </p>
                      </div>
                    </div>
                  </Col>{" "}
                  <Col lg="12" className="img_position_basic1 mt-3">
                    {" "}
                    <Row className="align-items-center img_position_basic2">
                      <div className="">
                        {" "}
                        <Form.Control
                          type="Text"
                          placeholder="First Name "
                          className="mb-3"
                          id="Presenters_name"
                          value={
                            newPresenter.Presenters_name != ""
                              ? newPresenter.Presenters_name
                              : ""
                          }
                          onChange={handleListChange}
                          disabled={editpresenter}
                        />
                        {Preerror && Preerror.Presenters_name && (
                          <span style={{ color: "red" }}>
                            {Preerror.Presenters_name}
                          </span>
                        )}{" "}
                        <Form.Control
                          type="Text"
                          className="mb-3"
                          placeholder="Email Address"
                          id="Presenters_email"
                          value={
                            newPresenter.Presenters_email != ""
                              ? newPresenter.Presenters_email
                              : ""
                          }
                          disabled={editpresenter}
                          onChange={handleListChange}
                        />
                        {Preerror && Preerror.Presenters_email && (
                          <span style={{ color: "red" }}>
                            {Preerror.Presenters_email}
                          </span>
                        )}
                      </div>
                      <div className="web_display">
                        {isOTPSent && (
                          <>
                            {" "}
                            <Form.Control
                              type="Text"
                              className="mb-3"
                              placeholder="Enter OTP"
                              id="otp"
                              // disabled={editpresenter}
                              onChange={handleListChange}
                            />
                            {Preerror && Preerror.Presenters_otp && (
                              <span style={{ color: "red" }}>
                                {Preerror.Presenters_otp}
                              </span>
                            )}
                          </>
                        )}

                        <div>
                          <button
                            className="profile-edit"
                            type="button"
                            onClick={addClick}
                            disabled={editpresenter}
                          >
                            Save
                          </button>
                        </div>
                      </div>

                    </Row>
                  </Col>
                </Row>
              </div>
            </div>
          </div>
        </Col>
        <Col xxl="6" xl="12" lg="12" className="mb-3">
          <div
            className="white-box1 "
            style={{ minHeight: "100px", background: "#f3f7f9" }}
          >
            <Row className=" present-profile align-items-center">
              <Col lg="2" md="2" sm="2" className="">
                {presenterImage != "" ? (
                  preview != "" ? (
                    <div className="img_position_basic">
                      <img
                        src={preview}
                        // onClick={() => fileRef.current.click()}
                        className="img-fluid"
                        style={{
                          height: "60px",
                          width: "60px",
                          borderRadius: "50%",
                          cursor: "pointer",
                        }}
                      />
                    </div>
                  ) : (
                    <div className="img_position_basic">
                      <img
                        src={`${config.API_URL}/presenter/${presenterImage}`}
                        className="img-fluid"
                        style={{
                          height: "60px",
                          width: "60px",
                          borderRadius: "50%",
                          cursor: "pointer",
                        }}
                      />
                    </div>
                  )
                ) : (
                  <div className="img_position_basic">
                    <img
                      src={require("../../assets/images/profile-img2.png")}
                      // onClick={() => fileRef.current.click()}
                      className="img-fluid"
                      style={{
                        height: "60px",
                        width: "60px",
                        borderRadius: "50%",
                        cursor: "pointer",
                      }}
                    />
                  </div>
                )}{" "}
              </Col>
              <Col xxl="10" xl="9" lg="10" md="10" sm="10">
                <div className=" profile-detail-align-start ">
                  <div>
                    <p className="name-profile">{presentername}</p>

                    <p className="email-profile">{presenteremail}</p>
                  </div>
                  <div className="profile-change mt-2">
                    <a
                      className="primary_btn"
                      style={{ minWidth: "42px" }}
                      onClick={() => editPresenters()}
                    >
                      Edit
                    </a>
                  </div>
                </div>{" "}
              </Col>
            </Row>

            {/* <Row className="align-items-center">
              <Col lg="4">
                <img
                  src={require("../../assets/images/profile-img.png")}
                  className="img-fluid"
                  style={{
                    height: "120px",
                    width: "120px",
                  }}
                />{" "}
              </Col>
              <Col lg="8" md="8" sm="8">
                <div className="profile-detail profile-detail-align-start">
                  <div>
                    <div className="name-content-1">
                      <p>gaayu</p>
                    </div>
                    <p className="email-profile">gaayu@yopmail.com</p>
                  </div>{" "}
                  <div className="profile-change">
                    <a className="profile-edit" href="/NewBasicSettings">
                      Change
                    </a>
                  </div>
                </div>
              </Col>
            </Row> */}
          </div>
        </Col>
      </Row>
      <h2 className="head-field ">Streaming service</h2>

      <Row className="vaffix-schedule">
        <Col xl="4" lg="6">
          <div className={`vaffix-radio-check checked`}>
            <div className="form-check check-select1">
              <input
                className="form-check-input"
                type="checkbox"
                name="vaffixRadioDefault"
                id="vaffix-radio1"
                checked={vaffixlive}
              // onChange={handleOptionChange}
              // disabled
              />
              <label className="form-check-label" htmlFor="vaffix-radio1">
                <h6>
                  {" "}
                  <img
                    src={require("../../assets/images/vaffix.png")}
                    className="img-fluid me-2"
                  />
                  Vaffix Live
                </h6>
                <p>
                  Our cutting-edge private broadcast network, simple and secure
                </p>
              </label>
            </div>
          </div>
        </Col>

        <Col xl="4" lg="6">
          <div className={`vaffix-radio-check ${youtubelive ? "checked" : ""}`}>
            <div className="form-check check-select1 radio-disable">
              <input
                className="form-check-input"
                type="checkbox"
                name="vaffixRadioDefault"
                id="youtubelive"
                checked={youtubelive}
                // value={2}
                // checked={service == 2}
                onChange={handleOptionChange}
                // disabled
                disabled={!userDetails.YouTube_key != ""}
              />
              <label className="form-check-label" htmlFor="youtubelive">
                <div className="badge-radio">
                  <h6>
                    {" "}
                    <img
                      src={require("../../assets/images/youtube-icon-1.png")}
                      className="img-fluid me-2"
                    />
                    YouTube Live
                  </h6>
                </div>
                <p>Broadcast directly to your YouTube channel and audience.</p>

                {!isEmpty(userDetails.YouTube_key) ? (
                  ""
                ) : (
                  <span className="badge bg-success">Update Channel Id </span>
                )}
              </label>
            </div>
          </div>
        </Col>

        <Col xl="4" lg="6">
          <div
            className={`vaffix-radio-check ${facebooklive ? "checked" : ""}`}
          >
            <div className="form-check check-select1 radio-disable">
              <input
                className="form-check-input"
                type="checkbox"
                name="vaffixRadioDefault"
                id="facebooklive"
                onChange={handleOptionChange}
                checked={facebooklive}
                disabled={!userDetails.FaceBook_key != ""}
              />
              <label className="form-check-label" htmlFor="facebooklive">
                <div className="badge-radio">
                  <h6>
                    {" "}
                    <img
                      src={require("../../assets/images/facebook-1.png")}
                      className="img-fluid me-2"
                    />
                    FaceBook Live
                  </h6>
                </div>
                <p>Broadcast directly to your YouTube channel and audience.</p>

                {!isEmpty(userDetails.FaceBook_key) ? (
                  ""
                ) : (
                  <span className="badge bg-success">Update Channel Id </span>
                )}
              </label>
            </div>
          </div>
        </Col>
      </Row>
    </>
  );
}
export default BasicFullconfig;
