import React, { useState } from "react";
import {
  Tabs,
  Tab,
  TabContainer,
  TabContent,
  Row,
  Col,
  Nav,
} from "react-bootstrap";
import Form from "react-bootstrap/Form";
import { toastAlert } from "../../utils/toast";

function Othersettingfullconfig(props) {
  const { settingForm, setSettngform } = props;

  const { message, urlLink, document, chat, listenOnlyMode, recording } =
    settingForm;

  const changeHandler = async (e) => {
    const { id, value } = e.target;
    setSettngform({ ...settingForm, [id]: value });
  };

  const handleFileChange = (e) => {
    const selectedFile = e.target.files[0];
    if (selectedFile) {
      // Check file type
      // if (!['application/pdf', 'application/vnd.ms-powerpoint', 'application/msword'].includes(selectedFile.type)) {
      //   toastAlert('error', 'Only PDF, PowerPoint, or Word files(DOC) are allowed.');
      //   return false
      // } else if (selectedFile.size > 1024 * 1024) { // Check file size (1 MB limit)
      //   toastAlert('error', 'File size exceeds 1 MB limit.');
      //   return false
      // } else {
      setSettngform({ ...settingForm, document: selectedFile });
      // }
    }
  };

  console.log(settingForm, "settingForm");

  return (
    <>
      <Row className="othersetting">
        <Col lg={7}>
          <Form.Group className="mb-3" controlId="exampleForm.ControlInput1">
            <Form.Label>Welcome Message</Form.Label>
            <Form.Control
              as="textarea"
              id="message"
              aria-label="With textarea"
              rows={5}
              placeholder="Enter your message"
              value={message}
              onChange={changeHandler}
            />
          </Form.Group>
        </Col>

        <Col lg={5}>
          <div className="othersetting_sub">
            <Row>
              {/* <div className="host-chat">
              <h4>Listen only mode </h4>
              <div className="switch-control">
                <Form.Check type="switch" checked={listenOnlyMode} id="all_timezone"
                  onChange={() => setSettngform({ ...settingForm, listenOnlyMode: !listenOnlyMode })} />
              </div>
            </div> */}

              {/* <Col lg="12">
              <Form.Group
                className="mb-3"
                controlId="exampleForm.ControlInput1"
              >
                <Form.Label>External URL Link</Form.Label>
                <Form.Control
                  type="text"
                  placeholder="Enter external URL Link"
                  id="urlLink"
                  value={urlLink}
                  onChange={changeHandler}
                />
              </Form.Group>
            </Col> */}
              <Col lg="12">
                <div className="switch-content">
                  <Row>
                    <Col xl="6" lg="12">
                      <div className="host-chat">
                        <h4>Attendees chat</h4>
                        <div className="switch-control">
                          <Form.Check
                            type="switch"
                            checked={chat}
                            id="all_timezone"
                            onChange={() =>
                              setSettngform({ ...settingForm, chat: !chat })
                            }
                          />
                        </div>
                      </div>
                    </Col>
                    <Col xl="6" lg="12">
                      <div className="host-chat">
                        <h4>Recording</h4>
                        <div className="switch-control">
                          <Form.Check
                            type="switch"
                            checked={recording}
                            id="all_timezone"
                            onChange={() =>
                              setSettngform({
                                ...settingForm,
                                recording: !recording,
                              })
                            }
                          />
                        </div>
                      </div>
                    </Col>
                  </Row>
                </div>
                <div className="file-drop-area mt-4">
                  <span className="choose-file-button">
                    {" "}
                    <img
                      src={require("../../assets/images/file_imge.png")}
                      alt="img"
                      className="img-fluid"
                    />
                  </span>
                  <span className="file-message">
                    {settingForm?.document
                      ? settingForm?.document?.name
                      : "Pre-upload document"}
                  </span>
                  <input
                    className="file-input"
                    accept=".pdf,.ppt,.doc,.docx"
                    onChange={handleFileChange}
                    type="file"
                    multiple
                  />
                </div>
                <p>
                  Image is optional, Max 1MB. Valid formats: pdf, ppt, doc and
                  docx
                </p>
              </Col>
            </Row>
          </div>
        </Col>
      </Row>
    </>
  );
}
export default Othersettingfullconfig;
