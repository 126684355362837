import React, { useState, useEffect } from "react";
import { NavLink, Link } from "react-router-dom";
import { clearCookies } from "../utils/cookies";
import logo from "../assets/images/logo.svg";
import ProfileImg from "../assets/images/profile-img.png";
import navicon1 from "../assets/images/nav-icon1.svg";
import navicon2 from "../assets/images/nav-icon2.svg";
import navicon3 from "../assets/images/nav-icon3.svg";
import navicon4 from "../assets/images/nav-icon4.svg";
import navicon5 from "../assets/images/nav-icon5.svg";
import navicon6 from "../assets/images/nav-icon6.svg";
import navicon7 from "../assets/images/profile-icon.svg";
import navicon8 from "../assets/images/subscription-icon.svg";
import Cookies from "js-cookie";
import { OverlayTrigger, Tooltip } from "react-bootstrap";
import { Create_Webinar, create_Meeting, getPlanDate } from "../action/user.action";
import { useSelector } from "react-redux";
import { encryptString, decryptString } from "../helper/helper";
import { toast } from 'react-toastify';
import key from "../lib/config";

export default function Navbar(props) {
  const userToken = Cookies.get("user_token");
  const [isDisabled, setIsDisabled] = useState(false);
  let user_details = useSelector((state) => state.User);

  //Redux Data Get From Data
  const PlanDetails = useSelector((state) => state.PlanDetails);
  const [planDate, setPlanDate] = useState(null);
  const date = new Date();

  useEffect(() => {
    console.log(PlanDetails[0], "PlanDetails-#");
    // Check if PlanDetails has data
    if (PlanDetails.length > 0) {
      const Pdate = PlanDetails[0].plan_expired;
      setPlanDate(Pdate);
    } else {
      console.log("Data Not Available");
    }
  }, [PlanDetails]);
  //-------------------DeletePart--------
  // //Ftech the Date particular 
  // useEffect(() => {
  //   fetchPlanDate()
  // },[])

  // //Fetch the Current Local Date 
  // const date = new Date();
  // const [planDate, setplanDate] = useState();

  // //Fetch The Date 
  // const fetchPlanDate = async () => {
  //   const { result } = await getPlanDate();
  //   setplanDate(result?.plan_expired)
  // }
  //-----------------DeletePart----------

  //Convert into DB Date to Local TimeZone
  function convertToLocalTime(dateString) {
    const planExpired = new Date(dateString);
    return planExpired;
  }
  const planExpired = convertToLocalTime(planDate);

  const handleQuickLink = async () => {
    // if (planExpired < date) {
    //   toast.warn("The plan has expired! Please subscribe to a plan.")
    //   setTimeout(() => {
    //     window.location.href = "/Subscription";
    //   }, 3000);
    //   console.log("Plan is In-Active")
    // } else {
    if (user_details) {
      setIsDisabled(true);
      var payload = {
        "presenters": [
          {
            "profile_image": "",
            "Presenters_name": user_details.userName,
            "Presenters_email": user_details.email
          }
        ],
        "Webinar_title": "Quick Meeting",
        "Webinar_language": "en",
        "Webinar_description": "Quick Meeting",
        "Webinar_name": "Quick Meeting",
        "edit": false,
        "configure": "express",
        "type": "Instant Meeting",
        "fake_user": 0
      };

      var { status, result } = await Create_Webinar(payload);

      console.log(status, result, "Webinar Creation Response");

      var payloadData = {
        id: result.result._id,
        type: 0,
      };

      console.log(payloadData, "Payload Data after Webinar Creation");
      const stringifiedPayload = JSON.stringify(payloadData);

      const encryptedData = await encryptString(stringifiedPayload, true);
      console.log(encryptedData, "Encrypted Data");

      const decryptedData = await decryptString(encryptedData, true);
      console.log(decryptedData, "Decrypted Data");

      let payloadMeetData = JSON.parse(decryptedData);
      console.log(payloadMeetData, "Final Payload Meet Data");

      console.log(payloadMeetData, "saranNew----------+++")

      var dataResult = await create_Meeting(payloadMeetData);

      console.log(dataResult, "saranNew----------+++")

      const url = dataResult.result.result.moderator_URL;
      console.log(url, "urlurlurlurlurl")
      window.open(url, "_blank");
      window.location.reload();
    }
    // }
  }


  // useEffect(() => {
  //   loadScript();
  // }, []);

  // function loadScript() {

  //   var t = $(".navbar-sticky");
  //   $(window).on("scroll load", function (e) {
  //     var e = $(window).scrollTop();
  //     120 <= e
  //       ? t.addClass("navbar-sticky--moved-up")
  //       : t.removeClass("navbar-sticky--moved-up");
  //     250 <= e
  //       ? t.addClass("navbar-sticky--transitioned")
  //       : t.removeClass("navbar-sticky--transitioned");
  //     500 <= e
  //       ? t.addClass("navbar-sticky--on")
  //       : t.removeClass("navbar-sticky--on");
  //   });
  // }

  return (
    <>
      <div className="container">
        <div className="row">
          <div className="col-12">
            <nav className="navbar navbar-expand main_navbar navbar-sticky">
              <NavLink className="navbar-brand" to="/">
                <img src={logo} className="img-fluid brand_logo" alt="logo" />
              </NavLink>
              <button
                className="navbar-toggler"
                type="button"
                data-bs-toggle="offcanvas"
                data-bs-target="#offcanvasNavbar"
                aria-controls="offcanvasNavbar"
                aria-label="Toggle navigation"
              >
                <span className="navbar-toggler-icon"></span>
              </button>
              <div
                className="offcanvas offcanvas-end"
                tabIndex={-1}
                id="offcanvasNavbar"
                aria-labelledby="offcanvasNavbarLabel"
              >
                <div className="offcanvas-header">
                  <h5
                    className="offcanvas-title"
                    id="offcanvasNavbarLabel"
                  ></h5>
                  <button
                    type="button"
                    className="btn-close"
                    data-bs-dismiss="offcanvas"
                    aria-label="Close"
                  ></button>
                </div>
                <div className="offcanvas-body">
                  <div className="ms-auto navbar_right">
                    {!userToken && (
                      <>
                        <Link
                          to="/login"
                          className="primary_btn primary_btn2 me-2"
                        >
                          Login
                        </Link>
                        <Link
                          to="/register"
                          className="primary_btn primary_btn3 "
                        >
                          Register
                        </Link>
                      </>
                    )}
                    {userToken && (
                      <>
                        <OverlayTrigger
                          placement="bottom"
                          overlay={
                            <Tooltip id="tooltip-disabled">
                              Quick Meeting
                            </Tooltip>
                          }
                        >
                          {/* <Link to="/register"
                            className={`primary_btn ${isDisabled ? 'disabled' : ''}`}
                            onClick={!isDisabled ? handleQuickLink : null}
                          >
                            Join Live Room
                          </Link> */}
                          <Link to="#"
                            className={`primary_btn primary_btn4 ${isDisabled ? 'disabled' : ''}`}
                            onClick={!isDisabled ? handleQuickLink : null}
                          >
                            Join Live Room
                          </Link>
                        </OverlayTrigger>
                        <div class="btn-group">
                          <button
                            type="button"
                            class="btn-profile"
                            data-bs-toggle="dropdown"
                            aria-expanded="false"
                          >
                            <img
                              //src={ProfileImg}
                              src={((user_details?.profile_image) ? `${key.API_URL}/presenter/${user_details?.profile_image}` : require("../assets/images/profile-img2.png"))}
                              className="img-fluid"
                              alt=""
                              style={{ height: "30px", width: "30px", borderRadius: "50%" }}
                            />
                          </button>
                          <ul class="dropdown-menu dropdown-menu-end">
                            <li>
                              <NavLink to="/inner-home">
                                <img src={navicon1} alt="" class="img-fluid" /> Dashboard
                              </NavLink>
                            </li>
                            <li>
                              <NavLink to="/webinar">
                                <img src={navicon2} alt="" class="img-fluid" /> My webinars
                              </NavLink>
                            </li>
                            <li>
                              <NavLink to="/report">
                                <img src={navicon3} alt="" class="img-fluid" /> My Report
                              </NavLink>
                            </li>
                            <li>
                              <NavLink to="/allmeetings">
                                <img src={navicon4} alt="" class="img-fluid" /> My registrants
                              </NavLink>
                            </li>
                            <li>
                              <NavLink to="/Subscription">
                                <img src={navicon8} alt="" class="img-fluid" />
                                Subscription
                              </NavLink>
                            </li>
                            <li>
                              <NavLink to="/inner-support-ticket">
                                <img src={navicon5} alt="" class="img-fluid" /> Support
                              </NavLink>
                            </li>
                            <li>
                              <NavLink to="/inner-settings">
                                <img src={navicon7} alt="" class="img-fluid" /> Profile
                              </NavLink>
                            </li>
                            <li>
                              <NavLink
                                to="javascript:void(0)"
                                onClick={clearCookies}
                              >
                                <img src={navicon6} alt="" class="img-fluid" />{" "}
                                Logout
                              </NavLink>
                            </li>
                          </ul>
                        </div>
                      </>
                    )}
                  </div>
                </div>
              </div>
            </nav>
          </div>
        </div>
      </div>
    </>
  );
}
