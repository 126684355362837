import React, { useState, useEffect } from "react";
import { Link, useNavigate } from "react-router-dom";
import logo from "../assets/images/logo.svg";
import Dropdown from "react-bootstrap/Dropdown";
import Modal from "react-bootstrap/Modal";
import Button from "react-bootstrap/Button";
import $ from "jquery";
import { toastAlert } from "../utils/toast";
//API
import { saveRegistartionTemplate } from "../action/user.action";

export default function Template_navbar(props) {
  //PROPS
  const { identifier, exportHtml, webinarId, templateId } = props;

  console.log("identifier:",identifier)

  const navigate = useNavigate();

  const [show, setShow] = useState(false);

  const handleClose = () => setShow(false);

  const closeHandler = () => {
    handleClose();
  };

  useEffect(() => {
    loadScript();
  }, []);

  function loadScript() {
    // Navbar Sticky
    var t = $(".navbar-sticky");
    $(window).on("scroll load", function (e) {
      var e = $(window).scrollTop();
      120 <= e
        ? t.addClass("navbar-sticky--moved-up")
        : t.removeClass("navbar-sticky--moved-up");
      250 <= e
        ? t.addClass("navbar-sticky--transitioned")
        : t.removeClass("navbar-sticky--transitioned");
      500 <= e
        ? t.addClass("navbar-sticky--on")
        : t.removeClass("navbar-sticky--on");
    });
  }

  //Save - Template
  const saveTemplate = async () => {
    //UPDATE CONTENT
    let content =
      templateId != "none"
        ? document.getElementsByClassName("templateData")[1].outerHTML
        : document.getElementsByClassName("templateData")[0].outerHTML;

    //PAYLOAD
    let payload = {
      content: content,
      identifier: identifier,
      default_template: false,
      status: "active",
      webinarId: webinarId,
      templateId: templateId,
    };

    try {
      let { status, result } = await saveRegistartionTemplate(payload);
      console.log(status, result, "status, result");

      if (status) {
        toastAlert("success", result?.message);
        redirectToWebinarConfig();
      }
    } catch (err) {
      console.log(err, "err");
    } finally {
    }
  };

  const refreshPage = () => {
    navigate(0);
  };

  const redirectToWebinarConfig = () => {
    navigate(`/configSettings/${webinarId}`, {
      state: { result: "registractionPage" },
    });
  };

  return (
    <>
      <nav className="navbar navbar-expand main_navbar template_nav">
        <div className="container">
          <Link className="navbar-brand" to="/">
            <img src={logo} className="img-fluid brand_logo" alt="logo" />
          </Link>
          <button
            className="navbar-toggler"
            type="button"
            data-bs-toggle="offcanvas"
            data-bs-target="#offcanvasNavbar"
            aria-controls="offcanvasNavbar"
            aria-label="Toggle navigation"
          >
            <span className="navbar-toggler-icon"></span>
          </button>
          <div
            className="offcanvas offcanvas-end"
            tabIndex={-1}
            id="offcanvasNavbar"
            aria-labelledby="offcanvasNavbarLabel"
          >
            <div className="offcanvas-header">
              <h5 className="offcanvas-title" id="offcanvasNavbarLabel"></h5>
              <button
                type="button"
                className="btn-close"
                data-bs-dismiss="offcanvas"
                aria-label="Close"
              ></button>
            </div>
            <div className="offcanvas-body">
              <div className="ms-auto navbar_right">
                <Dropdown>
                  <Dropdown.Toggle
                    variant="success"
                    id="dropdown-basic"
                    className="primary_btn"
                  >
                    Action
                  </Dropdown.Toggle>

                  <Dropdown.Menu>
                    {identifier != undefined ? (
                      <>
                        <Dropdown.Item onClick={refreshPage}>
                          <img
                            className="img-fluid me-2"
                            src={require("../assets/images/template_img/rotate-left.png")}
                            alt="pencil image"
                          />
                          Reset
                        </Dropdown.Item>

                        <Dropdown.Item
                          href="javascript:void(0)"
                          onClick={redirectToWebinarConfig}
                        >
                        
                          Cancel and Exit
                        </Dropdown.Item>

                        <Dropdown.Item onClick={saveTemplate}>
                         
                          Save and Exit
                        </Dropdown.Item>
                      </>
                    ) : (
                      <>
                        <Dropdown.Item onClick={exportHtml}>                       
                          Save and Exit
                        </Dropdown.Item>

                        <Dropdown.Item
                          href="javascript:void(0)"
                          onClick={redirectToWebinarConfig}
                        >
                          <img
                            className="img-fluid me-2"
                            src={require("../assets/images/template_img/close-circle.png")}
                            alt="pencil image"
                          />
                          Cancel and Exit
                        </Dropdown.Item>
                      </>
                    )}
                  </Dropdown.Menu>

                </Dropdown>
              </div>
            </div>
          </div>
        </div>
      </nav>

      <Modal show={show} onHide={handleClose} className="template_modal_1">
        <Modal.Header closeButton>
          <Modal.Title className="text-center mx-auto">
            Cancel and Exit
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div className="mx-auto text-center">
            <img
              class="img-fluid mb-4 mt-1"
              src={require("../assets/images/template_img/modal_img.png")}
              alt="group image"
            />
            <p>
              You are about to exit the page builder.Any unsaved changes will be
              lost.
            </p>
          </div>
          <div className="modal_body_btn mt-5">
            <Button className="modal_button_one me-2" onClick={closeHandler}>
              Cancel
            </Button>
            <Button className="modal_button_one" onClick={closeHandler}>
              Exit
            </Button>
          </div>
        </Modal.Body>
        {/* <Modal.Footer>
        <div>
            <Button className="modal_button_one m" onClick={handleClose}>
        Cancel
          </Button>
          <Button className="modal_button_one" onClick={handleClose}>
            Exit
          </Button>
          </div>
        
        </Modal.Footer> */}
      </Modal>
    </>
  );
}
