import React, { useEffect, useRef, useState } from "react";
import { editEmailTemplate, getMailByID, sendLinkToTelegram } from "../../action/user.action";
import { Modal } from "react-bootstrap";
import { Link } from "react-router-dom";
import { Editor } from "@tinymce/tinymce-react";
import Form from "react-bootstrap/Form";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import { toastAlert } from "../../utils/toast";
import { OverlayTrigger, Tooltip } from "react-bootstrap";

//Controller Data 
import { controllerData } from '../../lib/controller';
import { toast } from "react-toastify";
import { useSelector } from "react-redux";

function Notificationfullconfig(props) {
  //Redux Data Get From Data
  const PlanDetails = useSelector((state) => state.PlanDetails);
  const [packListNames, setPackListNames] = useState([]);
  const [planDate, setPlanDate] = useState(null);

  useEffect(() => {
    console.log(PlanDetails[0], "PlanDetails-#");

    // Check if PlanDetails has data
    if (PlanDetails.length > 0) {
      const names = PlanDetails[0]?.PlanDetails?.packList;
      const date = PlanDetails[0].plan_expired;

      if (Array.isArray(names)) {
        setPackListNames(names);
        console.log(names, "Pack List Names");
      } else {
        console.log("packListNames is not an array or is undefined.");
      }
      setPlanDate(date);
    } else {
      console.log("Data Not Available");
    }
  }, [PlanDetails]);

  const { EditData } = props;

  console.log(EditData, "EditData");

  let id = EditData._id;
  const editorRef = useRef(null);
  const [responseData, setResponseData] = useState(null);
  const [loading, setloading] = useState(false);
  const [email, setEmail] = useState(false);

  // const [EditData, setEditData] = useState([]);
  const [templateData, setTemplateData] = useState();

  const registerConfirmMessage = async (ids, type) => {
    const payload = {
      id: ids,
      type: type,
      webinarId: id,
    };
    const { status, result } = await getMailByID(payload);

    console.log(status, result, "status, result");
    // setTemplateData()
    setResponseData(result);
    // if (status) {
    setTemplateData(result?.result?.content);
    handleEmailShow();
    // }
  };

  const handleEmailClose = () => setEmail(false);
  const handleEmailShow = () => setEmail(true);
  const handleTelegramMessage = async (e) => {

    const value = (Array.isArray(packListNames) && packListNames.some(id => id === controllerData?.Telegram_support))
    if (value) {
      const payload = {
        webinarId: id,
      };
      console.log(payload, "payloadpayloadpayload");
      const result = await sendLinkToTelegram(payload);
      if (result.result.message == "Success") {
        toastAlert("success", "Link send Successfully");
      } else {
        toastAlert("error", result.result.message);
      }
    } else {
      toast.warning("Please subscribe to the pack.")
    }

  }
  const handleSubmit = async (e) => {
    try {
      if (editorRef.current) {
        setloading(true);

        const payload = {
          type: responseData?.result?.identifier,
          content: editorRef.current.getContent(),
          langCode: responseData?.result?.langCode,
          webid: id,
          default_template: responseData?.result?.default_template
            ? true
            : false,
          tempid: responseData?.result?._id,
        };

        console.log(payload, "payload", editorRef.current.getContent());

        if (editorRef.current.getContent() != templateData) {
          const { status, result } = await editEmailTemplate(payload);
          if (status) {
            toastAlert("success", "Submitted");
            handleEmailClose();
            setResponseData(null);
            setTemplateData("");
            // getWebinarDetails(id);
          }
        } else {
          toastAlert("success", "Submitted");
          handleEmailClose();
          setResponseData(null);
          // if (status) {
          setTemplateData("");
        }
      }
    } catch {
    } finally {
      setloading(false);
      // set_show_notification_edit(show_notification_edit);
    }
  };

  return (
    <>
      <h2 className="head-field ">Reminder notifications</h2>
      <div className="dashbordrightbody-list1 registration_alert">
        <h6 className="alert alert-info" style={{ color: "black" }}>
          All emails notifications will be sent out from{" "}
          <span style={{ color: "black" }}>"vaffix.com"</span>.
        </h6>
        <div className="pre_notification">
          <ul className="pre_notification_list">
            <li className="list_msg">
              <h4 className="mb-2">Pre-Webinar notifications</h4>
              <p>
                Keep them in the loop of the webinar they've registered for,
                It's a great opportunity to hand them their link to the webinar
                room, and to remind them of the topics that will be covered. It
                helps greatly to maximize attendance!
              </p>
              <ul className="pre_notification_1 mt-3">
                <li>
                  <div className="confirm_msg">
                    <div className="add_info">
                      <h4>Register Confirmation Message</h4>
                      {/* <div className="add_info_span">
                        <a href="#">Add Phone</a>
                        <a href="#">Add Email</a>
                      </div> */}
                    </div>
                    <div className="confirm_msg_content mt-4">
                      <div className="add_info_content">
                        <img
                          src={require("../../assets/images/registration_imgs/msg.png")}
                          className="img-fluid"
                        />
                        <div>
                          <h4>Immediately</h4>
                          <p>[Confirmation] Your webinar link</p>
                        </div>
                      </div>
                      <div className="add_info_img">
                        {/* <a href="#">
                          {" "}
                          <img
                            src={require("../../assets/images/registration_imgs/mail.png")}
                            className="img-fluid"
                          />
                        </a> */}
                        <OverlayTrigger
                          overlay={
                            <Tooltip id="tooltip-disabled">
                              Edit & confirm
                            </Tooltip>
                          }
                        >
                          <a href="#">
                            <img
                              src={require("../../assets/images/registration_imgs/pencil.png")}
                              className="img-fluid"
                              onClick={() =>
                                registerConfirmMessage(
                                  EditData?.templateReg,
                                  "User_Register"
                                )
                              }
                            />
                          </a>
                        </OverlayTrigger>
                        {/* <a href="#">
                          {" "}
                          <img
                            src={require("../../assets/images/registration_imgs/wrong.png")}
                            className="img-fluid"
                          />
                        </a> */}
                      </div>
                    </div>
                  </div>
                </li>
                {/* <li>
                  <div className="confirm_msg">
                    <div className="add_info">
                      <h4>Register Confirmation Message</h4>
                      <div className="add_info_span">
                        <a href="#">Add Phone</a>
                        <a href="#">Add Email</a>
                      </div>
                    </div>
                  </div>
                </li>*/}
                {EditData.mettingType != 0 && (
                  <>
                    <li>
                      <div className="confirm_msg">
                        <div className="add_info">
                          <h4>Reminder message</h4>
                          {/* <div className="add_info_span">
                        <a href="#">Add Phone</a>
                        <a href="#">Add Email</a>
                      </div> */}
                        </div>
                        <div className="confirm_msg_content mt-4">
                          <div className="add_info_content">
                            <img
                              src={require("../../assets/images/registration_imgs/msg.png")}
                              className="img-fluid"
                            />
                            <div>
                              <h4>15 minutes before</h4>
                              <p>The session is about to begin</p>
                            </div>
                          </div>
                          <div className="add_info_img">
                            {/* <a href="#">
                          {" "}
                          <img
                            src={require("../../assets/images/registration_imgs/mail.png")}
                            className="img-fluid"
                          />
                        </a> */}
                            <OverlayTrigger
                              overlay={
                                <Tooltip id="tooltip-disabled">
                                  Edit & confirm
                                </Tooltip>
                              }
                            >
                              <a href="#">
                                <img
                                  src={require("../../assets/images/registration_imgs/pencil.png")}
                                  className="img-fluid"
                                  onClick={() =>
                                    registerConfirmMessage(
                                      EditData?.templateNoti,
                                      "Reminder_Notify"
                                    )
                                  }
                                />
                              </a>
                            </OverlayTrigger>
                            {/* <a href="#">
                          {" "}
                          <img
                            src={require("../../assets/images/registration_imgs/wrong.png")}
                            className="img-fluid"
                          />
                        </a> */}
                          </div>
                        </div>
                      </div>
                    </li>
                  </>
                )}
              </ul>
            </li>
            <li className="list_msg">
              <h4>Add Notifications to telegram Channel</h4>
              <p>
                You link will shared with your Telegram group so that everyone can check it out and Join to your meeting
              </p>
              <ul className="pre_notification_1">
                <li>
                  <div className="confirm_msg">
                    <div className="add_info">
                      <h4>Post Webinar follow ups in telegram</h4>
                      <div className="add_info_span">
                        <a href="#" onClick={handleTelegramMessage}>Send Link</a>
                      </div>
                    </div>
                  </div>
                </li>
              </ul>
            </li>
          </ul>
        </div>
      </div>

      <Modal
        size="lg"
        show={email}
        onHide={handleEmailClose}
        keyboard={false}
        centered
        className="emailnotifications"
      >
        <Modal.Header className="model-head">
          <Modal.Title className="model-title">Email Notifications</Modal.Title>
          <div>
            <Link
              to="#"
              className="primary_btn primary_btn2 me-4"
              onClick={handleEmailClose}
            >
              Cancel
            </Link>
            <button
              className="primary_btn"
              type="button"
              onClick={(e) => handleSubmit(e)}
            >
              Confirm
            </button>
          </div>
        </Modal.Header>
        <Modal.Body className="mailnotify-body">
          <div className="mail-head">
            <Row>
              <Col lg="12">
                {" "}
                <Form.Label htmlFor="inputPassword5">Webinar name</Form.Label>
                <Form.Control
                  disabled={true}
                  type="text"
                  id="inputPassword5"
                  placeholder="[confirmation] Your webinar link"
                  aria-describedby="passwordHelpBlock"
                  value={EditData?.title}
                />
              </Col>
            </Row>
            <Row>
              <Col lg="12">
                <p>Email body</p>
                {/* <img
                  src={require("../../assets/images/email-body.png")}
                  className="img-fluid pt-2"
                />{" "} */}
                <Editor
                  apiKey="54h6qy3bgdr4e2v1znhrddnngex0hy8165p9bmkupwosdlc7"
                  cloudChannel="5-stable"
                  disabled={false}
                  initialValue={templateData}
                  inline={false}
                  onInit={(_evt, editor) => (editorRef.current = editor)}
                  //onEditorChange={(e)=>console.log(e.target.value)}
                  //onChange={(e)=>console.log(e.target.getContent())}
                  plugins=""
                  tagName="div"
                  textareaName=""
                  toolbar=""
                  value=""
                  outputFormat="html"
                  init={{
                    height: 500,
                    language: "en",
                    menubar: true,
                    plugins: [
                      "advlist autolink lists link image charmap print preview anchor",
                      "searchreplace visualblocks code fullscreen",
                      "insertdatetime media table paste code help wordcount",
                    ],
                    toolbar:
                      "undo redo | formatselect | bold italic backcolor | \
                alignleft aligncenter alignright alignjustify | \
                bullist numlist outdent indent | removeformat | help",
                  }}
                />
              </Col>
            </Row>
          </div>
        </Modal.Body>
      </Modal>
    </>
  );
}
export default Notificationfullconfig;
