
import { createSlice, createAsyncThunk } from '@reduxjs/toolkit'
import { get_PlanDetails } from '../../action/user.action'

// const initialState = {
//     userID: '',              
//     PlanAmount: 0,           
//     currency: '',            
//     secret: '',              
//     NoofDays: 0,             
//     CurrentPlan: '',         
//     status: false,         
//     plan_expired: false,   
//     planID: ''               
// }

const initialState = {}

export const getPlanDetails = createAsyncThunk('Plan_Option', async (data) => {
    const { status, result } = await get_PlanDetails(data)
    console.log(status, result, 'UserSlice ------ redux')
    if (status) {
        return result.result
    }
})


const PlanSlice = createSlice({
    name: 'Plan_Option',
    initialState,
    reducers: {
        setPlanDetails: (state, action) => {
            state = action.payload
            return state
        }
    },
    extraReducers(build) {
        build.addCase(getPlanDetails.fulfilled, (state, action) => {
            state = action.payload
            return state
        })
    }
})

export const { setPlanDetails } = PlanSlice.actions
export default PlanSlice.reducer