import React from "react";
import Modal from "react-bootstrap/Modal";

const ConfirmationModal = ({
  showModal,
  hideModal,
  confirmModal,
  config,
  id,
  loader,
}) => {
  return (
    <div className="popup1">
      <Modal
        size="md"
        show={showModal}
        onHide={hideModal}
        backdrop="static"
        keyboard={false}
      >
        <Modal.Header className="model-head" closeButton>
          <Modal.Title className="model-title">{config?.title}</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div className=" ">
            <div className=" instant-meeting-box completed_meeting">
              <div className="finishpage mt-3">
                <p>{config?.message}</p>
              </div>
              <div className="model-btn mt-3">
                {loader ? (
                  <i
                    className="fa fa-spinner fa-spin"
                    aria-hidden="true"
                    id="circle"
                  ></i>
                ) : (
                  <>
                    <button
                      type="button"
                      className="btn primary_btn"
                      onClick={() => confirmModal(id)}
                    >
                      Yes
                    </button>
                    <button
                      type="button"
                      className="btn primary_btn primary_btn2 "
                      onClick={hideModal}
                    >
                      No
                    </button>
                  </>
                )}
              </div>
            </div>
          </div>
        </Modal.Body>
        <Modal.Footer className="footer-model"></Modal.Footer>
      </Modal>
    </div>
  );
};
export default ConfirmationModal;
