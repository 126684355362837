import React, { useEffect, useState } from "react";
import {
  Tabs,
  Tab,
  TabContainer,
  TabContent,
  Row,
  Col,
  Nav,
} from "react-bootstrap";
import Form from "react-bootstrap/Form";
import InputGroup from "react-bootstrap/InputGroup";
import Button from "react-bootstrap/Button";
import TemplateFullconfig from "./template-fullconfig";
import { toastAlert } from "../../utils/toast";
import { OverlayTrigger, Tooltip } from "react-bootstrap";
import Owntemp from "./owntemplate";
import { Scrollbars } from "react-custom-scrollbars-2";

import { useSelector } from "react-redux";
//Controller Data 
import { controllerData } from '../../lib/controller';
import { toast } from "react-toastify";

function RegistrationFullconfig(props) {

  const userDetails = useSelector((state) => state.User);
  console.log(userDetails, "userDetails");

  //Redux Data Get From Data
  const PlanDetails = useSelector((state) => state.PlanDetails);
  const [packListNames, setPackListNames] = useState([]);

  useEffect(() => {
    console.log(PlanDetails[0], "PlanDetails-#");

    // Check if PlanDetails has data
    if (PlanDetails.length > 0) {
      const names = PlanDetails[0]?.PlanDetails?.packList;
      if (Array.isArray(names)) {
        setPackListNames(names);
        console.log(names, "Pack List Names");
      } else {
        console.log("packListNames is not an array or is undefined.");
      }
    } else {
      console.log("Data Not Available");
    }
  }, [PlanDetails]);
  
  const value = (Array.isArray(packListNames) && packListNames.some(id => id === controllerData?.Personalized_template_for_webinars))

  const handleTabClick = () => {
    if (!value) {
      toast.warning("Please subscribe to the pack.");
    }
  };

  const { RegisterForm, setRegisterForm, error, webinarId } = props;
  const {
    meetingType,
    templateId,
    firstName,
    Lastname,
    email,
    phone,
    registrationFees,
    hasRegistration,
    hasPassword,
    notiMailId,
    masterpassword,
    notifylist,
    hasNotified,
  } = RegisterForm;

  useEffect(() => { }, [RegisterForm]);

  console.log(RegisterForm, "RegisterForm");
  const [showTab1, setShowTab1] = useState(true);
  const [showTab2, setShowTab2] = useState(false);

  const [activeTab, setActiveTab] = useState(hasRegistration ? "tab2" : "tab1");

  const [feesTab, setFeesTab] = useState(hasRegistration ? true : false);

  const toggleTab1 = () => {
    setShowTab1(true);
    setShowTab2(false);
    setActiveTab("tab1");
    setRegisterForm({ ...RegisterForm, notiMailId: "" });
  };

  const toggleTab2 = () => {
    setShowTab1(false);
    setShowTab2(true);
    setActiveTab("tab2");
  };

  const [showPasswordprotectionTab1, setShowPasswordprotectionTab1] =
    useState(true);
  const [showPasswordprotectionTab2, setShowPasswordprotectionTab2] =
    useState(false);
  const [activeTab1, setActiveTab1] = useState("PasswordprotectionTab1");

  const togglePasswordprotectionTab1 = () => {
    setShowPasswordprotectionTab1(true);
    setShowPasswordprotectionTab2(false);
    setActiveTab1("PasswordprotectionTab1");
  };

  const togglePasswordprotectionTab2 = () => {
    setShowPasswordprotectionTab1(false);
    setShowPasswordprotectionTab2(true);
    setActiveTab1("PasswordprotectionTab2");
  };
  const [showMasterPassword1, setShowMasterPassword1] = useState(false);
  const [showMasterPassword2, setShowMasterPassword2] = useState(false);
  const toggleMasterPassword1 = () => {
    setShowMasterPassword1(true);
    setShowMasterPassword2(false);
  };
  const toggleMasterPassword2 = () => {
    setShowMasterPassword1(false);
  };

  const changeHandler = async (id) => {
    // const { id } = e.target
    console.log(id, "id");
    if (id == "Firstname" || id == "Email" || id == "phone") {
      toastAlert("warning", `${id} are mandatory`);
      return false;
    }
    setRegisterForm({ ...RegisterForm, [id]: !RegisterForm[id] });
  };

  console.log(RegisterForm, "initialForm11111");

  const feesToogle = (type) => {
    if (type) {
      setFeesTab(true);
    }
  };

  console.log(feesTab, "feee");
  const [showregisterfreeTab1, setShowregisterfreeTab1] = useState(true);
  const [showregisterfreeTab2, setShowregisterfreeTab2] = useState(false);
  const [registeractiveTab1, setregisteractiveTab1] =
    useState("registerfreeTab1");

  const toggleregisterfreeTab1 = () => {
    setShowregisterfreeTab1(true);
    setShowregisterfreeTab2(false);
    setregisteractiveTab1("registerfreeTab1");
  };

  const toggleregisterfreeTab2 = () => {
    setShowregisterfreeTab1(false);
    setShowregisterfreeTab2(true);
    setregisteractiveTab1("registerfreeTab2");
  };

  console.log("activeTab", feesTab);

  //---------------------------------------------------------------------------------------------------
  // const redirectpage = () => {
  //   const url = `/canva-template/${webinarId}/${meetingType}`;
  //   const a = document.createElement('a');
  //   a.href = url;
  //   a.click(); // Trigger the click event
  // };
  //----------------------------------------------------------------------------------------------------

  return (
    <>
      {/* <h2 className="head-field mt-2"> Registration page design</h2>
      <TemplateFullconfig
        webinarId={webinarId}
        templateId={templateId}
        meetingType={meetingType}
      /> */}
      {/* <div>
        <Tabs
          defaultActiveKey="default-temp"
          id="uncontrolled-tab-example"
          className="mb-3 temp_config"
        >
          <Tab eventKey="default-temp" title="Default template">
            <TemplateFullconfig
              webinarId={webinarId}
              templateId={templateId}
              meetingType={meetingType}
            />
          </Tab>
          <Tab eventKey="own-temp" title="Own template">
            <Owntemp />
          </Tab>
        </Tabs>
      </div> */}
      <div className="temp-head mb-3">
        <ul class="nav nav-tabs temp_config" id="myTab" role="tablist">
          <li class="nav-item" role="presentation">
            <button
              class="nav-link active"
              id="Default-tab"
              data-bs-toggle="tab"
              data-bs-target="#Default"
              type="button"
              role="tab"
              aria-controls="Default"
              aria-selected="true"
            >
              Pre Styled Template
            </button>
          </li>
          <li class="nav-item" role="presentation">
            <button
              class="nav-link"
              id="Own-tab"
              data-bs-toggle="tab"
              data-bs-target="#Own"
              type="button"
              role="tab"
              aria-controls="Own"
              aria-selected="false"
              onClick={handleTabClick}
            >
              Personalized Template
            </button>
          </li>
        </ul>
        <div>
          {/* <Button className="primary_btn primary_btn2 " onClick={redirectpage}>
            Add New Personalized Template
          </Button> */}
        </div>
      </div>
      <div class="tab-content" id="myTabContent">
        <div
          class="tab-pane fade show active"
          id="Default"
          role="tabpanel"
          aria-labelledby="Default-tab"
        >
          <TemplateFullconfig
            webinarId={webinarId}
            templateId={templateId}
            meetingType={meetingType}
          />
        </div>
        <div
          class="tab-pane fade"
          id="Own"
          role="tabpanel"
          aria-labelledby="Own-tab"
        >
          {value ? (<Owntemp
              webinarId={webinarId}
              templateId={templateId}
              meetingType={meetingType}
            />) : null }
        </div>
      </div>
      <h2 className="head-field"> Registration form fields</h2>
      <p>Add fields</p>
      <div className="Add_fields">
        <InputGroup
          className={
            firstName ? `mb-3 input-box-1 active` : "mb-3 input-box-1 "
          }
        >
          <Form.Control
            value="Firstname"
            aria-label="Recipient's username"
            aria-describedby="basic-addon2"
          />
          <OverlayTrigger
            overlay={<Tooltip id="tooltip-disabled">Add firstname</Tooltip>}
          >
            <Button
              id="button-addon2"
              onClick={() => changeHandler("Firstname")}
            >
              {" "}
              <img
                src={require("../../assets/images/add.png")}
                className="img-fluid"
              />
            </Button>
          </OverlayTrigger>
        </InputGroup>
        <InputGroup
          className={Lastname ? `mb-3 input-box-1 active` : "mb-3 input-box-1 "}
        >
          <Form.Control
            value="Lastname"
            aria-label="Recipient's username"
            aria-describedby="basic-addon2"
          />
          <OverlayTrigger
            overlay={<Tooltip id="tooltip-disabled">Add lastname</Tooltip>}
          >
            <Button
              id="button-addon2"
              onClick={() => changeHandler("Lastname")}
            >
              {" "}
              <img
                src={require("../../assets/images/add.png")}
                className="img-fluid"
              />
            </Button>
          </OverlayTrigger>
        </InputGroup>
        <InputGroup
          className={
            email == 1 ? `mb-3 input-box-1 active` : "mb-3 input-box-1 "
          }
        >
          <Form.Control
            value="Email"
            aria-label="Recipient's username"
            aria-describedby="basic-addon2"
          />
          <OverlayTrigger
            overlay={<Tooltip id="tooltip-disabled">Add email</Tooltip>}
          >
            <Button id="button-addon2" onClick={() => changeHandler("Email")}>
              {" "}
              <img
                src={require("../../assets/images/add.png")}
                className="img-fluid"
              />
            </Button>
          </OverlayTrigger>
        </InputGroup>
        <InputGroup
          className={
            phone == 1 ? `mb-3 input-box-1 active` : "mb-3 input-box-1 "
          }
        >
          <Form.Control
            value="phone"
            aria-label="Recipient's username"
            aria-describedby="basic-addon2"
          />
          <OverlayTrigger
            overlay={<Tooltip id="tooltip-disabled">Add phone number</Tooltip>}
          >
            <Button id="button-addon2" onClick={() => changeHandler("phone")}>
              {" "}
              <img
                src={require("../../assets/images/add.png")}
                className="img-fluid"
              />
            </Button>
          </OverlayTrigger>
        </InputGroup>
      </div>

      <div className="White_box_1 ">
        <div className="Mandatory_flelds">
          <p className="Mandatory_flelds_head">Form fields</p>
          <div className="Add_fields_2">
            <p>Mandatory</p>
            <div class="Add_fields_2_content">
              <p>First Name /</p>
            </div>
            {/* <div class="Add_fields_2_content active">
              <img
                src={require("../../assets/images/Mandatory_icon.png")}
                className="img-fluid"
              />
              <p>Last Name</p>
            </div> */}
            <div class="Add_fields_2_content">
              <p>Mail /</p>
            </div>
            <div class="Add_fields_2_content">
              <p>Phone </p>
            </div>
          </div>
        </div>
        <div className="Add_fields">
          {firstName == 1 && (
            <InputGroup className=" input-box-1 ">


              <Form.Control
                placeholder="First name"
                aria-label="Recipient's username"
                aria-describedby="basic-addon2"
                disabled
              />
              <OverlayTrigger
                overlay={<Tooltip id="tooltip-disabled">Close</Tooltip>}
              >
                <Button
                  id="button-addon2"
                  onClick={() => changeHandler("Firstname")}
                >
                  {" "}
                  <img
                    src={require("../../assets/images/close_img.png")}
                    className="img-fluid"
                  />
                </Button>
              </OverlayTrigger>
            </InputGroup>
          )}
          {email == 1 && (
            <InputGroup className=" input-box-1 ">

              <Form.Control
                placeholder="Email address"
                aria-label="Recipient's username"
                aria-describedby="basic-addon2"
                disabled
              />
              <OverlayTrigger
                overlay={<Tooltip id="tooltip-disabled">Close</Tooltip>}
              >
                <Button
                  id="button-addon2"
                  onClick={() => changeHandler("Email")}
                >
                  {" "}
                  <img
                    src={require("../../assets/images/close_img.png")}
                    className="img-fluid"
                  />
                </Button>
              </OverlayTrigger>
            </InputGroup>
          )}

        </div>
        <div className="Add_fields mt-3">
          {Lastname == 1 && (
            <InputGroup className=" input-box-1 ">

              <Form.Control
                placeholder="Last name"
                aria-label="Recipient's username"
                aria-describedby="basic-addon2"
                disabled
              />
              <OverlayTrigger
                overlay={<Tooltip id="tooltip-disabled">Close</Tooltip>}
              >
                <Button
                  id="button-addon2"
                  onClick={() => changeHandler("Lastname")}
                >
                  {" "}
                  <img
                    src={require("../../assets/images/close_img.png")}
                    className="img-fluid"
                  />
                </Button>
              </OverlayTrigger>
            </InputGroup>
          )}
          {phone == 1 && (
            <InputGroup className=" input-box-1 ">

              <Form.Control
                placeholder="Phone number"
                aria-label="Recipient's username"
                aria-describedby="basic-addon2"
                disabled
              />
              <OverlayTrigger
                overlay={<Tooltip id="tooltip-disabled">Close</Tooltip>}
              >
                <Button
                  id="button-addon2"
                  onClick={() => changeHandler("phone")}
                >
                  {" "}
                  <img
                    src={require("../../assets/images/close_img.png")}
                    className="img-fluid"
                  />
                </Button>
              </OverlayTrigger>
            </InputGroup>
          )}
        </div>
      </div>
      <h2 className="head-field mt-4">
        {" "}
        Free registration vs Paid registration
      </h2>
      <div class="  Auto_subscription">
        <div
          class="nav nav-pills "
          id="paid_registration"
          role="tablist"
          aria-orientation="vertical"
        >
          <div
            class="nav-link nav_link1 active"
            id="paid_registration-tab"
            data-bs-toggle="pill"
            data-bs-target="#p-paid_registration"
            // type="button"
            role="tab"
            aria-controls="v-paid_registration"
            aria-selected={registrationFees == 0 ? true : false}
            accessKey={feesTab}
          >
            <div
              class="form-check"
              onClick={() =>
                setRegisterForm({ ...RegisterForm, hasRegistration: false })
              }
            >
              <input
                className="form-check-input"
                type="radio"
                name="registerOptions1"
                id="registerNotification1"
                data-toggle="collapse"
                data-target="#registerfreeImg1"
                aria-expanded="false"
                aria-controls="registerfreeImg1"
                // checked={registeractiveTab1 === "registerfreeTab1"} // Check if registeractiveTab is "registerfreeTab1"
                checked={hasRegistration ? false : true}
              />
              <label
                className="form-check-label"
                htmlFor="registerNotification1"
              >
                <h6>It's free to register</h6>
                <p>The registration process is free of charge.</p>
              </label>
            </div>
          </div>

          <div
            class="nav-link nav_link1"
            id="paid_registration-tab_2"
            data-toggle="collapse"
            // data-target="#regfee"
            data-bs-toggle="pill"
            data-bs-target="#p-paid_registration_2"
            type="button"
            role="tab"
            aria-expanded="false"
            // aria-controls="notifyImg2"
            aria-controls="v-paid_registration_2"
            aria-selected={registrationFees != 0 ? true : false}
          >
            <div
              class="form-check"
              onClick={() =>
                setRegisterForm({ ...RegisterForm, hasRegistration: true })
              }
            >
              <input
                className="form-check-input"
                type="radio"
                name="registerOptions1"
                id="registerNotification2"
                data-toggle="collapse"
                data-target="#registerfreeImg2"
                aria-expanded="false"
                aria-controls="registerfreeImg2"
                checked={hasRegistration ? true : false}
              // checked={feesTab}
              // checked={registeractiveTab1 === "registerfreeTab2"} // Check if registeractiveTab is "registerfreeTab2"
              />
              <label
                className="form-check-label"
                htmlFor="registerNotification2"
              >
                <h6> Charge a registration fee</h6>
                <p>
                  Your users need to pay to complete their webinar registration.
                </p>
              </label>
            </div>
          </div>
        </div>
        {userDetails.payment_key && hasRegistration && (
          <div className="collapse show tab-content-11-body">
            <div className="whitebox-content">
              <div>
                <input
                  type="number"
                  class="form-control"
                  id="exampleFormControlInput1"
                  placeholder="Enter INR amount"
                  value={registrationFees}
                  onChange={(e) =>
                    setRegisterForm({
                      ...RegisterForm,
                      registrationFees: e.target.value,
                    })
                  }
                />
              </div>

              {error && error.registrationFees && (
                <span style={{ color: "red" }}>{error.registrationFees}</span>
              )}
            </div>
          </div>
        )}
      </div>

      <h2 className="head-field mt-4"> New registration notification</h2>

      <div class="tab_content_11">
        <div className=" tab_flex">
          <div
            className={`tab_content_1_sub ${!hasNotified ? "active" : ""}`}
            onClick={() =>
              setRegisterForm({ ...RegisterForm, hasNotified: false })
            }
          >
            <div className="form-check">
              <input
                className="form-check-input"
                type="radio"
                name="notificationOptions"
                id="noNotification"
                data-toggle="collapse"
                data-target="#notifyImg1"
                aria-expanded="false"
                aria-controls="notifyImg1"
                // checked={activeTab === "tab1"} // Check if activeTab is "tab1"
                checked={!hasNotified}
              />
              <label className="form-check-label" htmlFor="noNotification">
                <h6> No notification</h6>
                <p>
                  You will not receive an email notification when an attendee
                  registers to a webinar.
                </p>
              </label>
            </div>
          </div>

          <div
            className={`tab_content_1_sub2 ${hasNotified ? "active" : ""}`}
            onClick={() =>
              setRegisterForm({ ...RegisterForm, hasNotified: true })
            }

          // onClick={toggleTab2}
          >
            <div className="form-check">
              <input
                className="form-check-input"
                type="radio"
                name="notificationOptions"
                id="sendNotification"
                data-toggle="collapse"
                data-target="#notifyImg2"
                aria-expanded="false"
                aria-controls="notifyImg2"
                // checked={activeTab === "tab2"} // Check if activeTab is "tab2"
                checked={hasNotified}
              />
              <label className="form-check-label" htmlFor="sendNotification">
                <h6> Send notification</h6>
                <p>
                  You will be notified by email whenever an attendee registers
                  for a webinar.
                </p>
              </label>
            </div>
          </div>
        </div>

        <div className="tab-img ">
          {!hasNotified && (
            <div className="collapse show ">
              <div>
                <img
                  src={require("../../assets/images/mail-transfer2.png")}
                  className="img-fluid"
                  alt="Notification Image 1"
                />
              </div>
            </div>
          )}

          {hasNotified && (
            <div className="collapse show ">
              <div>
                <img
                  src={require("../../assets/images/mail-transfer.png")}
                  className="img-fluid"
                  alt="Notification Image 2"
                />
              </div>
            </div>
          )}
        </div>
      </div>
      {hasNotified && (
        <div className="select_box_1" id="notifyImg2">
          <Form.Label>Recipient's email</Form.Label>
          <Form.Select
            aria-label="Default select example"
            onChange={(e) =>
              setRegisterForm({ ...RegisterForm, notiMailId: e.target.value })
            }
          >
            {notiMailId != "" && notiMailId != "Select Email" ? (
              <>
                <option>Select Email</option>
                <option selected value={notiMailId}>
                  {notiMailId}
                </option>
              </>
            ) : (
              <>
                <option>Select Email</option>
                {notifylist.map((list) => (
                  <option value={list}>{list}</option>
                ))}
              </>
            )}
          </Form.Select>

          {error && error.notiMailId && (
            <span style={{ color: "red" }}>{error.notiMailId}</span>
          )}
        </div>
      )}

      <h2 className="head-field mt-4">Password protection</h2>

      <div class="">
        <div className="flex-tab-11">
          <div
            className={`tab_content_1_sub flex-grow-1 ${!hasPassword ? "active" : ""
              }`}
            onClick={() =>
              setRegisterForm({ ...RegisterForm, hasPassword: false })
            }
          >
            <div className="form-check">
              <input
                className="form-check-input"
                type="radio"
                name="notificationOptions1"
                id="noNotification1"
                data-toggle="collapse"
                data-target="#PasswordprotectionImg1"
                aria-expanded="false"
                aria-controls="PasswordprotectionImg1"
                // checked={activeTab1 === "PasswordprotectionTab1"} // Check if activeTab is "PasswordprotectionTab1"

                checked={!hasPassword ? true : false}
              />
              <label className="form-check-label" htmlFor="noNotification1">
                <h6>No password needed</h6>
                <p>
                  Anyone with a valid link will be able to access the webinar
                  room.
                </p>
              </label>
            </div>
          </div>

          <div
            className={`tab_content_1_sub2 ${hasPassword ? "active" : ""}`}
            onClick={() =>
              setRegisterForm({ ...RegisterForm, hasPassword: true })
            }

          // onClick={togglePasswordprotectionTab2}
          >
            <div className="form-check">
              <input
                className="form-check-input"
                type="radio"
                name="notificationOptions1"
                id="sendNotification1"
                data-toggle="collapse"
                data-target="#PasswordprotectionImg2"
                aria-expanded="false"
                aria-controls="PasswordprotectionImg2"
                // checked={activeTab1 === "PasswordprotectionTab2"} // Check if activeTab is "PasswordprotectionTab2"
                checked={hasPassword ? true : false}
              />
              <label className="form-check-label" htmlFor="sendNotification1">
                <h6> Password is required</h6>
                <p>
                  Both a valid link and a secret password will be required to
                  enter the webinar room.
                </p>
              </label>
            </div>
          </div>
        </div>
      </div>

      {hasPassword && (
        <div className="collapse show tab-content-11-body">
          <div className="whitebox-content">
            <div class="mb-3">
              <label for="exampleFormControlInput1" class="form-label">
                Master password
              </label>
              <input
                type="password"
                class="form-control"
                id="exampleFormControlInput1"
                placeholder="Enter your password"
                onChange={(e) =>
                  setRegisterForm({
                    ...RegisterForm,
                    masterpassword: e.target.value,
                  })
                }
              />
            </div>

            {error && error.password && (
              <span style={{ color: "red" }}>{error.password}</span>
            )}
          </div>
        </div>
      )}
    </>
  );
}
export default RegistrationFullconfig;
