import React, { useEffect, useState } from "react";
import Container from "react-bootstrap/Container";
import Header from "../components/Navbar";
import Footer from "../components/Footer";
import { faEye, faEyeSlash } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import logo from "../assets/images/logo.svg";
import {
  Register_User,
  Resend_Email,
  Verify_Email,
} from "../action/user.action";
import { toastAlert } from "../utils/toast";
import { Alert } from "react-bootstrap";
import { useParams } from "react-router-dom";
import { Link } from "react-router-dom";
function ConfirmMail() {
  return (
    <>
      <div className="nav_sec">
        <Header />
      </div>

      <section className="login_page confrim_mail">
        <div className="login_content">
          <h4 className="mb-4">Verify Your Account </h4>
          <Alert
            key={"primary"}
            variant={"primary"}
            className="p-2 alert_email"
          >
            <i className="me-2 fa fa-info-circle"></i>
            We have sent you the activation link again. kindly check your
            Inbox/Spam folder!
          </Alert>
          <div className="text-center mt-4">
            <Link to="/login" className="primary_btn">
              Sign In
            </Link>
          </div>
        </div>
      </section>
      <Footer />
    </>
  );
}

export default ConfirmMail;
