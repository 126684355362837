import React, { useState } from "react";
import Button from "react-bootstrap/Button";
import Modal from "react-bootstrap/Modal";
import nextImg from "../../assets/images/side-icon.svg";
import nextImg1 from "../../assets/images/side-icon2.svg";
import { Link } from "react-router-dom";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Form from "react-bootstrap/Form";

const WebinarPopup1 = () => {
  const [email, setEmail] = useState(false);

  const handleEmailClose = () =>setEmail(false);
  const handleEmailShow = () => setEmail(true);
  return (
    <div className="popup2">
      <Button variant="primary" onClick={handleEmailShow}>
        Launch demo modal
      </Button>
      <Modal
        size="lg"
        show={email}
        onHide={handleEmailClose}
        keyboard={false}
        centered
        className="emailnotifications"
      >
        <Modal.Header className="model-head">
          <Modal.Title className="model-title">Email Notifications</Modal.Title>
          <div>
            <Link to="#" className="primary_btn primary_btn2 me-4" onClick={handleEmailShow}>
            
              Cancel
            </Link>
            <Link to="#" className="primary_btn">
             
              Confirm
            </Link>
          </div>
        </Modal.Header>
        <Modal.Body className="mailnotify-body">
          <div className="mail-head">
            <Row>
              <Col lg="6">
                <p>
                  Send this email <span>Immediately</span>upon registration
                </p>
              </Col>
              <Col lg="6">
                {" "}
                <Form.Label htmlFor="inputPassword5">Webinar name</Form.Label>
                <Form.Control
                  type="text"
                  id="inputPassword5"
                  placeholder="[confirmation] Your webinar link"
                  aria-describedby="passwordHelpBlock"
                />
              </Col>
            </Row>
            <Row>
              <Col lg="6">
                <p>Email body</p>
                <img
                  src={require("../../assets/images/email-body.png")}
                  className="img-fluid pt-2"
                />{" "}
              </Col>
              <Col lg="6">
                <p>Short codes</p>
          
                <div className="shortcodes mt-2">
                  <h6>
                    Title :<span>#Sample Title 1</span>
                  </h6>
                  <h6>
                    Title :<span>#Sample Title 1</span>
                  </h6>
                  <h6>
                    Title :<span>#Sample Title 1</span>
                  </h6>
                  <h6>
                    Title :<span>#Sample Title 1</span>
                  </h6>
                  <h6>
                    Title :<span>#Sample Title 1</span>
                  </h6>
                  <h6>
                    Title :<span>#Sample Title 1</span>
                  </h6>
                  <h6>
                    Title :<span>#Sample Title 1</span>
                  </h6>
                  <h6>
                    Title :<span>#Sample Title 1</span>
                  </h6>
                </div>
            
              </Col>
            </Row>
          </div>
        </Modal.Body>
       
      </Modal>
    </div>
  );
};
export default WebinarPopup1;
