import React, { useEffect, useState } from "react";
import { Link, useLocation, useParams } from "react-router-dom";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Tab from "react-bootstrap/Tab";
import Header from "../components/Navbar";
import Innerfooter from "../components/inner-footer";
import {
  GetSingle_CompltedMeeting,
  getAllWebinar,
  getAll_Complted_meeting,
  getCompleted_Metting,
} from "../action/user.action";
import Innerpagenavbar from "../components/innerpage-navbar";
import Nav from "react-bootstrap/Nav";
import ProgressBar from "react-bootstrap/ProgressBar";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faUser,
  faUsers,
  faChartPie,
  faFaceSmile,
  faClipboard,
  faArrowRightToBracket,
  faComment,
  faHand,
  faGrin,
  faHandsWash,
  faThumbsUp,
  faThumbsDown,
} from "@fortawesome/free-solid-svg-icons";
import DataTable from "react-data-table-component";
import { isEmpty } from "../helper/helper";
import { toastAlert } from "../utils/toast";
import LiveChart from "./chart/live.chart";
import { Scrollbars } from "react-custom-scrollbars-2";
import Accordion from "react-bootstrap/Accordion";
import moment from "moment";
import Select from "react-select";

const columns = [
  {
    name: "Users",
    selector: (row) => row.Users,
    width: "240px",
    sortable: true,
  },
  {
    name: "Online Time",
    selector: (row) => row.online_time,
    width: "150px",
    sortable: true,
  },
  {
    name: "Talk Time",
    selector: (row) => row.Talk_time,
    width: "150px",
    sortable: true,
  },
  {
    name: "Webcam Time",
    selector: (row) => row.Webcam_time,
    width: "170px",
    sortable: true,
  },
  {
    name: "Message",
    selector: (row) => row.Message,
    width: "130px",
    sortable: true,
  },
  {
    name: "Emojis",
    selector: (row) => row.Emojis,
    sortable: true,
  },
  {
    name: "Raise Hands",
    selector: (row) => row.Raise_Hands,
    width: "150px",
    sortable: true,
  },
  {
    name: "Activity Score",
    selector: (row) => row.Activity_Score,
    width: "180px",
    sortable: true,
  },
  // {
  //   name: "Status",
  //   selector: (row) => row.Status,
  //   sortable: true,
  // },
];

const data = [
  {
    id: 1,
    Users: (
      <div className="users_tab">
        <div>
          <h6>Gaayathri</h6>
          <p>
            <FontAwesomeIcon icon={faArrowRightToBracket} /> June 4,10:12:12 AM
          </p>
        </div>
      </div>
    ),
    online_time: (
      <div className="online_time">
        <div>
          <div className="mb-2 text-center">
            {" "}
            <img
              src={require("../assets/images/volume_img.png")}
              className="img-fluid"
            />{" "}
            00:12:23
          </div>
          <div>
            <ProgressBar now={60} />
          </div>
        </div>
      </div>
    ),
    Talk_time: (
      <div className="Talk_time">
        <p>00:12:23</p>
      </div>
    ),
    Webcam_time: (
      <div className="Webcam_time">
        <p>00:12:23</p>
      </div>
    ),
    Message: (
      <div className="Message">
        <FontAwesomeIcon icon={faComment} /> 1
      </div>
    ),
    Emojis: (
      <div className="Emojis">
        <FontAwesomeIcon icon={faFaceSmile} /> 1 Happy
      </div>
    ),
    Raise_Hands: (
      <div className="Raise_Hands">
        <FontAwesomeIcon icon={faHand} /> 1
      </div>
    ),
    Activity_Score: (
      <div>
        <div className="Activity_Score">
          <div className="active1"></div>
          <div className="active2"></div>
          <div className="active3"></div>
          <div className="active4"></div>
          <div></div>
          <div></div>
        </div>
      </div>
    ),
    Status: (
      <div className="status">
        {/* <button className="online_btn">Online</button> */}
        <button className="offline_btn">Offline</button>
      </div>
    ),
  },
];
const columns1 = [
  {
    name: "Users",
    selector: (row) => row.Users,
    width: "240px",
    sortable: true,
  },
  {
    name: "Online Time",
    selector: (row) => row.online_time,
    width: "150px",
    sortable: true,
  },
  {
    name: "Talk Time",
    selector: (row) => row.Talk_time,
    width: "150px",
    sortable: true,
  },
  {
    name: "Webcam Time",
    selector: (row) => row.Webcam_time,
    width: "170px",
    sortable: true,
  },
  {
    name: "Message",
    selector: (row) => row.Message,
    width: "130px",
    sortable: true,
  },
  {
    name: "Emojis",
    selector: (row) => row.Emojis,
    sortable: true,
  },
  {
    name: "Raise Hands",
    selector: (row) => row.Raise_Hands,
    width: "150px",
    sortable: true,
  },
  {
    name: "Activity Score",
    selector: (row) => row.Activity_Score,
    width: "180px",
    sortable: true,
  },
  // {
  //   name: "Status",
  //   selector: (row) => row.Status,
  //   sortable: true,
  // },
];

const data1 = [
  {
    id: 1,
    Users: (
      <div className="users_tab">
        <div>
          <h6>Gaayathri</h6>
          <p>
            <FontAwesomeIcon icon={faArrowRightToBracket} /> June 4,10:12:12 AM
          </p>
        </div>
      </div>
    ),
    online_time: (
      <div className="online_time">
        <div>
          <div className="mb-2 text-center">
            {" "}
            <img
              src={require("../assets/images/volume_img.png")}
              className="img-fluid"
            />{" "}
            00:12:23
          </div>
          <div>
            <ProgressBar now={60} />
          </div>
        </div>
      </div>
    ),
    Talk_time: (
      <div className="Talk_time">
        <p>00:12:23</p>
      </div>
    ),
    Webcam_time: (
      <div className="Webcam_time">
        <p>00:12:23</p>
      </div>
    ),
    Message: (
      <div className="Message">
        <FontAwesomeIcon icon={faComment} /> 1
      </div>
    ),
    Emojis: (
      <div className="Emojis">
        <FontAwesomeIcon icon={faFaceSmile} /> 1 Happy
      </div>
    ),
    Raise_Hands: (
      <div className="Raise_Hands">
        <FontAwesomeIcon icon={faHand} /> 1
      </div>
    ),
    Activity_Score: (
      <div>
        <div className="Activity_Score">
          <div className="active1"></div>
          <div className="active2"></div>
          <div className="active3"></div>
          <div className="active4"></div>
          <div></div>
          <div></div>
        </div>
      </div>
    ),
    Status: (
      <div className="status">
        {/* <button className="online_btn">Online</button> */}
        <button className="offline_btn">Offline</button>
      </div>
    ),
  },
];
const columns2 = [
  {
    name: "User",
    selector: (row) => row.User,
    sortable: true,
  },
  {
    name: "Total",
    selector: (row) => row.Total,
    sortable: true,
  },
  {
    name: "Question_1",
    selector: (row) => row.Question_1,
    sortable: true,
  },
];

const data2 = [
  {
    id: 1,
    User: (
      <div className="users_tab">
        <h6>Gaayathri</h6>
      </div>
    ),
    Total: "1",

    Question_1: "True",
  },
];

const WebinarAnalytics = () => {
  const location = useLocation();
  console.log("analusss");

  console.log(location, "location");

  const [SessionDetaisl, setSessionDetaisl] = useState(
    location.state == undefined ? {} : location.state
  );
  const [analyticsData, setAnalyticsData] = useState("");
  const [userList, setUserList] = useState([]);
  const [selectedOption, setSelectedOption] = useState(1);
  let emojis_config = {
    applause: "fas fa-hands-wash",
    happy: "far fa-grin",
    thumbsUp: "far fa-thumbs-up",
    raiseHand: "far fa-hand-paper",
    neutral: "far fa-meh",
    thumbsDown: "far fa-thumbs-down",
    sad: "far fa-frown",
  };

  const [selTab, setSelTab] = useState({
    webinar: null,
    session: null,
  });
  const { webinar, session } = selTab;

  useEffect(() => {
    if (analyticsData !== "") {
      console.log("analytics data=", analyticsData);
      analyticsData?.userList?.forEach(function (usrObject) {
        let userData = {
          id: 1,
          Users: (
            <div className="users_tab">
              <div>
                <h6>{usrObject?.users?.name}</h6>
                <p>
                  {/* <FontAwesomeIcon icon={faArrowRightToBracket} />{" "} */}
                  {usrObject?.users?.registerOn}
                </p>
              </div>
            </div>
          ),
          online_time: (
            <div className="online_time">
              <div>
                <div className="mb-2 text-center">
                  {" "}
                  <img
                    src={require("../assets/images/volume_img.png")}
                    className="img-fluid"
                  />{" "}
                  {usrObject?.onlineTime?.duration}
                </div>
                <div>
                  <ProgressBar now={usrObject?.onlineTime?.progress} />
                </div>
              </div>
            </div>
          ),
          Talk_time: (
            <div className="Talk_time">
              <p>
                {usrObject?.talkTime?.toString()?.includes("NaN")
                  ? "00:00:00"
                  : usrObject?.talkTime}
              </p>
            </div>
          ),
          Webcam_time: (
            <div className="Webcam_time">
              <p>{usrObject?.webcamTime}</p>
            </div>
          ),
          Message: (
            <div className="Message">
              <img
                src={require("../assets/images/registration_imgs/quotation.png")}
                className="img-fluid"
              />{" "}
              {usrObject?.message?.count}
            </div>
          ),
          Emojis: (
            <>
              {usrObject?.emojis.map((obj) => {
                return (
                  <>
                    {obj.name !== "raiseHand" && (
                      <div className="Emojis">
                        <i
                          class={emojis_config[obj?.name]}
                          style={{ fontSize: "14px" }}
                        ></i>
                        {obj?.count}{" "}
                        {obj?.name.charAt(0).toUpperCase() + obj?.name.slice(1)}
                      </div>
                    )}
                  </>
                );
              })}
            </>
          ),
          Raise_Hands: (
            <>
              {usrObject?.raiseHands?.count > 0 && (
                <div className="Raise_Hands">
                  <FontAwesomeIcon icon={faHand} />{" "}
                  {usrObject?.raiseHands?.count}
                </div>
              )}
            </>
          ),
          Activity_Score: (
            <>
              {!usrObject?.activityScore?.isModerator ? (
                <div>
                  <div className="Activity_Score">
                    {usrObject?.activityScore?.score.map((item) => {
                      return <div className={`active${item}`}></div>;
                    })}
                  </div>
                </div>
              ) : (
                "N/A"
              )}
            </>
          ),
        };
        setUserList((prevArray) => [...prevArray, userData]);
      });
    }
  }, [analyticsData]);

  const getTimeLineCount = () => {
    return analyticsData?.timelineInfo?.reduce(function (a, b) {
      return a + b["count"];
    }, 0);
  };

  const [formValue, setFormValue] = useState({});
  const [webinarinfo, setWebinarInfo] = useState();
  const [initialState, setInitialState] = useState({});
  const [chartData, setChartData] = useState([]);
  const [xAxisData, setXaxisData] = useState([]);
  const [infoValues, setInfoValues] = useState({});

  var [learningDashboard, setlearningDashboard] = useState({});

  const [allMeeting, setAllmeeting] = useState([]);

  const [singleMeeting, setSingleMeeting] = useState([]);

  const getCompleted_Meetings = async () => {
    try {
      console.log(SessionDetaisl, "SessionId");
      // const { status, result } = await getAll_Complted_meeting();
      const { status, result } = await getAllWebinar();
      console.log("result.result491", result.result);
      if (status) {
        setAllmeeting(result.result);
      }

      if (isEmpty(SessionDetaisl)) {
        // const { status, result } = await getAll_Complted_meeting();
        // console.log(status, result, "sakthi ----- dashboard");
        // if (status) {
        //   setAllmeeting(result.result);
        // }
      } else {
        const { status, result } = await GetSingle_CompltedMeeting(
          SessionDetaisl
        );

        console.log(status, result, "sakthi-----dashboard");
        if (status) {
          setSingleMeeting(result.result);
          const webinar = result.result[0];
          setSelTab({
            ...selTab,
            ...{
              webinar: { value: webinar?._id, label: webinar?.title },
              session: {
                value: webinar?.webinar_Details[0]?._id,
                label: convertTime(
                  webinar?.webinar_Details[0]?.startDate,
                  webinar?.webinar_Details[0]?.timeZone
                ),
              },
            },
          });
          setFormValue({
            webinarId: result.result?.[0]._id,
            sessionId: result.result?.[0]?.webinar_Details?.[0]?._id,
          });
        }
      }

      // if(SessionId)
    } catch (error) {
      console.log(error);
    }
  };

  console.log(allMeeting, "allMeeting");
  useEffect(() => {
    getCompleted_Meetings();
  }, [SessionDetaisl]);

  useEffect(() => {
    if (!isEmpty(singleMeeting)) {
      submithandler();
    }
  }, [singleMeeting]);

  console.log(initialState, "initialState");

  const changeHandler = async (e) => {
    setFormValue({});
    setWebinarInfo();
    setFormValue({ webinarId: e.value });

    const singleWebinar = allMeeting.find((val) => val?._id == e.value);

    let newWeb = !singleWebinar
      ? null
      : { value: singleWebinar?._id, label: singleWebinar?.title };
    setSelTab({ ...selTab, ...{ webinar: newWeb, session: null } });
    // setSelTab({ ...selTab, ...{ webinar: newWeb, session: null } })
    const data = allMeeting.find((val) => val._id == e.value);
    console.log("data555", data);
    setSelectedOption(1);
    setWebinarInfo(data);
  };

  console.log(singleMeeting, "webinarinfo");

  const getLearningDashBorad = async (data) => {
    console.log(data, "data----11111");

    for (let i = 0; i < data.tabledata.length; i++) {
      let userData = {
        id: 1,
        Users: (
          <div className="users_tab">
            <div className="user_dp">
              <FontAwesomeIcon icon={faUser} />
            </div>
            <div>
              <h6>{"Test user"}</h6>
              <p>
                {/* <FontAwesomeIcon icon={faArrowRightToBracket} /> {registerOn} */}
              </p>
            </div>
          </div>
        ),
        online_time: (
          <div className="online_time">
            <div>
              <div className="mb-2 text-center">
                {" "}
                <img
                  src={require("../assets/images/volume_img.png")}
                  className="img-fluid"
                />{" "}
                {data?.tabledata?.[i]?.usrOnlineDuration}
              </div>
              <div>
                <ProgressBar now={data?.tabledata?.[i]?.OnlineProgress} />
              </div>
            </div>
          </div>
        ),
        Talk_time: (
          <div className="Talk_time">
            <p>{data?.tabledata?.[i]?.totalTalkTimeValue}</p>
          </div>
        ),
        Webcam_time: (
          <div className="Webcam_time">
            <p>{data?.tabledata?.[i]?.userWebcamTiming}</p>
          </div>
        ),
        Message: (
          <div className="Message">
            <FontAwesomeIcon icon={faComment} />{" "}
            {/* {specificUserData?.totalOfMessages} */}
          </div>
        ),
        Emojis: (
          <>
            {data?.tabledata?.[i]?.emojis_data.map((obj) => {
              return (
                <>
                  {/* {obj.name !== "raiseHand" && (
                      <div className="Emojis">
                        <i
                          class={emojis_config[obj?.name]}
                          style={{ fontSize: "14px" }}
                        ></i>
                        {obj?.count}{" "}
                        {obj?.name.charAt(0).toUpperCase() + obj?.name.slice(1)}
                      </div>
                    )} */}
                </>
              );
            })}
          </>
        ),
        Raise_Hands: (
          <>
            {data?.tabledata?.[i]?.raiseHand_data?.count > 0 && (
              <div className="Raise_Hands">
                <FontAwesomeIcon icon={faHand} />{" "}
                {data?.tabledata?.[i]?.raiseHand_data?.count}
              </div>
            )}
          </>
        ),
        Activity_Score: (
          <>
            {/* {!specificUserData?.isModerator ? (
                <div>
                  <div className="Activity_Score">
                    {totActivityScore.map((item) => {
                      return <div className={`active${item}`}></div>;
                    })}
                  </div>
                </div>
              ) : (
                "N/A"
              )} */}
          </>
        ),
        // Status: (
        //   <div className="status">
        //     {/* <button className="online_btn">Online</button> */}
        //     <button className="offline_btn">Offline</button>
        //   </div>
        // ),
      };
      // setUserList((prevArray) => [...prevArray, userData]);
    }
  };

  // Function to convert time in hours, minutes, and seconds to total seconds
  function timeToSeconds(hours, minutes, seconds) {
    return hours * 3600 + minutes * 60 + seconds;
  }

  // Function to convert total seconds to hours, minutes, and seconds
  function secondsToTime(seconds) {
    const hours = Math.floor(seconds / 3600);
    const minutes = Math.floor((seconds % 3600) / 60);
    const secondsRemaining = seconds % 60;
    return { hours, minutes, seconds: secondsRemaining };
  }

  const submithandler = async () => {
    try {
      if (!isEmpty(formValue)) {
        console.log(
          formValue,
          initialState.meeting_id,
          "formValue.webinarId != initialState.meeting_id"
        );

        if (!formValue.sessionId) {
          toastAlert("error", "Please select session");
          return false;
        }
        // if (formValue.webinarId != initialState._id) {
        setXaxisData([]);
        setChartData([]);

        console.log(analyticsData, "ananalyticsData");
        console.log(chartData, xAxisData, "xAxisData 11111");
        const { status, result } = await getCompleted_Metting(formValue);
        console.log(status, result, "status, result");

        let data = result?.result?.addtionInfo?.LarningDashboard;
        //Watch full webinar details:
        let userWatchFullWebinar = 0;
        const times = [];
        data.userList.forEach(function (item, index) {
          if (
            item?.onlineTime?.progress > 70 &&
            item?.activityScore?.isModerator == false
          ) {
            userWatchFullWebinar++;
          }
          if (item?.activityScore?.isModerator == false) {
            const TVAL = item?.onlineTime?.duration?.split(":");
            times.push({
              hours: parseInt(TVAL[0]),
              minutes: parseInt(TVAL[1]),
              seconds: parseInt(TVAL[2]),
            });
          }
        });

        // Calculate total time in seconds
        let totalSeconds = 0;
        times.forEach((time) => {
          totalSeconds += timeToSeconds(time.hours, time.minutes, time.seconds);
        });
        // Calculate average time in seconds
        const averageSeconds = totalSeconds / times.length;
        // Convert average seconds back to hours, minutes, and seconds
        const averageTime = secondsToTime(averageSeconds);

        data.userWatchFullWebinarCount = userWatchFullWebinar;
        data.avgMeetingTime =
          times.length != 0
            ? (parseInt(averageTime.hours) < 9
              ? "0" + parseInt(averageTime.hours)?.toString()
              : parseInt(averageTime.hours)) +
            ":" +
            (parseInt(averageTime.minutes) < 9
              ? "0" + parseInt(averageTime.minutes)?.toString()
              : parseInt(averageTime.minutes)) +
            ":" +
            (parseInt(averageTime.seconds) < 9
              ? "0" + parseInt(averageTime.seconds)?.toString()
              : parseInt(averageTime.seconds))
            : "00:00:00";
        console.log("data:====", data, times);

        setUserList([]);
        setAnalyticsData(data);

        if (status) {
          const newChartData = [];
          const newXAxisData = [];

          for (let i of result.dashboardDate.result) {
            newChartData.push(i.users);
            newXAxisData.push(i.intervalStart);
          }

          result.result.session_Length = result.dashboardDate.session_length;
          setChartData(newChartData);
          setXaxisData(newXAxisData);
          setInitialState(result.result);

          //const result111111=await getLearningDashBorad(result.result.addtionInfo.LarningDashboard)
          // setlearningDashboard(result.result.addtionInfo.LarningDashboard)
        }
        // }
      } else {
        toastAlert("error", "Select a webinar");
      }
    } catch (error) {
      console.log(error);
    }
  };

  const selectSession = async (e) => {
    console.log(e.value, "e.value");
    setSelectedOption(e.value);
    // setFormValue({ ...formValue, sessionId: e.value });

    const selectedSessionId = e.value;
    const selectedSession = allMeeting.reduce((acc, webinar) => {
      const session = webinar.sessions.find((session) => session._id === selectedSessionId);
      if (session) {
        acc = { ...session, webinar_id: webinar._id };
      }

      return acc;
    }, null);

    if (selectedSession) {
      setSelTab({
        ...selTab,
        session: {
          value: selectedSession.webinar_id,
          label: convertTime(
            selectedSession.startDate,
            selectedSession.timeZone
          ),
        },
      });

      setFormValue((prevFormValue) => ({
        ...prevFormValue,
        sessionId: selectedSessionId

      }));
    } else {
      console.log("No session found for the selected ID.");
    }
  };

  const convertTime = (sessiondate, timezone) => {
    console.log(sessiondate, timezone);
    const localTime = moment(sessiondate);

    const pacificMidwayDateTime = localTime.tz(timezone);

    console.log(
      pacificMidwayDateTime.format("MM/DD/YYYY hh:mm A"),
      "istDateTime1"
    );

    return pacificMidwayDateTime.format("MM/DD/YYYY hh:mm A");
  };

  return (
    <>
      <div className="nav_sec">
        <Header />
      </div>
      <div className="dashboard-page">
        <div className="container">
          <div className="page-flex">
            <div className="page-left">
              <Innerpagenavbar />
            </div>
            <div className="page-right">
              <div className="dashbord-right">
                <div className="dashbord-right-body-1">
                  <div className="dashbord-right-body">
                    <div className="dashbordrightbody-header">
                      <p>My Analytics</p>
                    </div>
                    <div className="dashbordrightbody-body">
                      <div className="dashbordrightbody-list1 analytics">
                        <div className="analytics_select">
                          <div className="select_box">
                            <label
                              for="exampleFormControlInput1"
                              class="form-label"
                            >
                              Webinar
                            </label>

                            {/* {singleMeeting.length > 0 ? (

                              <Select
                                id="filterSessions"
                                className="filterSessions"
                                defaultValue={singleMeeting[0].title}
                                options={{
                                  value: singleMeeting[0].title,
                                  label: singleMeeting[0].title,
                                }}
                              />
                            ) : (
                              <Select
                                id="filterSessions"
                                className="filterSessions"
                                aria-label="Default select example"
                                onChange={changeHandler}
                                defaultValue={1}
                                options={[
                                  { value: 1, label: 'Open this select menu' }, // Custom "Select Session" option
                                  ...(Array.isArray(allMeeting)
                                    ? allMeeting.map((val) => ({
                                      value: val._id,
                                      label: val.title,
                                    }))
                                    : []),
                                ]}
                                placeholder="Open this select menu"
                              />
                            )} */}

                            <Select
                              id="filterSessions"
                              className="filterSessions"
                              aria-label="Default select example"
                              onChange={changeHandler}
                              // defaultValue={1}
                              value={webinar}
                              options={[
                                { value: 1, label: "Open this select menu" }, // Custom "Select Session" option
                                ...(Array.isArray(allMeeting)
                                  ? allMeeting.map((val) => ({
                                    value: val._id,
                                    label: val.title,
                                  }))
                                  : []),
                              ]}
                              theme={(theme) => ({
                                ...theme,
                                borderRadius: "3px",

                                colors: {
                                  ...theme.colors,
                                  primary25: "#dbdfe3",
                                  primary: "#dbdfe3",
                                  neutral50: "#696e70",
                                  primary50: "#dbdfe3",
                                },
                              })}
                              placeholder="Open this select menu"
                            />
                          </div>
                          <div className="select_box">
                            <label
                              for="exampleFormControlInput1"
                              class="form-label"
                            >
                              Session
                            </label>
                            {/* {singleMeeting.length > 0 ? (

                              <Select
                                id="filterSessions"
                                className="filterSessions"
                                defaultValue={moment(
                                  singleMeeting?.[0]?.webinar_Details?.[0]
                                    .startDate
                                ).format("LLLL")}
                                options={{
                                  value: moment(
                                    singleMeeting?.[0]?.webinar_Details?.[0]
                                      .startDate
                                  ).format("LLLL"),
                                  label: moment(
                                    singleMeeting?.[0]?.webinar_Details?.[0]
                                      .startDate
                                  ).format("LLLL"),
                                }}
                              />


                            ) : (

                              <Select
                                id="filterSessions"
                                className="filterSessions"
                                aria-label="Default select example"
                                onChange={selectSession}
                                defaultValue={selectedOption}
                                options={[
                                  { value: 1, label: 'Select Session' }, // Custom "Select Session" option
                                  ...(Array.isArray(webinarinfo?.webinar_Details)
                                    ? webinarinfo.webinar_Details.map((val) => ({
                                      value: val._id,
                                      label: moment(val.startDate).format("LLLL"),
                                    }))
                                    : []),
                                ]}
                                placeholder="Open this select menu"
                              />


                            )} */}

                            <Select
                              id="filterSessions"
                              className="filterSessions"
                              aria-label="Default select example"
                              onChange={selectSession}
                              // defaultValue={selectedOption}
                              value={session}
                              options={[
                                { value: 1, label: "Select Session" }, // Custom "Select Session" option
                                ...(Array.isArray(webinarinfo?.sessions)
                                  ? webinarinfo.sessions.map((val) => ({
                                      value: val._id,
                                      label: moment(val.startDate).format(
                                        "LLLL"
                                      ),
                                    }))
                                  : []),
                              ]}
                              theme={(theme) => ({
                                ...theme,
                                borderRadius: "3px",

                                colors: {
                                  ...theme.colors,
                                  primary25: "#dbdfe3",
                                  primary: "#dbdfe3",
                                  neutral50: "#696e70",
                                  primary50: "#dbdfe3",
                                },
                              })}
                              placeholder="Open this select menu"
                            />
                          </div>
                          {/* <div className="select_box">
                        <label
                          for="exampleFormControlInput1"
                          class="form-label"
                        >
                          Event
                        </label>
                        <select
                          class="form-select"
                          aria-label="Default select example"
                        >
                          <option selected>All Event</option>
                          {webinarinfo?.webinar_Details.map(
                            (val) => (
                              <option value="2">All Event</option>
                            )
                          )}
                          <option value="3">Three</option>
                        </select>
                      </div> */}

                          {/* {singleMeeting.length > 0 ? (
                            ""
                          ) : (
                            <Link
                              to="#"
                              className="primary_btn"
                              onClick={submithandler}
                            >
                              <img
                                src={require("../assets/images/chevron-right.png")}
                                height="16"
                                width="16"
                                className="img-fluid"
                              />
                              Go
                            </Link>
                          )} */}

                          <Link
                            to="#"
                            className="primary_btn"
                            onClick={submithandler}
                          >
                            Go
                          </Link>
                        </div>
                      </div>
                    </div>
                  </div>
                  {initialState._id ? (
                    <>
                      <div className="dashbord-right-body mt-4">
                        <div className="dashbordrightbody-header">
                          <p>Traffic</p>
                        </div>
                        <div className="dashbordrightbody-body">
                          <div className="dashbordrightbody-list1 analytics">
                            <div className="traffic_box">
                              <div className="traffic_info">
                                <div>
                                  <img
                                    src={require("../assets/images/registration_imgs/traffic_img_1.png")}
                                    className="img-fluid"
                                  />
                                  <p>Visitors</p>
                                </div>
                                <span>0</span>
                              </div>
                              <div className="traffic_info">
                                <div>
                                  <img
                                    src={require("../assets/images/registration_imgs/traffic_img_1.png")}
                                    className="img-fluid"
                                  />
                                  <p>Register rate</p>
                                </div>
                                <span>
                                  {initialState?.addtionInfo?.RegisterRate}%
                                </span>
                              </div>
                              <div className="traffic_info">
                                <div>
                                  <img
                                    src={require("../assets/images/registration_imgs/traffic_img_2.png")}
                                    className="img-fluid"
                                  />
                                  <p>Registrants</p>
                                </div>
                                <span>
                                  {
                                    initialState?.Users?.filter(
                                      (val) => val.isModerator != true
                                    ).length
                                  }
                                </span>
                              </div>

                              {/* <div className="traffic_info">
                                <div>
                                  <img
                                    src={require("../assets/images/registration_imgs/location.png")}
                                    className="img-fluid"
                                  />
                                  <p>Live attendees</p>
                                </div>
                                <span>
                                  {
                                    initialState?.Users?.filter(
                                      (val) => val.isModerator != true
                                    ).length
                                  }
                                </span>
                              </div>
                              <div className="traffic_info">
                                <div>
                                  <img
                                    src={require("../assets/images/registration_imgs/location.png")}
                                    className="img-fluid"
                                  />
                                  <p>Live attendees</p>
                                </div>
                                <span>0</span>
                                <div className="traffic_info_1 ">
                                  <div>
                                    <img
                                      src={require("../assets/images/registration_imgs/traffic_img_1.png")}
                                      className="img-fluid"
                                    />
                                    <p>Register rate</p>
                                  </div>
                                  <span>0</span>
                                </div>
                              </div> */}
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className="dashbord-right-body mt-4">
                        <div className="dashbordrightbody-header">
                          <p>Engagement</p>
                        </div>
                        <div className="dashbordrightbody-body">
                          <div className="dashbordrightbody-list1 live_webinar">
                            <Row>
                              <Col lg={6}>
                                <div className="live_content mt-3">
                                  <h4>Live webinar</h4>
                                  <div className="chat-border">
                                    <LiveChart
                                      xAxisData={xAxisData}
                                      chartData={chartData}
                                    />
                                  </div>
                                  {/* {config} */}
                                  {/* <img
                          src={require("../assets/images/registration_imgs/engage_img.png")}
                          className="img-fluid"
                        /> */}
                                </div>
                              </Col>

                              <Col lg={6}>
                                <div className="webinar_2_info live_content mt-3">
                                  <h4>Details</h4>
                                  <div className="webinar_2_content">
                                    <div className="live_webinar_2">
                                      <img
                                        src={require("../assets/images/registration_imgs/attendees.png")}
                                        className="img-fluid"
                                      />
                                      <p>Live attendees</p>
                                    </div>
                                    <p>
                                      {
                                        initialState?.Users?.filter(
                                          (val) => val.isModerator != true
                                        ).length
                                      }
                                    </p>
                                  </div>
                                  <div className="webinar_2_content">
                                    <div className="live_webinar_2">
                                      <img
                                        src={require("../assets/images/registration_imgs/time.png")}
                                        className="img-fluid"
                                      />
                                      <p>Session length</p>
                                    </div>
                                    <p>
                                      {initialState?.addtionInfo?.session_length?.includes(
                                        "-"
                                      )
                                        ? "00:00:00"
                                        : initialState?.addtionInfo
                                            ?.session_length}
                                    </p>
                                  </div>
                                  <div className="webinar_2_content">
                                    <div className="live_webinar_2">
                                      <img
                                        src={require("../assets/images/registration_imgs/tv.png")}
                                        className="img-fluid"
                                      />
                                      <p>Avrg. time on live room</p>
                                    </div>
                                    <p>{analyticsData?.avgMeetingTime}</p>
                                  </div>
                                  <div className="webinar_2_content">
                                    <div className="live_webinar_2">
                                      <img
                                        src={require("../assets/images/registration_imgs/webinar.png")}
                                        className="img-fluid"
                                      />
                                      <p>Watched the full webinar</p>
                                    </div>
                                    <p>
                                      {analyticsData?.userWatchFullWebinarCount}
                                    </p>
                                  </div>
                                  <div className="webinar_2_content">
                                    <div className="live_webinar_2">
                                      <img
                                        src={require("../assets/images/registration_imgs/thumsup.png")}
                                        className="img-fluid"
                                      />
                                      <p>User rating (0)</p>
                                    </div>
                                    <p>
                                      {" "}
                                      <img
                                        src={require("../assets/images/registration_imgs/star_img.png")}
                                        className="img-fluid"
                                      />
                                    </p>
                                  </div>
                                </div>
                              </Col>
                              {/* <Col lg={6}>
                              <div className="live_content mt-3">
                                <h4>Replay webinar</h4>
                                <img
                                  src={require("../assets/images/registration_imgs/engage_img.png")}
                                  className="img-fluid"
                                />
                              </div>
                            </Col> */}
                            </Row>

                            <Row>
                              {/* <Col lg={6}>
                              <div className="webinar_2_info">
                                <div className="webinar_2_content">
                                  <div className="live_webinar_2">
                                    <img
                                      src={require("../assets/images/registration_imgs/attendees.png")}
                                      className="img-fluid"
                                    />
                                    <p>Live attendees</p>
                                  </div>
                                  <p>0</p>
                                </div>
                                <div className="webinar_2_content">
                                  <div className="live_webinar_2">
                                    <img
                                      src={require("../assets/images/registration_imgs/clock.png")}
                                      className="img-fluid"
                                    />
                                    <p>Session length</p>
                                  </div>
                                  <p>00:00:00</p>
                                </div>
                                <div className="webinar_2_content">
                                  <div className="live_webinar_2">
                                    <img
                                      src={require("../assets/images/registration_imgs/time.png")}
                                      className="img-fluid"
                                    />
                                    <p>Avrg. time on live room</p>
                                  </div>
                                  <p>00:00:00</p>
                                </div>
                              </div>
                            </Col> */}
                            </Row>
                          </div>
                        </div>
                      </div>
                    </>
                  ) : (
                    <></>
                  )}

                  {analyticsData != "" && (
                    <div className="dashbord-right-body dashbord-right-body_Analytics1  mb-5 mt-4">
                      <div className="dashbordrightbody-header">
                        <p>Learning Analytics Dashboard</p>
                        <div className="Duration">
                          <p>
                            <span>
                              {" "}
                              {analyticsData?.meetingInfo?.startTime}{" "}
                            </span>{" "}
                            Duration : {analyticsData?.meetingInfo?.duration}
                          </p>

                          <p></p>
                        </div>
                      </div>
                      <div className="dashbordrightbody-body">
                        {/* <p className="user_name">Username</p> */}
                        <div className="learning_analytics">
                          <Tab.Container
                            id="left-tabs-example"
                            defaultActiveKey="first"
                          >
                            <Nav variant="pills nav-fill">
                              <Nav.Item>
                                <Nav.Link eventKey="first">
                                  <div className="analytics_tab_head">
                                    <div className="analytics_tab_content_1">
                                      <img
                                        src={require("../assets/images/registration_imgs/attendees.png")}
                                        className="img-fluid"
                                      />
                                      <h6>Active Users</h6>
                                    </div>
                                    <div className="analytics_tab_content_2">
                                      <p>
                                        {
                                          analyticsData?.meetingInfo
                                            ?.activeUsersCount
                                        }
                                      </p>
                                    </div>
                                  </div>
                                </Nav.Link>
                              </Nav.Item>
                              <Nav.Item>
                                <Nav.Link eventKey="second">
                                  {" "}
                                  <div className="analytics_tab_head">
                                    <div className="analytics_tab_content_1">
                                      <img
                                        src={require("../assets/images/registration_imgs/activity.png")}
                                        className="img-fluid"
                                      />
                                      <h6>Activity Score</h6>
                                    </div>
                                    <div className="analytics_tab_content_2">
                                      <p>
                                        {
                                          analyticsData?.meetingInfo
                                            ?.totalActivityScore
                                        }
                                      </p>
                                    </div>
                                  </div>
                                </Nav.Link>
                              </Nav.Item>
                              <Nav.Item>
                                <Nav.Link eventKey="third">
                                  {" "}
                                  <div className="analytics_tab_head">
                                    <div className="analytics_tab_content_1">
                                      <img
                                        src={require("../assets/images/registration_imgs/clock.png")}
                                        className="img-fluid"
                                      />
                                      <h6>Time Line</h6>
                                    </div>
                                    <div className="analytics_tab_content_2">
                                      <p>{getTimeLineCount()}</p>
                                    </div>
                                  </div>
                                </Nav.Link>
                              </Nav.Item>
                              <Nav.Item>
                                <Nav.Link eventKey="forth">
                                  {" "}
                                  <div className="analytics_tab_head">
                                    <div className="analytics_tab_content_1">
                                      <img
                                        src={require("../assets/images/registration_imgs/polls.png")}
                                        className="img-fluid"
                                      />
                                      <h6>Polls</h6>
                                    </div>
                                    <div className="analytics_tab_content_2">
                                      <p>
                                        {analyticsData?.meetingInfo?.pollsCount}
                                      </p>
                                    </div>
                                  </div>
                                </Nav.Link>
                              </Nav.Item>
                            </Nav>
                            <div className="overview_text">
                              <h2>Overview</h2>
                            </div>
                            <Tab.Content className="  ">
                              <Tab.Pane eventKey="first">
                                <DataTable columns={columns} data={userList} />
                              </Tab.Pane>
                              <Tab.Pane eventKey="second">
                                <DataTable columns={columns1} data={userList} />
                              </Tab.Pane>
                              <Tab.Pane eventKey="third">
                                <div className="tab_3_content">
                                  {(getTimeLineCount() > 0) ? (
                                    <>
                                      <Scrollbars
                                        style={{ minHeight: 250, width: "100%" }}
                                        className="timelineScroll"
                                      >
                                        {analyticsData?.timelineInfo?.map(
                                          (item, index) => {
                                            return (
                                              <div className="timeline-item">
                                                <div className="users_tab1">
                                                  <div className="userFixed">
                                                    <div>
                                                      <h6>
                                                        {item?.userName
                                                          ?.substring(0, 8)
                                                          .concat("..")}
                                                      </h6>
                                                    </div>
                                                  </div>
                                                </div>

                                                <div
                                                  className="line1"
                                                  style={{
                                                    height: 12,
                                                    width: 500,
                                                    marginLeft: 130,
                                                  }}
                                                >
                                                  {item?.emojis?.map(
                                                    (obj, index) => (
                                                      <div
                                                        className="likes_event"
                                                        style={{
                                                          left: `${obj?.sentOn}`,
                                                        }}
                                                      >
                                                        <i
                                                          class={
                                                            emojis_config[
                                                            obj?.emojis
                                                            ]
                                                          }
                                                        ></i>
                                                      </div>
                                                    )
                                                  )}
                                                </div>
                                              </div>
                                            );
                                          }
                                        )}
                                      </Scrollbars>
                                    </>
                                  ) : (
                                    <div><center>No timeline found</center></div>
                                  )}
                                </div>
                              </Tab.Pane>
                              <Tab.Pane eventKey="forth">
                                <div className="">
                                  <div className="">
                                    <div className=" registration_alert analytics">
                                      <div className="pre_notification">
                                        {(analyticsData?.meetingInfo?.pollsCount > 0) ? (
                                          <Accordion
                                            defaultActiveKey=""
                                            className="accMain Polls_acc"
                                            flush
                                          >
                                            {analyticsData?.pollsData?.map(
                                              (pollsObj, index) => {

                                                let polllen = pollsObj?.polls?.length;
                                                if (polllen > 0) {
                                                  return (
                                                    <Accordion.Item
                                                      eventKey={index}
                                                    >
                                                      <Accordion.Header>
                                                        <p>{pollsObj?.userName}</p>
                                                        <div className="Total_count">
                                                          <h6>Total</h6>
                                                          <h6>
                                                            {
                                                              pollsObj?.polls
                                                                ?.length
                                                            }
                                                          </h6>
                                                        </div>
                                                      </Accordion.Header>
                                                      <Accordion.Body>
                                                        {pollsObj?.polls?.map(
                                                          (obj) => (
                                                            <div className="confirm_msg_content">
                                                              <div className="add_info_content">
                                                                <p>
                                                                  {obj?.question}
                                                                </p>
                                                              </div>
                                                              <div className="add_content_1">
                                                                <div className="add_sub_content">
                                                                  <h4>
                                                                    {obj?.answers}
                                                                  </h4>
                                                                </div>
                                                              </div>
                                                            </div>
                                                          )
                                                        )}
                                                      </Accordion.Body>
                                                    </Accordion.Item>
                                                  );
                                                }
                                                else {
                                                  return (
                                                    <Accordion.Item
                                                      eventKey={index}
                                                    >
                                                      <Accordion.Header>
                                                        <p>{pollsObj?.userName}</p>
                                                        <div className="Total_count">
                                                          <h6>Total</h6>
                                                          <h6>
                                                            {
                                                              pollsObj?.polls
                                                                ?.length
                                                            }
                                                          </h6>
                                                        </div>
                                                      </Accordion.Header>
                                                    </Accordion.Item>
                                                  )
                                                }
                                              }
                                            )}
                                          </Accordion>
                                        ) : (
                                          <div><center>No polls found</center></div>
                                        )}
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </Tab.Pane>
                            </Tab.Content>
                          </Tab.Container>
                        </div>
                      </div>
                    </div>
                  )}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <Innerfooter />
    </>
  );
};
export default WebinarAnalytics;
