import React from "react";
function Innerfooter() {
  return (
    <footer className="inner-footer-main">
      <div className="container">
        <div className="innerfooter">
      <p>
        Privacy Policy<span>|</span>Terms & Conditions
      </p>
      <p>Copy right © 2024, Vaffix All rights reserved</p>
      </div>
      </div>
    </footer>
  );
}
export default Innerfooter;
