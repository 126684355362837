import { data } from "jquery";
import { decryptString } from "../helper/helper";
import instance from "./axios";

const responceData = (type, action) => {
  if (type == "success") {
    return {
      status: true,
      result: action.data,
    };
  } else {
    return {
      status: false,
      result: action.response.data,
    };
  }
};

export const Register_User = async (data) => {
  try {
    console.log(data, "data");
    const respDate = await instance({
      url: `api/user/Register`,
      method: "post",
      data: data,
    });

    return responceData("success", respDate);
  } catch (error) {
    return responceData("error", error);
  }
};

export const Login_user = async (data) => {
  try {
    console.log(data, "data");
    const respDate = await instance({
      url: `api/user/login`,
      method: "post",
      data: data,
    });

    return responceData("success", respDate);
  } catch (error) {
    return responceData("error", error);
  }
};

export const Verify_Email = async (data) => {
  try {
    console.log(data, "data");
    const respDate = await instance({
      url: `api/user/Verify_Email`,
      method: "get",
      params: data,
    });
    return responceData("success", respDate);
  } catch (error) {
    return responceData("error", error);
  }
};

export const Resend_Email = async (data) => {
  try {
    console.log(data, "data");
    const respDate = await instance({
      url: `api/user/resendMail`,
      method: "get",
      params: { id: data },
    });
    return responceData("success", respDate);
  } catch (error) {
    return responceData("error", error);
  }
};

export const check_email = async (data) => {
  try {
    console.log(data, "data");
    const respDate = await instance({
      url: `api/user/forgetpassword`,
      method: "get",
      params: data,
    });
    return responceData("success", respDate);
  } catch (error) {
    return responceData("error", error);
  }
};

export const resetPassword = async (data) => {
  try {
    console.log(data, "data");
    const respDate = await instance({
      url: `api/user/resetPassword`,
      method: "post",
      data: data,
    });
    return responceData("success", respDate);
  } catch (error) {
    return responceData("error", error);
  }
};

export const user_resetPassword = async (data) => {
  try {
    console.log(data, 'data')
    const respDate = await instance({
      url: `api/user/userresetPassword`,
      method: 'post',
      data: data
    })
    return responceData("success", respDate)
  } catch (error) {
    return responceData("error", error)
  }
}

export const verify_recaptcha = async (data) => {
  try {
    console.log(data, "data");
    const respDate = await instance({
      url: `api/user/verify_recaptcha`,
      method: "get",
      params: { token: data },
    });
    return responceData("success", respDate);
  } catch (error) {
    return responceData("error", error);
  }
};

export const get_UserDetails = async () => {
  try {
    const respDate = await instance({
      url: `api/user/get_UserDetails`,
      method: "get",
    });
    console.log("respDate139", respDate)
    return responceData("success", respDate);
  } catch (error) {
    return responceData("error", error);
  }
};

export const Create_Webinar = async (data) => {
  try {
    console.log(data, "pppppp");
    const formdata = new FormData();
    for (let val of data.presenters) {
      if (val.image) {
        formdata.append("images", val.image[0]);
      }
    }

    formdata.append("Webinar_desc", data.Webinar_description);
    formdata.append("Webinar_lang", data.Webinar_language);
    formdata.append("Webinar_name", data.Webinar_name);
    formdata.append("Webinar_title", data.Webinar_title);
    formdata.append("configure", data.configure);
    formdata.append("type", data.type);
    formdata.append("presenterName", data.presenters[0].Presenters_name);
    formdata.append("presenterEmail", data.presenters[0].Presenters_email);
    formdata.append("profileImage", data.presenters[0].profile_image);
    formdata.append("fake_user", data.fake_user);
    formdata.append("google_form", data.google_form);

    // const parseData = JSON.stringify(data.presenters)

    // formdata.append('presenters', parseData)
    const respDate = await instance({
      url: `api/user/Create_Webinar`,
      method: "post",
      data: formdata,
    });
    return responceData("success", respDate);
  } catch (error) {
    console.log(error, "error");
    return responceData("error", error);
  }
};

export const Full_Create_Webinar = async (data) => {
  try {
    console.log(data, "pppppp");
    const formdata = new FormData();

    console.log(typeof data.profile_image, "profile_image");
    // for (let val of data.presenters) {
    if (typeof data.profile_image == "string") {
      formdata.append("profileImage", data.profile_image);
    } else {
      formdata.append("images", data.profile_image);
    }
    // }
    formdata.append("Webinar_desc", data.Webinar_description);
    formdata.append("Webinar_lang", data.Webinar_language);
    formdata.append("Webinar_title", data.Webinar_title);
    formdata.append("configure", data.configure);
    formdata.append("type", data.type);
    formdata.append("presenterName", data.Presenters_name);
    formdata.append("presenterEmail", data.Presenters_email);
    formdata.append("vaffixlive", data.vaffixlive);
    formdata.append("youtubelive", data.youtubelive);
    formdata.append("facebooklive", data.facebooklive);
    formdata.append("fake_user", data.fake_user);
    formdata.append("google_form", data.google_form);

    // const parseData = JSON.stringify(data.presenters)

    // formdata.append('presenters', parseData)
    const respDate = await instance({
      url: `api/user/Create_Webinar`,
      method: "post",
      data: formdata,
    });
    return responceData("success", respDate);
  } catch (error) {
    console.log(error, "error");
    return responceData("error", error);
  }
};

export const getWebinarDetails = async (data) => {
  try {
    console.log(data, "getWebinarDetailsiiiiiii");

    const respDate = await instance({
      url: `api/user/getWebinarDetails`,
      method: "get",
      params: { type: data },
    });

    return responceData("success", respDate);
  } catch (error) {
    console.log(error);
    return responceData("error", error);
  }
};

export const getSingleWebinarDetails = async (data) => {
  try {
    console.log("iiiiiii");

    const respDate = await instance({
      url: `api/user/getSingleWebinarDetails`,
      method: "get",
      params: data,
    });

    return responceData("success", respDate);
  } catch (error) {
    console.log(error);
    return responceData("error", error);
  }
};

export const add_webinar_schedule = async (data) => {
  try {
    const respDate = await instance({
      url: `api/user/add_webinar_schedule`,
      method: "post",
      data: data,
    });
    return responceData("success", respDate);
  } catch (error) {
    console.log(error);
    return responceData("error", error);
  }
};

export const update_Webinar = async (data) => {
  try {
    console.log(data, "update_Webinar");

    const formdata = new FormData();
    for (let val of data.presenters) {
      if (val.image) {
        formdata.append("images", val.image[0]);
      }
    }
    formdata.append("id", data._id);

    formdata.append("Webinar_desc", data.Webinar_description);
    formdata.append("Webinar_lang", data.Webinar_language);
    formdata.append("Webinar_name", data.Webinar_name);
    formdata.append("Webinar_title", data.Webinar_title);

    const parseData = JSON.stringify(data.presenters);

    formdata.append("presenters", parseData);

    const respDate = await instance({
      url: `api/user/update_Webinar`,
      method: "post",
      data: formdata,
    });
    return responceData("success", respDate);
  } catch (error) {
    console.log(error);
    return responceData("error", error);
  }
};

export const delete_Webinar = async (data) => {
  try {
    const respDate = await instance({
      url: `api/user/Delete_Webinar`,
      method: "post",
      params: data,
    });
    return responceData("success", respDate);
  } catch (error) {
    console.log(error);
    return responceData("error", error);
  }
};

export const Add_registractionForm = async (data) => {
  try {
    console.log(data, "update_Webinar");

    const respDate = await instance({
      url: `api/user/Add_registractionForm`,
      method: "post",
      data: data,
    });
    return responceData("success", respDate);
  } catch (error) {
    console.log(error);
    return responceData("error", error);
  }
};

export const getMeetingDetails = async () => {
  try {
    console.log("iiiiiii");
    const respDate = await instance({
      url: `api/user/getMeetingDetails`,
      method: "get",
    });

    return responceData("success", respDate);
  } catch (error) {
    console.log(error);
    return responceData("error", error);
  }
};

export const getSingleMeeting = async (data) => {
  try {
    console.log("iiiiiii");
    const respDate = await instance({
      url: `api/user/getSingleMeeting`,
      method: "get",
      params: data,
    });
    return responceData("success", respDate);
  } catch (error) {
    console.log(error);
    return responceData("error", error);
  }
};

export const Register_User_Webinar = async (data) => {
  try {
    console.log("iiiiiii");
    const respDate = await instance({
      url: `api/user/Register_User_Webinar`,
      method: "post",
      data: data,
    });
    return responceData("success", respDate);
  } catch (error) {
    console.log(error);
    return responceData("error", error);
  }
};

export const create_Meeting = async (data) => {
  try {
    console.log(data, "iiiiiii");
    const respDate = await instance({
      url: `api/user/create_Meeting`,
      method: "get",
      params: data,
    });
    return responceData("success", respDate);
  } catch (error) {
    console.log(error);
    return responceData("error", error);
  }
};

export const Completed_meeting_Details = async (data) => {
  try {
    console.log("iiiiiii");
    const respDate = await instance({
      url: `api/user/Completed_meeting_Details`,
      method: "get",
      params: data,
    });
    return responceData("success", respDate);
  } catch (error) {
    console.log(error);
    return responceData("error", error);
  }
};

export const getAll_Complted_meeting = async () => {
  try {
    console.log("iiiiiii");
    const respDate = await instance({
      url: `api/user/getAll_Complted_meeting`,
      method: "get",
    });
    return responceData("success", respDate);
  } catch (error) {
    console.log(error);
    return responceData("error", error);
  }
};

export const GetSingle_CompltedMeeting = async (data) => {
  try {
    console.log("iiiiiii");
    const respDate = await instance({
      url: `api/user/GetSingle_CompltedMeeting`,
      method: "get",
      params: data,
    });
    return responceData("success", respDate);
  } catch (error) {
    console.log(error);
    return responceData("error", error);
  }
};

export const getCompleted_Metting = async (data) => {
  try {
    console.log("iiiiiii");
    const respDate = await instance({
      url: `api/user/getCompleted_Metting`,
      method: "post",
      data: data,
    });
    return responceData("success", respDate);
  } catch (error) {
    console.log(error);
    return responceData("error", error);
  }
};

export const getSingle_Webinar = async (data) => {
  try {
    console.log("iiiiiii");
    const respDate = await instance({
      url: `api/user/getSingleWebinar`,
      method: "get",
      params: data,
    });
    return responceData("success", respDate);
  } catch (error) {
    console.log(error);
    return responceData("error", error);
  }
};

//search meetings
export const searchMeetings = async (data) => {
  try {
    const respDate = await instance({
      url: `api/user/searchWebinar`,
      method: "get",
      params: data,
    });
    return responceData("success", respDate);
  } catch (error) {
    console.log(error);
    return responceData("error", error);
  }
};

//search meetings
export const FilterMeetings = async (data) => {
  try {
    const respDate = await instance({
      url: `api/user/FilterMeetings`,
      method: "get",
      params: data,
    });
    return responceData("success", respDate);
  } catch (error) {
    console.log(error);
    return responceData("error", error);
  }
};

//Schedule meeting
//Register confirm message
export const getConfirmMessage = async (data) => {
  try {
    const respDate = await instance({
      url: `api/user/GetSingleEmail`,
      method: "get",
      params: data,
    });
    return responceData("success", respDate);
  } catch (error) {
    console.log(error);
    return responceData("error", error);
  }
};

//submit - email template
export const submitEmailTemplate = async (data) => {
  try {
    const respDate = await instance({
      url: `api/user/submit_EmailTemplate`,
      method: "post",
      data: data,
    });
    return responceData("success", respDate);
  } catch (error) {
    console.log(error);
    return responceData("error", error);
  }
};

//Extract token data for ( schedule meeting - multiple session )
export const extractToken = async (data) => {
  try {
    const respDate = await instance({
      url: `api/user/extract-token`,
      method: "post",
      data: data,
    });
    return responceData("success", respDate);
  } catch (error) {
    console.log(error);
    return responceData("error", error);
  }
};

//check for attendee password

export const AttendeePassword = async (data) => {
  try {
    const respDate = await instance({
      url: `api/user/AttendeePassword`,
      method: "post",
      data: data,
    });
    return responceData("success", respDate);
  } catch (error) {
    console.log(error);
    return responceData("error", error);
  }
};

export const getWebinarData = async (data) => {
  try {
    const respDate = await instance({
      url: `api/user/getWebinarData`,
      method: "get",
      params: data,
    });
    return responceData("success", respDate);
  } catch (error) {
    console.log(error);
    return responceData("error", error);
  }
};

export const editWebinerBasic = async (data) => {
  try {
    console.log(data, "data");

    console.log(data, "pppppp");
    const formdata = new FormData();

    formdata.append("Webinar_desc", data.Webinar_description);
    formdata.append("Webinar_lang", data.Webinar_language);
    formdata.append("Webinar_title", data.Webinar_title);
    formdata.append("presenterName", data.Presenters_name);
    formdata.append("presenterEmail", data.Presenters_email);
    formdata.append("profile_image", data.profile_image);
    formdata.append("id", data.id);

    if (data.vaffixlive) {
      formdata.append("vaffixlive", data.vaffixlive);
      formdata.append("youtubelive", data.youtubelive);
      formdata.append("facebooklive", data.facebooklive);
    }

    const respDate = await instance({
      url: `api/user/editWebinerBasic`,
      method: "post",
      data: formdata,
    });
    return responceData("success", respDate);
  } catch (err) {
    console.log(err);
    return responceData("error", err);
  }
};

export const editWebinarSchedule = async (data) => {
  try {
    const respDate = await instance({
      url: `api/user/editWebinarSchedule`,
      method: "post",
      data: data,
    });
    return responceData("success", respDate);
  } catch (error) {
    console.log(error);
    return responceData("error", error);
  }
};

export const sendLinkToTelegram = async (data) => {
  try {
    const respDate = await instance({
      url: `api/user/sendLinkToTelegram`,
      method: "post",
      data: data,
    });
    return responceData("success", respDate);
  } catch (error) {
    console.log(error);
    return responceData("error", error);
  }
};

export const getMailByID = async (data) => {
  try {
    const respDate = await instance({
      url: `api/user/getMailByID`,
      method: "get",
      params: data,
    });
    return responceData("success", respDate);
  } catch (error) {
    console.log(error);
    return responceData("error", error);
  }
};

export const editEmailTemplate = async (data) => {
  try {
    const respDate = await instance({
      url: `api/user/editEmailTemplate`,
      method: "post",
      data: data,
    });
    return responceData("success", respDate);
  } catch (error) {
    console.log(error);
    return responceData("error", error);
  }
};

export const editWebinarRegister = async (data) => {
  try {
    const respDate = await instance({
      url: `api/user/editWebinarRegister`,
      method: "post",
      data: data,
    });
    return responceData("success", respDate);
  } catch (error) {
    console.log(error);
    return responceData("error", error);
  }
};

export const editWebinarSettings = async (data) => {
  try {
    console.log(data, "data-----1-----111");
    const formdata = new FormData();
    formdata.append("message", data.message);
    formdata.append("urlLink", data.urlLink);
    formdata.append("chat", data.chat);
    formdata.append("listenOnlyMode", data.listenOnlyMode);
    formdata.append("recording", data.recording);
    formdata.append("document", data.document);
    formdata.append("id", data.id);

    const respDate = await instance({
      url: `api/user/editWebinarSettings`,
      method: "post",
      data: formdata,
    });
    return responceData("success", respDate);
  } catch (error) {
    console.log(error);
    return responceData("error", error);
  }
};

//Full config - Save registration template
export const saveRegistartionTemplate = async (data) => {
  try {
    const respDate = await instance({
      url: `api/user/saveRegTemplate`,
      method: "post",
      data: data,
    });
    return responceData("success", respDate);
  } catch (error) {
    console.log(error);
    return responceData("error", error);
  }
};


export const getRegistartionTemplate = async (data) => {
  try {
    const respDate = await instance({
      url: `api/user/getRegTemplate`,
      method: "post",
      data: data,
    });
    return responceData("success", respDate);
  } catch (error) {
    console.log(error);
    return responceData("error", error);
  }
};

export const getEnrollTemplate = async (data) => {
  try {
    const respDate = await instance({
      url: `api/user/getEnrollTemplate`,
      method: "get",
      params: data,
    });
    return responceData("success", respDate);
  } catch (error) {
    console.log(error);
    return responceData("error", error);
  }
};

export const submitInstancePassword = async (data) => {
  try {
    const respDate = await instance({
      url: `api/user/submitInstancePassword`,
      method: "post",
      data: data,
    });
    return responceData("success", respDate);
  } catch (error) {
    console.log(error);
    return responceData("error", error);
  }
};

export const checkForEmail_verification = async (data) => {
  try {
    const respDate = await instance({
      url: `api/user/checkForEmail_verification`,
      method: "get",
      params: data,
    });
    return responceData("success", respDate);
  } catch (error) {
    console.log(error);
    return responceData("error", error);
  }
};

export const getDashboard_data = async () => {
  try {
    const respDate = await instance({
      url: `api/user/getDashboard_data`,
      method: "get",
    });
    return responceData("success", respDate);
  } catch (error) {
    console.log(error);
    return responceData("error", error);
  }
};

export const getAllWebinar = async () => {
  try {
    const respDate = await instance({
      url: `api/user/getAllWebinar`,
      method: "get",
    });
    return responceData("success", respDate);
  } catch (error) {
    console.log(error);
    return responceData("error", error);
  }
};

export const getAllUser_InWebinar = async (data) => {
  try {
    const respDate = await instance({
      url: `api/user/getAllUser_InWebinar`,
      method: "get",
      params: data,
    });
    return responceData("success", respDate);
  } catch (error) {
    console.log(error);
    return responceData("error", error);
  }
};

export const updateYoutube_key = async (data) => {
  try {
    const respDate = await instance({
      url: `api/user/updateYoutube_key`,
      method: "get",
      params: data,
    });
    return responceData("success", respDate);
  } catch (error) {
    console.log(error);
    return responceData("error", error);
  }
};

export const updateUserDeatails = async (data) => {
  try {
    const respDate = await instance({
      url: `api/user/updateUserDeatails`,
      method: "post",
      data: data,
    });
    return responceData("success", respDate);
  } catch (error) {
    console.log(error);
    return responceData("error", error);
  }
};

export const verifyOtp = async (data) => {
  try {
    const respDate = await instance({
      url: `api/user/verifyOtp`,
      method: "post",
      data: data,
    });
    return responceData("success", respDate);
  } catch (error) {
    console.log(error);
    return responceData("error", error);
  }
};

export const sendOtpEmail = async (data) => {
  try {
    const respDate = await instance({
      url: `api/user/resendOtp`, // Assuming the endpoint for resending OTP is 'resendOtp'
      method: "post",
      data: data, // Pass user data required for resending OTP, such as email or user ID
    });
    return responceData("success", respDate);
  } catch (error) {
    console.log(error);
    return responceData("error", error);
  }
};

export const CreateSupportTicket = async (data) => {
  try {
    console.log(data, "datadatadatadatadatadatadata")
    const formdate = new FormData();
    if (data.image) {
      formdate.append("images", data.image);
    }
    formdate.append("subject", data.subject);
    formdate.append("category", data.category.value);
    formdate.append("discription", data.message);

    const respDate = await instance({
      url: `api/user/CreateSupportTicket`,
      method: "post",
      data: formdate,
    });
    return responceData("success", respDate);
  } catch (error) {
    console.log(error);
    return responceData("error", error);
  }
};

export const FetchAllCategory = async () => {
  try {
    const respDate = await instance({
      url: `api/user/getSupportCatagory`,
      method: "get",
    });
    return responceData("success", respDate);
  } catch (error) {
    console.log(error);
    return responceData("error", error);
  }
};

export const getAllTicketDetails = async () => {
  try {
    const respDate = await instance({
      url: `api/user/getAllTicketDetails`,
      method: "get",
    });
    return responceData("success", respDate);
  } catch (error) {
    console.log(error);
    return responceData("error", error);
  }
};



export const getSingleTicketDetails = async (data) => {
  try {
    const respDate = await instance({
      url: `api/user/getSingleTicketDetails`,
      method: "get",
      params: data,
    });
    return responceData("success", respDate);
  } catch (error) {
    console.log(error);
    return responceData("error", error);
  }
};

export const ReplyTicket = async (data) => {
  try {
    console.log("data916", data)
    const formdate = new FormData();
    if (data.image) {
      formdate.append("images", data.image);
    }
    formdate.append("ticketid", data.ticketid);
    formdate.append("message", data.replaymessage);

    const respDate = await instance({
      url: `api/user/ReplyTicket`,
      method: "post",
      data: formdate,
    });
    console.log("resdata929", respDate)

    return responceData("success", respDate);
  } catch (error) {
    console.log(error);
    return responceData("error", error);
  }
};

export const getSingle_Webinardetails = async (data) => {
  try {
    const respDate = await instance({
      url: `api/user/getSingle_Webinardetails`,
      method: "get",
      params: data,
    });

    return responceData("success", respDate);
  } catch (error) {
    console.log(error);
    return responceData("error", error);
  }
};

export const getWebinardetails_MeetingId = async (data) => {
  try {
    const respDate = await instance({
      url: `api/user/getWebinardetails_MeetingId`,
      method: "get",
      params: data,
    });

    return responceData("success", respDate);
  } catch (error) {
    console.log(error);
    return responceData("error", error);
  }
};

export const addMeetingToGoogleCalendar = async (data) => {
  try {
    const respDate = await instance({
      url: `api/user/meetingAddToCalendar`,
      method: "post",
      data: data,
    });

    return responceData("success", respDate);
  } catch (error) {
    console.log(error);
    return responceData("error", error);
  }
};


export const CloseTicket = async (data) => {
  try {
    let respData = await instance({
      method: 'post',
      url: `api/user/closeByUser`,
      data,
    })
    return responceData('success', respData)
  } catch (err) {
    return responceData('error', err)
  }
}


export const createPayment = async (data) => {
  try {
    let respData = await instance({
      method: 'post',
      url: `api/user/create-payment-intent`,
      data,
    })
    return responceData('success', respData)
  } catch (err) {
    return responceData('error', err)
  }
}

export const getPakageLists = async () => {
  try {
    let respData = await instance({
      method: 'get',
      url: `api/user/getpackage`,

    })
    console.log("pakage", respData)
    return responceData('success', respData)
  } catch (err) {
    return responceData('error', err)
  }
}

export const updatePakageLists = async (data) => {
  try {
    let respData = await instance({
      method: 'put',
      url: `api/user/updatepackage`,
      data: data
    })
    console.log("pakage", respData)
    return responceData('success', respData)
  } catch (err) {
    return responceData('error', err)
  }
}

export const getSubcriptionLists = async () => {
  try {
    let respData = await instance({
      method: 'get',
      url: `api/user/getsubcription`,

    })
    console.log("subcripton", respData)
    return responceData('success', respData)
  } catch (err) {
    return responceData('error', err)
  }
}
//Fetch Single Email Template or Particular Template
export const getSingleTemplate = async (id) => {
  try {
    let respData = await instance({
      'method': 'post',
      'url': `api/user/getParEmailTemplate`,
      data: { id }
    });
    return respData.data;
  } catch (error) {
    return {
      status: "failed"
    }
  }
};

//Send Mail
export const sendMail = async (data) => {
  try {
    let respData = await instance({
      'method': 'post',
      'url': `api/user/bulkmail`,
      data
    });
    return {
      result: respData.data,
      status: "success"
    }
  } catch (error) {
    return {
      status: "failed"
    }
  }

}

//Plan Details fetch by Plandate
export const getPlanDate = async () => {
  try {
    let respData = await instance({
      'method': 'get',
      'url': `api/user/plansubscription`,
      data
    });
    return {
      result: respData.data,
      status: "success"
    }
  } catch (error) {
    return {
      status: "failed"
    }
  }
}

export const fakeUser = async (data) => {
  try {
    const respDate = await instance({
      url: `api/user/faketrigger`,
      method: "post",
      params: data,
    });
    return responceData("success", respDate);
  } catch (error) {
    console.log(error);
    return responceData("error", error);
  }
}

//Redux to Store a Plan Option
export const get_PlanDetails = async () => {
  try {
    const respDate = await instance({
      url: `api/user/get_PlanDetails`,
      method: "get",
    });
    console.log("respDate400912", respDate)
    return responceData("success", respDate);
  } catch (error) {
    return responceData("error", error);
  }
};