import moment from "moment";
import React, { useEffect, useState } from "react";
import ApexCharts from "react-apexcharts";
import DatePicker from "react-datepicker";
import { useSelector } from "react-redux";
import CircularProgress from "@mui/material/CircularProgress";

export default function WebinarAttendanceChart() {
  const Meeting_Details = useSelector((state) => state.Webinar);

  // Initial date states with a 50-day range
  const [startDate, setStartDate] = useState(
    moment().subtract(50, "days").toDate()
  );
  const [endDate, setEndDate] = useState(new Date());
  const [seriesData, setSeriesData] = useState([]);
  const [loader, setLoader] = useState(false);

  // Function to handle date clearing or selecting
  const handleStartDateChange = (date) => {
    setStartDate(date ? date : moment().subtract(50, "days").toDate());
  };

  const handleEndDateChange = (date) => {
    setEndDate(date ? date : new Date());
  };

  useEffect(() => {
    if (Meeting_Details.length > 0) {
      const timestampCounts = {};
      const filteredMeetings = Meeting_Details.filter((meeting) => {
        const meetingDate = new Date(meeting.createdAt);

        // Check if startDate or endDate is not set
        if (!startDate || !endDate) {
          return true; // Include all meetings if either date is not set
        }

        return meetingDate >= startDate && meetingDate <= endDate;
      });

      filteredMeetings
        .sort((a, b) => new Date(b.createdAt) - new Date(a.createdAt))
        .forEach((meeting) => {
          const meetingTimeUTC = new Date(meeting.createdAt).getTime();
          const meetingDate = moment(meetingTimeUTC)
            .local()
            .format("YYYY-MM-DD");
          const userCount = meeting?.AttendeeUser?.[0]?.TotalUserCount || 0;

          if (!timestampCounts[meetingDate]) {
            timestampCounts[meetingDate] = {
              x: meetingDate,
              y: 0,
              totalUserCount: 0,
              webinars: [],
            };
          }
          timestampCounts[meetingDate].y += 1;
          timestampCounts[meetingDate].totalUserCount += userCount;
          timestampCounts[meetingDate].webinars.push({
            title: meeting?.title || "Untitled Webinar",
            userCount: userCount,
          });
        });

      const formattedData = Object.values(timestampCounts).sort(
        (a, b) => new Date(a.x) - new Date(b.x)
      );

      setSeriesData(
        formattedData.map((item) => ({
          x: new Date(item.x).getTime(),
          y: item.y,
          totalUserCount: item.totalUserCount,
          webinars: item.webinars,
        }))
      );
    }
  }, [Meeting_Details, startDate, endDate]);

  const options = {
    chart: {
      id: "webinar-attendance-chart",
      type: "bar",
    },
    stroke: {
      curve: "smooth",
    },
    dataLabels: {
      enabled: false,
    },
    xaxis: {
      type: "datetime",
      title: {
        text: "Date",
      },
      labels: {
        datetimeUTC: false,
        format: "dd MMM yyyy",
      },
    },
    yaxis: {
      title: {
        text: "Total Webinars",
      },
    },
    tooltip: {
      shared: false,
      x: {
        format: "dd MMM yyyy",
      },
      y: {
        formatter: function (val, { dataPointIndex }) {
          const meeting = seriesData[dataPointIndex];
          const webinarDetails = meeting?.webinars
            .map(
              (webinar) =>
                `<br/><small><b>Webinar:</b> ${webinar.title}<br/><b>Users-Count:</b> ${webinar.userCount}</small></br>`
            )
            .join("");

          //return `Total Webinars: ${val}<br/>Total Users: ${meeting?.totalUserCount || 0} ${webinarDetails}`;
          return `Total Webinars: ${val}<br/>Total Users: ${
            meeting?.totalUserCount || 0
          }`;
        },
      },
    },
    plotOptions: {
      bar: {
        horizontal: false,
        columnWidth: "50%",
        endingShape: "rounded",
      },
    },
    fill: {
      type: "gradient",
      gradient: {
        shadeIntensity: 1,
        inverseColors: false,
        opacityFrom: 0.5,
        opacityTo: 0.8,
        stops: [0, 100],
        colorStops: [
          {
            offset: 0,
            color: "rgb(22,119,126)",
            opacity: 1,
          },
          {
            offset: 100,
            color: "rgb(22,119,126)",
            opacity: 1,
          },
        ],
      },
    },
  };

  const series = [
    {
      name: "",
      data: seriesData.map(({ x, y }) => ({ x, y })),
    },
  ];

  return (
    <div className="card">
      {loader ? (
        <div style={{ textAlign: "center", padding: "200px" }}>
          <CircularProgress sx={{ color: "#394346" }} />
        </div>
      ) : (
        <div className="card-body">
          <div className="dashbord-right-header">
            <p>Total Webinar Attendance Per Date</p>
          </div>
          <div className="chartFilter">
            <div className="datapick">
              <DatePicker
                className="datapick_head"
                selected={startDate}
                showIcon
                isClearable
                onChange={(date) => setStartDate(date)}
                dateFormat="yyyy/MM/dd"
                placeholderText="Select a start date"
              />
            </div>
            <div className="datapick">
              <DatePicker
                className="datapick_head"
                selected={endDate}
                showIcon
                isClearable
                onChange={(date) => setEndDate(date)}
                dateFormat="yyyy/MM/dd"
                placeholderText="Select an end date"
              />
            </div>
          </div>
          {seriesData.length > 0 ? (
            <ApexCharts
              options={options}
              series={series}
              type="bar"
              height={350}
            />
          ) : (
            <p>No data available for the selected date range.</p>
          )}
        </div>
      )}
    </div>
  );
}
