import React, { useRef, useState, useEffect } from "react";
import { Link, useNavigate } from "react-router-dom";
import Modal from "react-bootstrap/Modal";
import { Form, Button, InputGroup, Col, Row, Image } from "react-bootstrap";
import { OverlayTrigger, Tooltip } from "react-bootstrap";
import Navinner from "../components/nav-inner";
import Tab from "react-bootstrap/Tab";
import Tabs from "react-bootstrap/Tabs";
import nextImg from "../assets/images/side-icon.svg";
import nextImg1 from "../assets/images/side-icon2.svg";

import Innerpagenavbar from "../components/innerpage-navbar";
import Settingpage from "../components/dashboard/settingpage";
import Webinarpage from "./express/schudule/webinar";
import {
  CreateSupportTicket,
  Create_Webinar,
  FetchAllCategory,
  ReplyTicket,
  create_Meeting,
  delete_Webinar,
  getAllTicketDetails,
  getSingleTicketDetails,
  searchMeetings,
} from "../action/user.action";
import { useDispatch, useSelector } from "react-redux";
import { encryptString } from "../helper/helper";
import {
  Search_Details,
  setWebinar,
  Webinar_Details,
} from "../redux/features/webinar_details";

import moment, { now } from "moment";

import config from "../lib/config";

import Innerfooter from "../components/inner-footer";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

import {
  faShareFromSquare,
  faMagnifyingGlass,
  faListUl,
  faTh,
} from "@fortawesome/free-solid-svg-icons";
import Emptymeeting from "../assets/images/Emptymeeting.png";

//COMPONENTS
import ConfirmationModal from "../components/common/modals/ConfirmationModal";
import { toastAlert } from "../utils/toast";
import PropagateLoader from "react-spinners/PropagateLoader";

import { formatInTimeZone } from "date-fns-tz";

// import { useSelector } from "react-redux";

const initialForm = {
  subject: "",
  catagory: "",
  message: "",
  image: "",
  imagename: "",
};

const replayForm = {
  replaymessage: "",
  replayimage: "",
};

const SupportTicket = () => {
  const [visibleDiv, setVisibleDiv] = useState("CreateTicket");

  const [Initailvalue, setInitialValue] = useState(initialForm);
  const { subject, catagory, message, image, imagename } = Initailvalue;
  const [categorylist, setCategoryList] = useState([]);
  const [userTicketList, setUserTicketList] = useState([]);
  const [SingleTicketDetails, setSingleTicketDetails] = useState({});
  const [replayInitialForm, setreplayInitialForm] = useState(replayForm);

  const userDetails = useSelector((state) => state.User);

  const { replaymessage, replayimage } = replayInitialForm;

  const showCreateDiv = () => {
    setVisibleDiv("CreateTicket");
  };

  const showDetailsDiv = () => {
    setVisibleDiv("TicketDetails");
  };

  const getCategory = async () => {
    try {
      const { result, status } = await FetchAllCategory();
      if (status) {
        setCategoryList(result.result);
      } else {
        console.log(result.message);
      }
    } catch (error) {
      console.log(error);
    }
  };

  const getUserTicket = async () => {
    try {
      const { result, status } = await getAllTicketDetails();
      if (status) {
        console.log("support111",result.result)
        setUserTicketList(result.result);
      } else {
        console.log(result.message);
      }
      
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    getCategory();
    getUserTicket();
  }, []);
  const changHandler = async (e) => {
    const { id, value } = e.target;
    setInitialValue({ ...Initailvalue, [id]: value });
  };

  const submitHandler = async () => {
    try {
      const { result, status } = await CreateSupportTicket(Initailvalue);

      console.log(result, status, "result,status");
      if (status) {
        toastAlert("success", result.message);
      }
    } catch (error) {
      console.log(error);
    }
  };

  const handleFileChange = async (event) => {
    const { id } = event.target;

    console.log("val_______________1111");
    if (id == "images") {
      const file = event.target.files[0];
      const fileType = file.type;
      const fileSize = file.size;

      console.log("filesfiles", file);

      if (
        (fileType === "image/png" ||
          fileType === "image/jpeg" ||
          fileType === "image/jpg" ||
          fileType === "image/webp") &&
        fileSize <= 1024 * 1024 // 1MB in bytes
      ) {
        const { files } = event.target;

        console.log("filesfiles", file);

        if (visibleDiv == "CreateTicket") {
          setInitialValue({
            ...Initailvalue,
            image: file,
            imagename: file.names,
          });
        } else {
          setreplayInitialForm({
            ...replayInitialForm,
            image: file,
          });
        }
      } else {
        toastAlert("error", "The file must be less than 1 MB.");
        return false;
      }
    } else {
    }
  };

  const selectSingleTicket = async (id) => {
    try {
      const { result, status } = await getSingleTicketDetails({ ticketid: id });

      console.log(result, status, "result,status");
      if (status) {
        setSingleTicketDetails(result.result);
      }
    } catch (error) {
      console.log(error);
    }
  };

  const SubmitReplay = async () => {
    try {
      replayInitialForm.ticketid = SingleTicketDetails._id;
      const { result, status } = await ReplyTicket(replayInitialForm);

      console.log(result, status, "result,status");
    } catch (error) {
      console.log(error);
    }
  };

  return (
    <>
      <div className="dashboard-page">
        <div className="dashboard support_inner_nav">
          <Innerpagenavbar />

          <div className="dashbord-right mt-5 mb-5">
            <div className="dashbord-right-body-1 dashboard-padding ">
              <div className="inner_home_shadow">
                <div className="dashbord-right-header inner_home_dashboard">
                  <p>Support Ticket</p>
                </div>
                <div className="inner_home_body inner_support_ticket">
                  <Row>
                    <Col lg={6}>
                      <div className="inner_tab_content">
                        <div className="border_tab">
                          <Button
                            onClick={showCreateDiv}
                            className={
                              visibleDiv === "CreateTicket" ? "active" : ""
                            }
                          >
                            Create Tickets
                          </Button>
                          <Button
                            onClick={showDetailsDiv}
                            className={
                              visibleDiv === "TicketDetails" ? "active" : ""
                            }
                          >
                            Ticket Details
                          </Button>
                        </div>
                        {visibleDiv === "CreateTicket" && (
                          <>
                            <Form.Group
                              className="mb-3 mt-3"
                              controlId="exampleForm.ControlInput1"
                            >
                              {" "}
                              <Form.Label>Subject</Form.Label>{" "}
                              <div
                                names={"international"}
                                searchable={true}
                                searchPlaceholder="Search for a language"
                                aria-label="Default select example"
                                id="Webinar_language"
                                // defaultLanguage={webinarDetails?.Webinar_language}
                              />
                              <Form.Control
                                type="text"
                                id="subject"
                                aria-label="With textarea"
                                placeholder="Subject"
                                value={subject}
                                onChange={changHandler}
                              />
                            </Form.Group>
                            <Form.Group
                              className="mb-3"
                              controlId="exampleForm.ControlInput1"
                            >
                              {" "}
                              <Form.Label>Category</Form.Label>{" "}
                              <div
                                names={"international"}
                                searchable={true}
                                searchPlaceholder="Search for a language"
                                aria-label="Default select example"

                                // defaultLanguage={webinarDetails?.Webinar_language}
                              />
                              <Form.Select
                                aria-label="Default select example"
                                id="category"
                                value={catagory}
                                onChange={changHandler}
                              >
                                <option>Select Catagory</option>
                                {categorylist.length > 0 &&
                                  categorylist.map((val) => (
                                    <option value={val._id}>
                                      {val.category}
                                    </option>
                                  ))}

                                {/* <option value="2">Two</option>
                                <option value="3">Three</option> */}
                              </Form.Select>
                            </Form.Group>
                            <Form.Group
                              className="mb-3"
                              controlId="exampleForm.ControlInput1"
                            >
                              <Form.Label>Description</Form.Label>
                              <Form.Control
                                as="textarea"
                                id="message"
                                aria-label="With textarea"
                                rows={5}
                                placeholder="Describe your issue in detail"
                                value={message}
                                onChange={changHandler}
                              />
                            </Form.Group>
                            <Form.Label>Attach Files</Form.Label>
                            <div className="file-drop-area ">
                              <span className="choose-file-button">
                                {" "}
                                <img
                                  src={require("../assets/images/file_imge.png")}
                                  alt="img"
                                  className="img-fluid"
                                />
                              </span>
                              <span className="file-message">Upload image</span>
                              <input
                                className="file-input"
                                accept=".jpg.jpeg.png"
                                type="file"
                                id="images"
                                onChange={handleFileChange}
                              />
                            </div>
                            <p>*Upload button for JPG,JPEG,PNG etc.</p>

                            <button
                              to="#"
                              className="primary_btn"
                              onClick={submitHandler}
                            >
                              <img
                                src={require("../assets/images/chevron-right.png")}
                                height="16"
                                width="16"
                                className="img-fluid me-2"
                              />{" "}
                              Submit Ticket
                            </button>
                          </>
                        )}
                        {visibleDiv === "TicketDetails" && (
                          <div className="ticket_panel mt-3">
                            {userTicketList.length > 0 ? (
                              <>
                                {userTicketList.map((val, i) => (
                                  <div
                                    className="ticket_panel_div active"
                                    onClick={() => selectSingleTicket(val._id)}
                                  >
                                    <h4>Ticket 0{i + 1}</h4>
                                    <div className="ticket_panel_details">
                                      <label>
                                        Sub: <span>{val.subject}</span>
                                      </label>
                                      <div className="ticket_panel_bottom">
                                        <label>
                                          [ Ticket ID: <span>#0001</span>]
                                        </label>
                                        <label>
                                          [ Status:{" "}
                                          <span>
                                            {val.status == 0
                                              ? "Open"
                                              : "Closed"}
                                          </span>
                                          ]
                                        </label>
                                      </div>
                                    </div>
                                  </div>
                                ))}
                              </>
                            ) : (
                              <div className="support_empty">
                                <img
                                  src={require("../assets/images/inner_support_img.png")}
                                  className="img-fluid pe-2"
                                />
                                <p>No Ticket Found</p>
                              </div>
                            )}

                            {/* <div className="ticket_panel_div">
                              <h4>Ticket 02</h4>
                              <div className="ticket_panel_details">
                                <label>
                                  Sub: <span>Login Issue</span>
                                </label>
                                <div className="ticket_panel_bottom">
                                  <label>
                                    [ Ticket ID: <span>#0002</span>]
                                  </label>
                                  <label>
                                    [ Status: <span>Closed</span>]
                                  </label>
                                </div>
                              </div>
                            </div>

                            <div className="ticket_panel_div">
                              <h4>Ticket 03</h4>
                              <div className="ticket_panel_details">
                                <label>
                                  Sub: <span>Login Issue</span>
                                </label>
                                <div className="ticket_panel_bottom">
                                  <label>
                                    [ Ticket ID: <span>#0003</span>]
                                  </label>
                                  <label>
                                    [ Status: <span>Open</span>]
                                  </label>
                                </div>
                              </div>
                            </div> */}
                          </div>
                        )}
                      </div>
                    </Col>
                    <Col lg={6}>
                      <div className="inner_tab_content ">
                        {visibleDiv === "CreateTicket" && (
                          <div className="support_empty">
                            <img
                              src={require("../assets/images/inner_support_img.png")}
                              className="img-fluid pe-2"
                            />
                            <p>Your Support Tickets List is Empty.</p>
                          </div>
                        )}
                        {visibleDiv === "TicketDetails" && (
                          <div className="ticket_details">
                            <h4>Ticket Details</h4>
                            <div className="ticket_details_flex">
                              <label>
                                Sub: <span>{SingleTicketDetails.subject}</span>
                              </label>
                              <div className="ticket_panel_bottom">
                                <label>
                                  [ Ticket ID: <span>#0003</span>]
                                </label>
                                <label>
                                  [ Status:{" "}
                                  <span>
                                    {SingleTicketDetails.status == 0
                                      ? "Open"
                                      : "Closed"}
                                  </span>
                                  ]
                                </label>
                              </div>
                            </div>

                            <div className="msg_box_wrapper">
                              {SingleTicketDetails.message?.length > 0 ? (
                                SingleTicketDetails.message.map((val) => (
                                  <div
                                    className={`msg_box_panel ${
                                      userDetails._id != val.from_id
                                        ? "admin_msg_box_panel"
                                        : ""
                                    }`}
                                  >
                                    <div className="msg_box_div">
                                      <div className="msg_box_content">
                                        <p>{val.message}</p>
                                      </div>
                                      <img
                                        src={require("../assets/images/user1.png")}
                                        className="img-fluid ps-2"
                                        alt="Icon"
                                      />
                                    </div>
                                    <h6>
                                      {moment(val.createdAt).format("LLL")}
                                    </h6>
                                  </div>
                                ))
                              ) : (
                                <> Empty</>
                              )}

                              <div className="msg_box_panel admin_msg_box_panel">
                                <div className="msg_box_div">
                                  <img
                                    src={require("../assets/images/chat_user.png")}
                                    className="img-fluid ps-2"
                                    alt="Icon"
                                    width="42"
                                    height="42"
                                  />
                                  <div className="msg_box_content">
                                    <p>
                                      Lorem Ipsum has been the industry's
                                      standard dummy text ever since the 1500s,
                                      when an unknown printer took a galley of
                                      type and scrambled it to make a type
                                      specimen book. It has survived not only
                                      five centuries, but also the leap into
                                      electronic typesetting, remaining
                                      essentially unchanged.
                                    </p>
                                  </div>
                                </div>
                                <h6>1-07-2024 15:15 PM</h6>
                              </div>

                              <div className="msg_box_panel admin_msg_box_panel">
                                <div className="msg_box_div">
                                  <img
                                    src={require("../assets/images/chat_user.png")}
                                    className="img-fluid ps-2"
                                    alt="Icon"
                                  />
                                  <div className="msg_box_content">
                                    <p>
                                      Lorem Ipsum has been the industry's
                                      standard dummy text ever since the 1500s,
                                      when an unknown printer took a galley of
                                      type and scrambled it to make a type
                                      specimen book. It has survived not only
                                      five centuries, but also the leap into
                                      electronic typesetting, remaining
                                      essentially unchanged.
                                    </p>
                                  </div>
                                </div>
                                <h6>1-07-2024 15:15 PM</h6>
                              </div>

                              <div className="msg_box_panel">
                                <div className="msg_box_div">
                                  <div className="msg_box_content">
                                    <p>
                                      Lorem Ipsum has been the industry's
                                      standard dummy text ever since the 1500s,
                                      when an unknown printer took a galley of
                                      type and scrambled it to make a type
                                      specimen book. It has survived not only
                                      five centuries, but also the leap into
                                      electronic typesetting, remaining
                                      essentially unchanged.
                                    </p>
                                  </div>
                                  <img
                                    src={require("../assets/images/user1.png")}
                                    className="img-fluid ps-2"
                                    alt="Icon"
                                    width="42"
                                    height="42"
                                  />
                                </div>
                                <h6>1-07-2024 15:15 PM</h6>
                              </div>
                            </div>

                            {/* <div className="support_txtbox">
                              <Form.Control
                                placeholder="Write a message"
                                type="text"
                                onChange={(e) =>
                                  setreplayInitialForm({
                                    ...replayInitialForm,
                                    message: e.target.value,
                                  })
                                }
                              ></Form.Control>
                              <div className="support_txtbox_btngrp">
                                <div className="support_upload_btn">
                                  <Form.Control
                                    type="file"
                                    onChange={handleFileChange}
                                  ></Form.Control>
                                  <Button className="support_upload_btn">
                                    <img
                                      src={require("../assets/images/file_imge.png")}
                                      className="img-fluid"
                                      //   width={42}
                                      //   height={42}
                                    />
                                  </Button>
                                </div>
                                <button
                                  className="send_btn"
                                  onClick={SubmitReplay}
                                >
                                  Send
                                </button>
                              </div>
                            </div> */}
                            <InputGroup className="support_txtbox">
                              <Form.Control
                                placeholder="Recipient's username"
                                aria-label="Recipient's username"
                                aria-describedby="basic-addon2"
                              />
                              <Button id="basic-addon2">
                                <div className="support_upload_btn">
                                  <img
                                    src={require("../assets/images/file_imge.png")}
                                    className="img-fluid"
                                    // width={42}
                                    // height={42}
                                  />
                                </div>
                              </Button>
                              <InputGroup.Text id="basic-addon2">
                                <button
                                  className="send_btn"
                                  onClick={SubmitReplay}
                                >
                                  Send
                                </button>
                              </InputGroup.Text>
                            </InputGroup>
                            <div className="uploaded_file">
                              <span>FrontImage.png</span>
                              {/* <img
                            src={require("../assets/images/close.png")}
                            alt="Icon"
                          />  */}
                            </div>
                            <div className="text-end mt-3">
                              <Button className="primary_btn">
                                Satisfied, Close Ticket
                              </Button>
                            </div>
                          </div>
                        )}
                      </div>
                    </Col>
                  </Row>
                </div>
              </div>
            </div>
            <Innerfooter />
            <div></div>
          </div>
        </div>
      </div>
    </>
  );
};
export default SupportTicket;
