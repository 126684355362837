import moment from "moment";
import React, { useEffect, useState } from "react";
import ApexCharts from "react-apexcharts";
import { useSelector } from "react-redux";

export default function WebinarAttendanceChart() {
  const Meeting_Details = useSelector((state) => state.Webinar);
  const [seriesData, setSeriesData] = useState([]);

  useEffect(() => {
    if (Meeting_Details && Meeting_Details.length > 0) {
      const timestampCounts = {};

      [...Meeting_Details]
        .sort(
          (a, b) =>
            new Date(b.createdAt).getTime() - new Date(a.createdAt).getTime()
        )
        .forEach((meeting) => {
          const meetingTimeUTC = new Date(meeting.createdAt).getTime();
          const meetingDate = moment(meetingTimeUTC)
            .local()
            .format("YYYY-MM-DD");

          const userCount = meeting?.AttendeeUser?.[0]?.TotalUserCount || 0;

          if (!timestampCounts[meetingDate]) {
            timestampCounts[meetingDate] = {
              x: meetingDate,
              y: 0,
              totalUserCount: 0,
              webinars: [],
            };
          }
          timestampCounts[meetingDate].y += 1;
          timestampCounts[meetingDate].totalUserCount += userCount;
          timestampCounts[meetingDate].webinars.push({
            title: meeting?.title || "Untitled Webinar",
            userCount: userCount,
          });
        });

      const formattedData = Object.values(timestampCounts).sort(
        (a, b) => new Date(a.x) - new Date(b.x)
      );

      const cumulativeData = formattedData.map((item) => ({
        x: new Date(item.x).getTime(), // Convert to timestamp
        y: item.y, // Total webinars on that day
        totalUserCount: item.totalUserCount, // Total users attended on that day
        webinars: item.webinars, // List of webinars on that day
      }));

      setSeriesData(cumulativeData);
    }
  }, [Meeting_Details]);

  const options = {
    chart: {
      id: "webinar-attendance-chart",
      type: "area",
    },
    stroke: {
      curve: "smooth",
      colors: ["#11777e"],
      width: "2px",
    },
    dataLabels: {
      enabled: false,
    },
    xaxis: {
      type: "datetime",
      title: {
        text: "Date",
      },
      labels: {
        datetimeUTC: false,
        format: "dd MMM yyyy",
      },
    },
    yaxis: {
      title: {
        text: "Total Webinars",
      },
    },
    // title: {
    //   text: "Total Webinar Attendance Per Date ",
    // },

    tooltip: {
      shared: false,
      x: {
        format: "dd MMM yyyy",
      },
      y: {
        formatter: function (val, { dataPointIndex }) {
          const meeting = seriesData[dataPointIndex];
          console.log("meeting85", meeting);

          const webinarDetails = meeting.webinars
            .map(
              (webinar) =>
                `<br/><small><b>Webinar:</b> ${webinar.title}<br/><b>Users-Count:</b> ${webinar.userCount}</small></br>`
            )
            .join("");

          return `Total Webinars: ${val}, Total Users: ${
            meeting?.totalUserCount || 0
          }${webinarDetails}`;
        },
      },
    },
    // tooltip: {
    //     shared: false,
    //     x: {
    //         format: 'dd MMM yyyy', // Show date only
    //     },
    //     y: {
    //         formatter: function (val, { dataPointIndex }) {
    //             const meeting = seriesData[dataPointIndex];
    //             return `Webinars: ${val}, Users Attended: ${meeting?.totalUserCount || 0}`;
    //         }
    //     }
    // },
    fill: {
      type: "gradient",
      type: "gradient",
      gradient: {
        shadeIntensity: 1,
        inverseColors: false,
        opacityFrom: 0.5,
        opacityTo: 0.8,
        stops: [0, 100],
        colorStops: [
          {
            offset: 0,
            color: "#c6f9fc",
            opacity: 1,
          },
          {
            offset: 100,
            color: "rgb(239, 254, 255)",
            opacity: 1,
          },
        ],
      },
    },
  };

  const series = [
    {
      // name: 'Total Webinar Sessions',
      name: "",
      data: seriesData.map(({ x, y }) => ({ x, y })),
    },
  ];

  return (
    <div className="card">
      <div className="card-body">
        <div className="dashbord-right-header ">
          <p>Total Webinar Attendance Per Date</p>
        </div>

        <ApexCharts
          options={options}
          series={series}
          type="area"
          height={350}
        />
      </div>
    </div>
  );
}
