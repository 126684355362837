import React, { useRef, useState, useEffect } from "react";
import { Form, Button, Col, Row } from "react-bootstrap";
import InputGroup from "react-bootstrap/InputGroup";
import Innerpagenavbar from "../components/innerpage-navbar";
import { Link } from "react-router-dom";
import {
  CloseTicket,
  CreateSupportTicket,
  FetchAllCategory,
  ReplyTicket,
  getAllTicketDetails,
  getSingleTicketDetails,
} from "../action/user.action";
import { useSelector } from "react-redux";
import Select from "react-select";

import moment, { now } from "moment";

import config from "../lib/config";

import Header from "../components/Navbar";
import Innerfooter from "../components/inner-footer";

import { toastAlert } from "../utils/toast";

// import { useSelector } from "react-redux";

import { support_validation } from "../lib/validation";
import { toast } from "react-toastify";
import io from "socket.io-client";
import key from "../lib/config";

const socket = io(config.API_URL);

const initialForm = {
  subject: "",
  category: "",
  message: "",
  image: "",
  imagename: "",
};

const replayForm = {
  replaymessage: "",
  replayimage: "",
};

const InnerSupportTicket = () => {
  const user_deatils = useSelector((state) => state.User);
  const [visibleDiv, setVisibleDiv] = useState("CreateTicket");

  const [Initailvalue, setInitialValue] = useState(initialForm);
  const { subject, category, message, image, imagename } = Initailvalue;
  const [categorylist, setCategoryList] = useState([]);
  const [userTicketList, setUserTicketList] = useState([]);
  const [SingleTicketDetails, setSingleTicketDetails] = useState({});
  const [replayInitialForm, setreplayInitialForm] = useState(replayForm);
  const [formErrors, setFormErrors] = useState({});

  const [closeloader, setCloseLoader] = useState(false);

  const userDetails = useSelector((state) => state.User);

  const messageEndRef = useRef(null);

  // const { replaymessage, replayimage } = replayInitialForm;

  const showCreateDiv = () => {
    setVisibleDiv("CreateTicket");
  };

  const showDetailsDiv = () => {
    setVisibleDiv("TicketDetails");
  };

  const getCategory = async () => {
    try {
      const { result, status } = await FetchAllCategory();
      if (status) {
        setCategoryList(result.result);
      } else {
        console.log(result.message);
      }
    } catch (error) {
      console.log(error);
    }
  };

  const getAllTicketDetail = async () => {
    try {
      const { result, status } = await getAllTicketDetails();
      if (status) {
        console.log("result.result", result.result);
        setUserTicketList(result.result);
      } else {
        console.log(result.message);
      }
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    getCategory();
    getAllTicketDetail();
  }, []);

  useEffect(() => {
    socket.on("new-message", (data) => {
      console.log("New message received:", data);

      setSingleTicketDetails((prevTicket) => ({
        ...prevTicket,
        message: Array.isArray(prevTicket.message)
          ? [...prevTicket.message, data]
          : [data],
      }));
    });

    return () => {
      socket.off("new-message");
    };
  }, []);
  useEffect(() => {
    if (userDetails) {
      // socket.on("connect", () => {
      //   console.log("Connected to server");
      // });
      // socket.emit("CREATEROOM", userDetails._id);
      // socket.emit("message", "hii");
      // socket.on("auth", (data) => {
      //   console.log("socket_______emit__auth");
      //   // clearUserCookies();
      // });
      // socket.on("StremingCompleted", (reason) => {
      //   console.log(`StremingCompletedstopStreaming : ${reason}`);
      // });
      // return () => {
      //   if (socket) {
      //     socket.disconnect();
      //   }
      // };
    }
  }, [userDetails, socket]);

  const changHandler = async (e) => {
    const { id, value } = e.target;
    setInitialValue({ ...Initailvalue, [id]: value });
  };

  const changHandlerNew = (selectedOption) => {
    const { label, value } = selectedOption; // Destructure the selected value
    // You probably don't need to use `label` as the key here unless it's part of the state object structure.
    // Use "category" as the key if you want to store the selected value
    // setInitialValue({ ...Initailvalue, ["category"]: value });

    setInitialValue({ ...Initailvalue, category: { label, value } });
  };

  //scroll message
  useEffect(() => {
    if (SingleTicketDetails?.message?.length) {
      console.log("scroll100");
      scrollToBottom();
    }
  }, [SingleTicketDetails?.message]);

  const scrollToBottom = () => {
    if (messageEndRef.current) {
      messageEndRef.current.scrollIntoView({ behavior: "smooth" });
    }
  };

  // const submitHandler = async () => {
  //   try {
  //     const { result, status } = await CreateSupportTicket(Initailvalue);

  //     console.log(result, status, "result,status");
  //     if (status) {
  //       toastAlert("success", result.message);
  //     }
  //   } catch (error) {
  //     console.log(error);
  //   }
  // };

  const submitHandler = async () => {
    try {
      // Perform validation
      const validationErrors = await support_validation(Initailvalue);

      // Check if validation returned any errors
      if (Object.keys(validationErrors).length === 0) {
        const { result, status } = await CreateSupportTicket(Initailvalue);

        console.log("Initailvalue", Initailvalue);
        // Log result and status
        console.log("Result:", result);
        console.log("Status:", status);

        if (status) {
          toastAlert("success", result.message);
          setFormErrors({});
          setInitialValue(initialForm);
          window.location.reload();
        } else {
          toastAlert("error", "Failed to create support ticket.");
        }
      } else {
        // Validation errors exist, set them in the state
        setFormErrors(validationErrors);
        console.log("Validation Errors:", validationErrors);
      }
    } catch (error) {
      console.error("An error occurred:", error);
      toastAlert("error", "An unexpected error occurred.");
    }
  };

  const handleFileChange = async (event) => {
    const { id } = event.target;

    console.log("val_______________1111");
    if (id == "images") {
      const file = event.target.files[0];
      const fileType = file.type;
      const fileSize = file.size;

      console.log("filesfiles", file);

      if (
        (fileType === "image/png" ||
          fileType === "image/jpeg" ||
          fileType === "image/jpg" ||
          fileType === "image/webp") &&
        fileSize <= 1024 * 1024 // 1MB in bytes
      ) {
        const { files } = event.target;

        console.log("filesfiles", file);

        if (visibleDiv == "CreateTicket") {
          setInitialValue({
            ...Initailvalue,
            image: file,
            imagename: file.names,
          });
        } else {
          setreplayInitialForm({
            ...replayInitialForm,
            image: file,
          });
        }
      } else {
        toastAlert("error", "The file must be less than 1 MB.");
        return false;
      }
    } else {
    }
  };

  const selectSingleTicket = async (id) => {
    try {
      const { result, status } = await getSingleTicketDetails({ ticketid: id });

      socket.on("connect", () => {
        console.log("Connected to server");
      });

      //   socket.emit("CREATEROOM");
      socket.emit("JoinTicket", id);

      console.log(result, status, "result,status");
      if (status) {
        setSingleTicketDetails(result.result);
      }
    } catch (error) {
      console.log(error);
    }
  };

  const SubmitReplay = async () => {

    if (replayInitialForm?.replaymessage != "") {
      try {
        replayInitialForm.ticketid = SingleTicketDetails._id;
        console.log("replayInitialForm238", replayInitialForm);
        const { result, status } = await ReplyTicket(replayInitialForm);

        console.log(result, status, "result,status242");

        if (status === true) {
          console.log("s245");

          setreplayInitialForm({
            ...replayInitialForm,
            replaymessage: "",
          });

          // socket.emit("message-sent", {
          //   ticketid: replayInitialForm.ticketid,
          //   message: replayInitialForm.replaymessage,
          // });
        }
      } catch (error) {
        console.log(error);
        toast.error("Error sending message");
      }
    }
    else {
      toast.error("Message is empty");
    }
  };

  //ticket close
  const ticketclose = async (e) => {
    e.preventDefault();
    const confirmClose = window.confirm(
      "Are you sure you want to close this ticket?"
    );

    if (!confirmClose) {
      return;
    }
    try {
      setCloseLoader(true);
      let reqData = {
        id: SingleTicketDetails._id,
      };
      const { status, result } = await CloseTicket(reqData);
      if (status) {
        setCloseLoader(false);
        window.location.reload();
        // history.push('/support');
        toastAlert("success", result);
      } else {
        setCloseLoader(false);
        toastAlert("error", result);
      }
    } catch (err) {
      console.log(err, "error");
      setCloseLoader(false);
    }
  };

  // const options = [
  //   { value: "0", label: "Technical support" },
  //   { value: "1", label: "Account Management" },
  //   { value: "2", label: "New one" },
  // ];

  // categorylist.map((val) => (
  //   { value: val._id, label: val.category }
  // ))}
  return (
    <>
      <div className="nav_sec">
        <Header />
      </div>
      <div className="dashboard-page">
        <div className="container">
          <div className="page-flex">
            <div className="page-left">
              <Innerpagenavbar />
            </div>
            <div className="page-right">
              <div className="dashbord-right">
                <div className="dashbord-right-body-1 dashboard-padding ">
                  <div className="dashbord-right-header ">
                    <p>Support Ticket</p>
                  </div>
                  <div className="inner_home_shadow">
                    <div className="inner_home_body inner_support_ticket">
                      <Row>
                        <Col lg={6}>
                          <div className="inner_tab_content">
                            <div className="border_tab">
                              <Button
                                onClick={showCreateDiv}
                                className={
                                  visibleDiv === "CreateTicket" ? "active" : ""
                                }
                              >
                                Create Tickets
                              </Button>
                              <Button
                                onClick={showDetailsDiv}
                                className={
                                  visibleDiv === "TicketDetails" ? "active" : ""
                                }
                              >
                                Ticket Details
                              </Button>
                            </div>
                            {visibleDiv === "CreateTicket" && (
                              <>
                                <Form.Group
                                  className="mb-3 mt-3"
                                  controlId="exampleForm.ControlInput1"
                                >
                                  {" "}
                                  <Form.Label>Subject</Form.Label>{" "}
                                  <div
                                    names={"international"}
                                    searchable={true}
                                    searchPlaceholder="Search for a language"
                                    aria-label="Default select example"
                                    id="Webinar_language"
                                  // defaultLanguage={webinarDetails?.Webinar_language}
                                  />
                                  <Form.Control
                                    type="text"
                                    id="subject"
                                    aria-label="With textarea"
                                    placeholder="Subject"
                                    value={subject}
                                    onChange={changHandler}
                                    isInvalid={!!formErrors.subject}
                                  />
                                  <Form.Control.Feedback
                                    type="invalid"
                                    style={{ color: "red" }}
                                  >
                                    {formErrors.subject}
                                  </Form.Control.Feedback>
                                </Form.Group>
                                <Form.Group
                                  className="mb-3"
                                  controlId="exampleForm.ControlInput1"
                                >
                                  {" "}
                                  <Form.Label>Category</Form.Label>{" "}
                                  <div
                                    names={"international"}
                                    searchable={true}
                                    searchPlaceholder="Search for a language"
                                    aria-label="Default select example"

                                  // defaultLanguage={webinarDetails?.Webinar_language}
                                  />
                                  <Select
                                    id="category"
                                    className="filterSessions"
                                    placeholder="Select Category"
                                    // Ensure value is in the correct format for react-select: { value, label }
                                    value={
                                      category
                                        ? {
                                          value: category.value,
                                          label: category.label,
                                        }
                                        : null
                                    }
                                    onChange={(selectedOption) =>
                                      changHandlerNew(selectedOption)
                                    } // Pass the entire selected object
                                    options={categorylist.map((val) => ({
                                      value: val._id,
                                      label: val.category,
                                    }))}
                                    theme={(theme) => ({
                                      ...theme,
                                      borderRadius: "3px",
                                      width: "100%",
                                      border: "1px",
                                      colors: {
                                        ...theme.colors,
                                        primary25: "#F4F8FC",
                                        neutral20: "#dbdfe3 ",
                                        neutral30: "dbdfe3",
                                        primary: "#dbdfe3",
                                        // neutral20: "#696e70",
                                        neutral50: "#696e70 ",
                                        neutral60: "#696e70",
                                        neutral40: "#F4F8FC",
                                        primary50: "#F4F8FC",
                                        neutral80: "#696e70",
                                        neutral10: "red",
                                      },
                                    })}
                                  />
                                  {/* {categorylist.length > 0 &&
                                  categorylist.map((val) => (
                                    <option value={val._id}>
                                      {val.category}
                                    </option>
                                  ))} */}
                                  <Form.Control.Feedback
                                    type="invalid"
                                    style={{ color: "red" }}
                                  >
                                    {formErrors.category}
                                  </Form.Control.Feedback>
                                </Form.Group>
                                <Form.Group
                                  className="mb-3"
                                  controlId="exampleForm.ControlInput1"
                                >
                                  <Form.Label>Description</Form.Label>
                                  <Form.Control
                                    as="textarea"
                                    id="message"
                                    aria-label="With textarea"
                                    rows={5}
                                    placeholder="Describe your issue in detail"
                                    value={message}
                                    onChange={changHandler}
                                    isInvalid={!!formErrors.message}
                                  />
                                  <Form.Control.Feedback
                                    type="invalid"
                                    style={{ color: "red" }}
                                  >
                                    {formErrors.message}
                                  </Form.Control.Feedback>
                                </Form.Group>
                                {/* <Form.Label>Attach Files</Form.Label>
                            <div className="file-drop-area ">
                              <span className="choose-file-button">
                                {" "}
                                <img
                                  src={require("../assets/images/file_imge.png")}
                                  alt="img"
                                  className="img-fluid"
                                />
                              </span>
                              <span className="file-message">Upload image</span>
                              <input
                                className={`file-input ${formErrors.image ? 'is-invalid' : ''}`}
                                accept=".jpg,.jpeg,.png"
                                type="file"
                                id="images"
                                onChange={handleFileChange}
                                isInvalid={!!formErrors.image}
                              />
                              {formErrors.image && (
                                <Form.Control.Feedback type="invalid" style={{ color: 'red' }}>
                                  {formErrors.image}
                                </Form.Control.Feedback>
                              )}
                            </div>
                            <p>*Upload button for JPG,JPEG,PNG etc.</p> */}

                                <button
                                  to="#"
                                  className="primary_btn"
                                  onClick={submitHandler}
                                >
                                  Submit Ticket
                                </button>
                              </>
                            )}
                            {visibleDiv === "TicketDetails" && (
                              <div className="ticket_panel mt-3">
                                {userTicketList.length > 0 ? (
                                  <>
                                    {userTicketList.map((val, i) => (
                                      <div
                                        className="ticket_panel_div active"
                                        onClick={() =>
                                          selectSingleTicket(val._id)
                                        }
                                      >
                                        <h4>Ticket 0{i + 1}</h4>
                                        <div className="ticket_panel_details">
                                          <label>
                                            Sub: <span>{val.subject}</span>
                                          </label>
                                          <div className="ticket_panel_bottom">
                                            <label>
                                              [ Ticket ID:{" "}
                                              <span>
                                                ..{val?._id?.slice(-4)}
                                              </span>
                                              ]
                                            </label>
                                            <label>
                                              [ Status:{" "}
                                              <span>
                                                {val.status == 0
                                                  ? "Open"
                                                  : "Closed"}
                                              </span>
                                              ]
                                            </label>
                                          </div>
                                        </div>
                                      </div>
                                    ))}
                                  </>
                                ) : (
                                  <div className="support_empty">
                                    <img
                                      src={require("../assets/images/inner_support_img.png")}
                                      className="img-fluid pe-2"
                                    />
                                    <p>No Ticket Found</p>
                                  </div>
                                )}
                              </div>
                            )}
                          </div>
                        </Col>
                        <Col lg={6}>
                          <div className="inner_tab_content">
                            {visibleDiv === "CreateTicket" && (
                              <div className="support_empty">
                                <img
                                  src={require("../assets/images/inner_support_img.png")}
                                  className="img-fluid pe-2"
                                />
                                <p>Your Support Tickets List is Empty.</p>
                              </div>
                            )}
                            {visibleDiv === "TicketDetails" && (
                              <div className="ticket_details">
                                <h4>Ticket Details</h4>
                                {SingleTicketDetails.message?.length > 0 && (
                                  <div className="ticket_details_flex">
                                    <label>
                                      Sub:{" "}
                                      <span>{SingleTicketDetails.subject}</span>
                                    </label>
                                    <div className="ticket_panel_bottom">
                                      <label>
                                        [ Ticket ID:{" "}
                                        <span>
                                          ..
                                          {SingleTicketDetails?._id?.slice(-4)}
                                        </span>
                                        ]
                                      </label>
                                      <label>
                                        [ Status:{" "}
                                        <span>
                                          {SingleTicketDetails.status == 0
                                            ? "Open"
                                            : "Closed"}
                                        </span>
                                        ]
                                      </label>
                                    </div>
                                  </div>
                                )}

                                {/* <div className="msg_box_wrapper">
                                  {SingleTicketDetails.message?.length > 0 ? (
                                    SingleTicketDetails.message.map((val) => (
                                      <div className={`msg_box_panel ${userDetails._id != val.from_id
                                          ? "admin_msg_box_panel"
                                          : ""
                                        }`}
                                      >
                                        <div className="msg_box_div">
                                          <img
                                            src={require("../assets/images/user1.png")}
                                            className="img-fluid ps-2"
                                            alt="Icon"
                                          />
                                          <div className="msg_box_content">
                                            <p>{val.message}</p>
                                          </div>

                                        </div>
                                        <h6>
                                          {moment(val.createdAt).format("LLL")}
                                        </h6>
                                      </div>
                                    ))
                                  ) : (
                                    <> Empty</>
                                  )}


                                </div> */}

                                <div className="msg_box_wrapper">
                                  {SingleTicketDetails.message?.length > 0 ? (
                                    SingleTicketDetails.message.map((val) => (
                                      <div
                                        className={`msg_box_panel ${userDetails._id !== val.from_id
                                          ? "admin_msg_box_panel"
                                          : "user_msg_box_panel"
                                          }`}
                                        key={val._id} // Assuming _id or unique identifier is available
                                      >
                                        <div className="msg_box_div">
                                          {(userDetails._id !== val.from_id) ? (
                                            <img
                                            src={require("../assets/images/profile-img2.png")}
                                            className="img-fluid" style={{ height: "60px", width: "60px", borderRadius: "50%", cursor: "pointer" }} />
                                          ):(
                                           <img
                                            src={((user_deatils?.profile_image) ? `${key.API_URL}/presenter/${user_deatils?.profile_image}` : require("../assets/images/profile-img2.png"))}
                                            className="img-fluid" style={{ height: "60px", width: "60px", borderRadius: "50%", cursor: "pointer" }} />
                                          )}
                                          <div className="msg_box_content">
                                            <p>{val.message}</p>
                                          </div>
                                          <div ref={messageEndRef} />
                                        </div>
                                        <h6>
                                          {moment(val.createdAt).format("LLL")}
                                        </h6>
                                      </div>
                                    ))
                                  ) : (
                                    <div className="support_empty">
                                      <img
                                        src={require("../assets/images/inner_support_img.png")}
                                        className="img-fluid pe-2"
                                      />
                                      <p> Empty</p>
                                    </div>
                                  )}
                                </div>
                                {SingleTicketDetails.message?.length > 0 &&
                                  SingleTicketDetails.status !== 1 && (
                                    <div className="support_txtbox">
                                      <InputGroup>
                                        <Form.Control
                                          placeholder="Write a message"
                                          aria-label="Write a message"
                                          aria-describedby="basic-addon2"
                                          value={
                                            replayInitialForm.replaymessage
                                          }
                                          onChange={(e) =>
                                            setreplayInitialForm({
                                              ...replayInitialForm,
                                              replaymessage: e.target.value,
                                            })
                                          }
                                        />
                                        <InputGroup.Text id="basic-addon2">
                                          <div
                                            className=""
                                            onClick={SubmitReplay}
                                          >
                                            <Link>
                                              <img
                                                src={require("./../assets/images/send.png")}
                                                className="img-fluid"
                                              />
                                            </Link>
                                          </div>
                                        </InputGroup.Text>
                                      </InputGroup>
                                    </div>
                                  )}
                                {/* <div className="support_txtbox">
                                  <Form.Control
                                    placeholder="Write a message"
                                    type="text"
                                    value={replayInitialForm.replaymessage}
                                    onChange={(e) =>
                                      setreplayInitialForm({
                                        ...replayInitialForm,
                                        replaymessage: e.target.value,
                                      })
                                    }
                                  ></Form.Control>
                                </div> */}
                                {/* <div className="support_txtbox_btngrp">
                              
                                  <button
                                    to="#"
                                    className="primary_btn"
                                    onClick={SubmitReplay}
                                  >
                                    Send
                                  </button>
                                </div> */}
                                {SingleTicketDetails.status == 1 && (
                                  <Button className="primary_btn">
                                    Ticket Already Closed
                                  </Button>
                                )}
                                {SingleTicketDetails.message?.length > 0 &&
                                  SingleTicketDetails.status !== 1 && (
                                    <div className="text-center mt-3">
                                      <Link
                                        className="ticketclose primary_btn"
                                        onClick={ticketclose}
                                      >
                                        Satisfied, Close Ticket
                                      </Link>
                                    </div>
                                  )}
                              </div>
                            )}
                          </div>
                        </Col>
                      </Row>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div></div>
          </div>
        </div>
      </div>
      <Innerfooter />
    </>
  );
};
export default InnerSupportTicket;
