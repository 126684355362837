

import React, { useEffect } from "react";
import { getAuthorizationHeader } from "./utils/cookies";
import { useSelector, useDispatch } from "react-redux";
import { getUserDetails } from "./redux/features/userdetails";
import { Webinar_Details } from "./redux/features/webinar_details";
import { getPlanDetails } from './redux/features/plandetails.js'
import { Meeting_Details } from "./redux/features/meeting_details";

const HelperRouter = () => {

    const token = getAuthorizationHeader()
    const dispatch = useDispatch()

    useEffect(() => {
        if (token) {
            dispatch(getUserDetails())
            dispatch(Webinar_Details())
            dispatch(getPlanDetails())
        }
    }, [token])


    // const tkn = sessionStorage.getItem('webinar_tkn')
    // useEffect(() => {
    //     if (tkn) {
    //         dispatch(Webinar_Details())
    //     }
    // }, [tkn])


    return (
        <>
        </>
    )

}

export default HelperRouter