// controller.js
export const controllerData = {
    Express_configuration: "6718de915adfb43dd7019ed7",
    Full_configuration: "6718de915adfb43dd7019ed8",
    Download_CSV: "6718dee15adfb43dd7019ed6",
    Bulk_mail: "6718de915adfb43dd7019ee7",
    Youtube_and_Facebook_Live: "6718de915adfb44cd7019ed6",
    Telegram_support: "6718de915adfb43dd7019ee6",
    Personalized_template_for_webinars: "6718de915adfb43dd7019ee4"
}







